import React, { useEffect } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  InputAdornment,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LevelAdd from "../../components/addingComponents/LevelAdd";
import FacultyAdd from "../../components/addingComponents/FacultyAdd";
import ProgramAdd from "../../components/addingComponents/ProgramAdd";
import { getCampus } from "../../services/services";
import SearchIcon from "@mui/icons-material/Search";
import EditCampus from "./editCampus/EditCampus";
import RegisterUser from "../../components/settings/RegisterUser";
import { Link } from "react-router-dom";

const CommunityCampus = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogType, setDialogType] = React.useState("");
  const [campusData, setCampusData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedCampus, setSelectedCampus] = React.useState(null);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);

  const openDialogHandler = (type) => {
    setDialogType(type);
    setOpenDialog(true);
  };

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCampus();
        setCampusData(response.filter(data => data && data.typeOfCampus === "community"));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const campusFilteredData = campusData.filter(
    (campus) =>
      campus.campusName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      campus.university.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      campus.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = (campusId) => {
    setSelectedCampus(campusId);
    setOpenEditDialog(true);
  };
  const handleRegisterUserClick = (campusId) => {
    setSelectedCampus(campusId);
    setOpenRegisterDialog(true);
  };
  
console.log(selectedCampus)
  return (
    <div style={{ textAlign: "center", marginTop:'20px' }}>
      
      <Grid
        container
        justifyContent="space-between" // Adjust this as needed
        alignItems="center"
        style={{ marginBottom: "5px" }}
      >
        <Grid item xs={9} sm={5} md={2}>
          <TextField
            // label="Search  "
            variant="outlined"
            size="small"
            placeholder="search name, type, code"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" style={{ color: "#2b6eb5" }} />
                </InputAdornment>
              ),
              style: {
                height: "36px",
                padding: "0 10px",
                fontSize: "13px",
              },
            }}
            fullWidth
            sx={{
              border: "1px solid #2b6eb5",
              borderRadius: "5px",
              "&.MuiOutlinedInput-root": {
                height: "36px",
                "& fieldset": {
                  borderRadius: "5px",
                },
              },
              "&.MuiInputLabel-root": {
                fontSize: "14px",
              },
              "&.MuiInputBase-input": {
                padding: "6px 12px",
              },
            }}
          />
        </Grid>
        <Grid item xs={3} sm={2} md={4} style={{ textAlign: "center" }}>
          <Typography
            sx={{ fontSize: "24px", color: "#2b6eb5", padding: "10px" }}
          >
            List Of Community Campuses
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} md={2} style={{ textAlign: "right" }}>
          <Button
          component={Link}
          to='/campus-register'
            variant="contained"
            color="primary"
            size="small"
            sx={{ padding: "5px 20px 5px 20px", textTransform:'capitalize' }}
          >
            Add Campus
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              {[
                "S.No.",
                "University Name",
                "Campus Name",
                "Type",
                "Code",
                "Establish Year",
                "URL",
                "Email",
                "Actions",
              ].map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    color: "#ffffff",
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "24px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {campusFilteredData.map((data, index) => (
              <TableRow key={data.id}>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    // height: "15px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    // height: "24px",
                  }}
                >
                  {data.university.name}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    // height: "24px",
                  }}
                >
                  {data.campusName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    // height: "24px",
                  }}
                >
                  {data.typeOfCampus}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    // height: "24px",
                  }}
                >
                  {data.code}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "24px",
                  }}
                >
                  {data.yearOfEstd}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "24px",
                  }}
                >
                  <a href={data.url} target="blank">
                    website
                  </a>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "24px",
                  }}
                >
                  {data.email}
                </TableCell>
                <TableCell
                  key={index}
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  <div style={{display:'flex'}}>
                    <Button
                      onClick={() => handleEditClick(data.id)}
                      variant="contained"
                      size="small"
                      sx={{ fontSize: "10px", padding: "0x 0px", textTransform:'capitalize', mr:'5px' }}
                    >
                      edit
                    </Button>
                    <Divider orientation="vertical"  flexItem />
                    <Button
                      size="small"
                      onClick={() => openDialogHandler("level")}
                      sx={{textTransform:'capitalize'}}
                    >
                      Add Level
                    </Button>
                    <Divider orientation="vertical"  flexItem />
                    <Button
                      size="small"
                      onClick={() => openDialogHandler("faculty")}
                      sx={{textTransform:'capitalize'}}
                    >
                      Add Faculty
                    </Button>
                    <Divider orientation="vertical"  flexItem />
                    <Button
                      size="small"
                      onClick={() => openDialogHandler("program")}
                      sx={{textTransform:'capitalize'}}
                    >
                      Add Program
                    </Button>
                    <Divider orientation="vertical"  flexItem />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      { campusFilteredData.length===0 && (<Typography color='error'>No Community Campus Recorded</Typography>)}
      <Dialog
        onClose={closeDialogHandler}
        open={openDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {dialogType === "level" && "Add Level to Tribhuvan University"}
          {dialogType === "faculty" && "Add Faculty to Tribhuvan University"}
          {dialogType === "program" && "Add Program to Tribhuvan University"}
          
        </DialogTitle>
        <IconButton
          onClick={closeDialogHandler}
          style={{ position: "absolute", top: 5, right: 5 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {dialogType === "level" && <LevelAdd />}
          {dialogType === "faculty" && <FacultyAdd />}
          {dialogType === "program" && <ProgramAdd id={selectedCampus}/>}
         
        </DialogContent>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
        BackdropProps={{
          invisible: true,
          onClick: (event) => event.stopPropagation(),
        }}
        disableEscapeKeyDown
      >
        <EditCampus setOpenEditDialog={setOpenEditDialog} id={selectedCampus} onClose={closeDialogHandler}/>
      </Dialog>
     
    </div>
  );
};

export default CommunityCampus;
