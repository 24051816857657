import { useForm, Controller } from "react-hook-form";
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Select,
  MenuItem,
  Box,
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const LabMgmt = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Lab Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {/* Lab Name */}
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="labName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Lab Name is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Lab Name"
                        fullWidth
                        autoComplete="labName"
                        error={!!errors.labName}
                      />
                    )}
                  />
                  {errors.labName && (
                    <FormHelperText error>{errors.labName.message}</FormHelperText>
                  )}
                </Grid>

                {/* Building Name (Block No) */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small" error={!!errors.buildingName}>
                    <InputLabel id="building-name-label">Building Name (Block No)</InputLabel>
                    <Controller
                      name="buildingName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Building Name is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="building-name-label"
                          label="Building Name (Block No)"
                          autoComplete="buildingName"
                        >
                          {/* Populate with actual building names */}
                          <MenuItem value="Building1">Building1</MenuItem>
                          <MenuItem value="Building2">Building2</MenuItem>
                          {/* Add more MenuItems as needed */}
                        </Select>
                      )}
                    />
                    {errors.buildingName && (
                      <FormHelperText>{errors.buildingName.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Area Covered by Lab */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="areaCovered"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Area Covered by Lab is required",
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Area Covered by Lab must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Area Covered by Lab(in sq. ft)"
                        fullWidth
                        autoComplete="areaCovered"
                        error={!!errors.areaCovered}
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows decimal input, min value set
                      
                      />
                    )}
                  />
                  {errors.areaCovered && (
                    <FormHelperText error>{errors.areaCovered.message}</FormHelperText>
                  )}
                </Grid>

                {/* Lab Type */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="labType"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Lab Type is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Lab Type"
                        fullWidth
                        autoComplete="labType"
                        error={!!errors.labType}
                      />
                    )}
                  />
                  {errors.labType && (
                    <FormHelperText error>{errors.labType.message}</FormHelperText>
                  )}
                </Grid>

                {/* Adequacy of Lab Equipment */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.equipmentAdequacy}>
                    <InputLabel id="equipment-adequacy-label">Adequacy of Lab Equipment</InputLabel>
                    <Controller
                      name="equipmentAdequacy"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Adequacy of Lab Equipment is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="equipment-adequacy-label"
                          label="Adequacy of Lab Equipment"
                          autoComplete="equipmentAdequacy"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.equipmentAdequacy && (
                      <FormHelperText>{errors.equipmentAdequacy.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Has Internet Connection */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hasInternetConnection}>
                    <InputLabel id="internet-connection-label">Has Internet Connection</InputLabel>
                    <Controller
                      name="hasInternetConnection"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Internet Connection status is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="internet-connection-label"
                          label="Has Internet Connection"
                          autoComplete="hasInternetConnection"
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hasInternetConnection && (
                      <FormHelperText>{errors.hasInternetConnection.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Equipment at Lab */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="equipmentAtLab"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Equipment at Lab is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Equipment at Lab"
                        fullWidth
                        autoComplete="equipmentAtLab"
                        error={!!errors.equipmentAtLab}
                      />
                    )}
                  />
                  {errors.equipmentAtLab && (
                    <FormHelperText error>{errors.equipmentAtLab.message}</FormHelperText>
                  )}
                </Grid>

                {/* Remarks */}
                <Grid item xs={12} >
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        multiline rows={4}
                        InputProps={{ style: { minWidth: '100%' } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LabMgmt;
