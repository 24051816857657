import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getVerifiedStudents,
  upgradeStudents,
} from "../../components/dashboard/services/service";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UpgradeAcademics = () => {
  const [studentData, setStudentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedFiscalYear, setSelectedFiscalYearId] = useState("");
  const [levels, setLevels] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [semesters, setSemesters] = useState([]); // Added state for semesters
  const [fiscalYears, setFiscalYears] = useState([]);
  const [filteredStudentData, setFilteredStudentData] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [semesterName, setSemesterName] = useState([]);
  const [yearsName, setYearsName] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedSemesters, setSelectedSemesters] = useState("");

  const rowsPerPage = 15;
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  const isButtonDisabled =
    roleName === "UniSuperAdmin" || roleName === "SuperAdmin";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const verifiedStudents = await getVerifiedStudents();
        setStudentData(verifiedStudents);
        setFilteredStudentData(verifiedStudents);

        const uniqueLevels = [
          ...new Set(
            verifiedStudents.map((student) => student.levelName).filter(Boolean)
          ),
        ];
        const uniqueFaculties = [
          ...new Set(
            verifiedStudents
              .map((student) => student.facultyName)
              .filter(Boolean)
          ),
        ];
        const uniquePrograms = [
          ...new Set(
            verifiedStudents
              .map((student) => student.programName)
              .filter(Boolean)
          ),
        ];
        const uniqueFiscalYear = [
          ...new Set(
            verifiedStudents
              .map((student) => student?.fiscalYear)
              .filter(Boolean)
          ),
        ];
        const uniqueSemesters = [
          ...new Set(
            verifiedStudents.map((student) => student.semester).filter(Boolean)
          ),
        ]; // Extract unique semesters
        setFiscalYears(uniqueFiscalYear);
        setLevels(uniqueLevels);
        setFaculties(uniqueFaculties);
        setPrograms(uniquePrograms);
        setSemesters(uniqueSemesters); // Set semesters
      } catch (err) {
        console.error("Error fetching student data:", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = studentData.filter((student) => {
      const firstName = student.firstName
        ? student.firstName.toLowerCase()
        : "";
      const lastName = student.lastName ? student.lastName.toLowerCase() : "";
      const rollNo = student.rollNo ? student.rollNo.toLowerCase() : "";
      const gender = student.gender ? student.gender.toLowerCase() : "";
      const registeredYear = student.admissionYear
        ? student.admissionYear.toLowerCase()
        : "";
      const semester = student.semester ? student.semester.toLowerCase() : "";
      const faculty = student.facultyName.toLowerCase();
      const program = student.programName.toLowerCase();
      const district = student.district ? student.district.toLowerCase() : "";
      const searchTermLower = searchTerm.toLowerCase();

      return (
        (firstName.includes(searchTermLower) ||
          lastName.includes(searchTermLower) ||
          rollNo.includes(searchTermLower) ||
          gender.includes(searchTermLower) ||
          registeredYear.includes(searchTermLower) ||
          semester.includes(searchTermLower) ||
          faculty.includes(searchTermLower) ||
          program.includes(searchTermLower) ||
          district.includes(searchTermLower)) &&
        (selectedLevel === "" || student.levelName === selectedLevel) &&
        (selectedFaculty === "" || student.facultyName === selectedFaculty) &&
        (selectedProgram === "" || student.programName === selectedProgram) &&
        (selectedFiscalYear === "" ||
          student?.fiscalYear === selectedFiscalYear) &&
        (selectedSemester === "" || student.semester === selectedSemester) // Add filter by semester
      );
    });

    setFilteredStudentData(filteredData);
  }, [
    searchTerm,
    selectedLevel,
    selectedFaculty,
    selectedProgram,
    studentData,
    selectedFiscalYear,
    selectedSemester,
  ]);

  useEffect(() => {
    const relevantLevels = [
      ...new Set(
        studentData
          .filter((student) => student.facultyName === selectedFaculty)
          .map((student) => student.levelName)
          .filter(Boolean)
      ),
    ];

    setLevels(relevantLevels);

    // Reset level and program when faculty changes
    setSelectedLevel("");
    setSelectedProgram("");
  }, [selectedFaculty, studentData]);

  useEffect(() => {
    const relevantPrograms = [
      ...new Set(
        studentData
          .filter(
            (student) =>
              student.levelName === selectedLevel &&
              student.facultyName === selectedFaculty
          )
          .map((student) => student.programName)
          .filter(Boolean)
      ),
    ];

    setPrograms(relevantPrograms);

    // Reset program when level changes
    setSelectedProgram("");
  }, [selectedLevel, selectedFaculty, studentData]);

  const indexOfLastStudent = page * rowsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - rowsPerPage;
  const currentStudents = filteredStudentData.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCheckboxChange = (event, studentId) => {
    if (event.target.checked) {
      setSelectedStudents([...selectedStudents, studentId]);
    } else {
      setSelectedStudents(selectedStudents.filter((id) => id !== studentId));
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const semester = await axios.get(
          `${backendUrl}/StudentUpgrade/Semesters`,
          config
        );
        const year = await axios.get(
          `${backendUrl}/StudentUpgrade/Years`,
          config
        );
        setSemesterName(semester.data);
        setYearsName(year.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedStudents(filteredStudentData.map((student) => student.id));
    } else {
      setSelectedStudents([]);
    }
  };

  const handleUpgrade = async () => {
    try {
      if (!Array.isArray(selectedStudents) || !Array.isArray(studentData)) {
        throw new Error("Invalid input data");
      }

      const upgradeData = selectedStudents
        .map((studentId) => {
          const student = studentData.find(
            (student) => student.id === studentId
          );
          console.log("Found student:", student);

          if (student) {
            return {
              programId: student.programId || 36,
              studentId: studentId,
              year: selectedYear || "",
              semester: selectedSemesters || "",
              batchId: student.admissionYearId || 2,
            };
          }
          console.warn(`Student with ID ${studentId} not found or invalid`);
          return null;
        })
        .filter((item) => item !== null);

      console.log("Upgrade data prepared:", upgradeData);

      // Ensure upgradeStudents is defined and can handle the data
      if (typeof upgradeStudents !== "function") {
        throw new Error("upgradeStudents is not a function");
      }

      // Post the data
      await upgradeStudents(upgradeData);
      console.log("Upgrade successful");

      // Optionally refresh the data or provide feedback to the user
    } catch (error) {
      console.error("Error upgrading students:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasSelectedRows = selectedStudents.length > 0;

  return (
    <div>
      <Box>
        <Grid item xs={12} sm={6} md={4} padding={2}>
          <Typography
            variant="h6"
            style={{ color: "#2b6eb5", textAlign: "center" }}
          >
            Verified Student List
          </Typography>
        </Grid>
      </Box>

      {hasSelectedRows && (
        <Typography variant="body2" color="#1976d2" pb={1}>
          No of Selected Students: {selectedStudents.length}
        </Typography>
      )}

      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell colSpan={12} style={{ padding: 0 }}>
                <Grid
                  container
                  spacing={2}
                  padding={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} sm={8} md={9} container spacing={2}>
                    {/* Filter Row */}
                    <Grid item xs={12} sm={4} md={2}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedFiscalYear}
                          onChange={(e) =>
                            setSelectedFiscalYearId(e.target.value)
                          }
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Fiscal Year</MenuItem>
                          {fiscalYears.map((year, index) => (
                            <MenuItem key={index} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedFaculty}
                          onChange={(e) => setSelectedFaculty(e.target.value)}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Faculty</MenuItem>
                          {faculties.map((faculty, index) => (
                            <MenuItem key={index} value={faculty}>
                              {faculty}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedLevel}
                          onChange={(e) => setSelectedLevel(e.target.value)}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Level</MenuItem>
                          {levels.map((level, index) => (
                            <MenuItem key={index} value={level}>
                              {level}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedProgram}
                          onChange={(e) => setSelectedProgram(e.target.value)}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Program</MenuItem>
                          {programs.map((program, index) => (
                            <MenuItem key={index} value={program}>
                              {program}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedSemester}
                          onChange={(e) => setSelectedSemester(e.target.value)}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Semester</MenuItem>
                          {semesters.map((semester, index) => (
                            <MenuItem key={index} value={semester}>
                              {semester}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    container
                    justifyContent="flex-end"
                  >
                    <Button
                      size="small"
                      variant="standard"
                      onClick={handleClickOpen}
                      disabled={selectedStudents.length === 0}
                      display="none"
                      sx={{
                        bgcolor: "white",
                        color: "#3498db",
                        textTransform: "capitalize",
                        "&:hover": {
                          bgcolor: "#f5f5f5",
                          color: "#3498db",
                          opacity: 0.8,
                        },
                      }}
                    >
                      Upgrade selected
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              {selectedProgram && (
                <TableCell padding="checkbox">
                  <Checkbox
                    sx={{ color: "white" }}
                    color="default"
                    onChange={handleSelectAllClick}
                    checked={
                      selectedStudents.length === filteredStudentData.length
                    }
                    disabled={isButtonDisabled} // Conditionally disable the button
                  />
                </TableCell>
              )}
              {[
                "S.No.",
                "Full Name",
                "Gender",
                "Registered Year",
                "Semester/Year",
                "Roll No",
                "Level",
                "Faculty",
                "Program",
                "District",
                "Status",
              ].map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
              {!selectedProgram && (
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "center",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentStudents.map((student, index) => (
              <TableRow key={student.id}>
                {selectedProgram && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedStudents.includes(student.id)}
                      onChange={(event) =>
                        handleCheckboxChange(event, student.id)
                      }
                      disabled={isButtonDisabled} // Conditionally disable the button
                    />
                  </TableCell>
                )}
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {indexOfFirstStudent + index + 1}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {`${student.firstName || ""} ${student.middleName || ""} ${
                    student.lastName || ""
                  }`}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.gender || ""}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.admissionYear || ""}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.program?.programType || ""}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.rollNo}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.levelName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.facultyName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.programName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.pDistrict || ""}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                    textAlign: "left",
                  }}
                >
                  {student.isVerified ? (
                    <span style={{ color: "green" }}>Verified</span>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  {!selectedProgram && (
                    <Button
                      component={Link}
                      variant="contained"
                      size="small"
                      onClick={handleClickOpen}
                      style={{
                        textTransform: "capitalize",
                        fontSize: "12px",
                        marginLeft: "5px",
                      }}
                      disabled={isButtonDisabled}
                    >
                      Upgrade
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
        <Pagination
          count={Math.ceil(filteredStudentData.length / rowsPerPage)}
          page={page}
          shape="rounded"
          onChange={handlePageChange}
        />
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm Upgrade</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed with upgrading the selected{" "}
            <strong>{selectedStudents.length}</strong> students?
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel id="year-select-label" required>
                  Select Year
                </InputLabel>
                <Select
                  labelId="year-select-label"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  label="Selected Year"
                  name="yearName"
                  id="yearName"
                >
                  {yearsName.map((data) => (
                    <MenuItem key={data.id} value={data}>
                      {" "}
                      {data}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel id="year-select-label" required>
                  Select Semester
                </InputLabel>
                <Select
                  labelId="semester-select-label"
                  value={selectedSemesters}
                  onChange={(e) => setSelectedSemesters(e.target.value)}
                  label="Selected Semester"
                  name="semesterName"
                  id="semesterName"
                >
                  {semesterName.map((data) => (
                    <MenuItem key={data.id} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleUpgrade();
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpgradeAcademics;
