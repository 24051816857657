import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid, Paper, Typography } from '@mui/material';

const StudentsByCampus = () => {
  const studentsData = [
    { campus: 'Constituent', male: 300, female: 250 },
    { campus: 'Community', male: 200, female: 150 },
    { campus: 'Private', male: 100, female: 120 },
  ];

  const generateChartOption = () => {
    const campuses = ['Constituent', 'Community', 'Private'];

    const seriesData = [
      {
        name: 'Male',
        type: 'bar',
        barWidth: 35,
        data: campuses.map(campus =>
          studentsData.find(item => item.campus === campus)?.male || 0
        ),
        itemStyle: { color: '#1976d2' },
        label: { show: true, position: 'top', color: '#000' },
      },
      {
        name: 'Female',
        type: 'bar',
        barWidth: 35,
        data: campuses.map(campus =>
          studentsData.find(item => item.campus === campus)?.female || 0
        ),
        itemStyle: { color: '#FABC3F' },
        label: { show: true, position: 'top', color: '#000' },
      },
    ];

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: seriesData.map(series => series.name),
        textStyle: { color: '#2E294E' },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: 'category',
        data: campuses,
        axisLabel: { color: '#333' },
        name: "Campus Type",
        nameLocation: 'middle',
        nameGap: 35,
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        name: "No. of Students",
        nameLocation: 'middle',
        nameGap: 35,
        axisLabel: { color: '#333' },
      },
      series: seriesData,
    };
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ padding: '20px' }}>
          <Typography variant='body1' align="center" fontWeight={900} style={{ padding: '1rem' }}>
            Students by Campus Type
          </Typography>
          <ReactECharts
            option={generateChartOption()}
            style={{ height: '400px' }}
            opts={{ renderer: 'canvas' }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StudentsByCampus;
