import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Stack, Typography, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const baseUrl = process.env.REACT_APP_BASE_URL
const StudentsDetails = () => {
  const { id } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [actionType, setActionType] = useState('');
  const [documentUrl, setDocumentUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getStudentById = async () => {
      try {
        const response = await axios.get(`${backendUrl}/Student/${id}`);
        setStudentData(response.data);
        setDocumentUrl(response.data.documentUrl || ''); 
      } catch (error) {
        console.error('Error fetching student data:', error);
        toast.error("Failed to fetch student data", { autoClose: 2000 });
      }
    };

    getStudentById();
  }, [id]);

  const handleAction = async (type) => {
    setOpenConfirmDialog(false);
  
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
  
      let response;
  
      if (type === 'verify') {
        // Perform verification request
        response = await axios.post(`${backendUrl}/Student/VerifyStudents`, null, {
          params: { StudentId: id },
          headers: {
            'Authorization': `Bearer ${authToken}`,
          }
        });
        
        console.log('Verification response:', response); // Add this for debugging
      }
    navigate('/student-list')
      // Check response status
      if (response.status === 200) {
        toast.success('Student verified successfully', { autoClose: 2000 });
      } else {
        toast.error('Failed to verify student', { autoClose: 2000 });
      }
    } catch (error) {
      toast.error('Failed to verify student', { autoClose: 2000 });
    }
  };
  

  const openConfirmationDialog = (action) => {
    setActionType(action);
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = (actionConfirmed) => {
    if (actionConfirmed) {
      handleAction(actionType);
    } else {
      setOpenConfirmDialog(false);
    }
  };

  const studentInfo = studentData || {};

  const renderField = (label, value) => (
    <Grid item xs={12} sm={6}>
      <Stack direction="row" spacing={1} sx={{ mb: 0.5 }}>
        <Typography variant="body2" color="text.primary" sx={{ width: 160 }}>
          {`${label}:`}
        </Typography>
        <Typography variant="body2" color="#1976d2">
          {value || '-'}
        </Typography>
      </Stack>
    </Grid>
  );

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ textAlign: "center", color: "#2A629A", mb: 1 }}
      >
        Student's Enrollment Details for Verification
      </Typography>
      <Box sx={{ p: 3, display: 'flex', height: '100%', gap: 2 }}>
        <Card variant="outlined" sx={{ flex: 2, borderRadius: 2, boxShadow: 2, display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography textAlign="center" variant="subtitle1" sx={{ color: "#636363", mt: ".5rem" }}>
              Personal Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={1}>
            {renderField('विद्यार्थीको पुरा नाम देबनगरीमा (unicode)', studentInfo.nepaliName)}
              {renderField('First Name', studentInfo.firstName)}
              {renderField('Middle Name', studentInfo.middleName)}
              {renderField('Last Name', studentInfo.lastName)}
              {renderField('Phone Number', studentInfo.phoneNumber)}
              {renderField('Gender', studentInfo.gender)}
              {renderField('Email', studentInfo.email)}
              {renderField('Date of Birth (BS)', studentInfo.doBBS)}
              {renderField('Date of Birth (AD)', studentInfo.doBAD)}
              {renderField('Ethnicity', studentInfo.ethnicity)}
              {renderField('Nationality', studentInfo.nationality)}
              {renderField('Disability Status', studentInfo.disabilityStatus)}
              {renderField('Citizenship No', studentInfo.citizenshipNo)}
              {renderField('Citizen Issue District', studentInfo.citizenIssueDist)}
              {renderField('Address', studentInfo.gAddress)}
            </Grid>

            <Typography textAlign="center" variant="subtitle1" sx={{ color: "#636363", mt: ".5rem" }}>
              Guardian Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={1}>
              {renderField('Father Name', studentInfo.fatherName)}
              {renderField('Father Occupation', studentInfo.fOccupation)}
              {renderField('Father Phone No', studentInfo.fatherPhoneNo)}
              {renderField('Father Email', studentInfo.fatherEmail)}
              {renderField('Mother Name', studentInfo.motherName)}
              {renderField('Mother Occupation', studentInfo.mOccupation)}
              {renderField('Mother Phone No', studentInfo.motherPhoneNo)}
              {renderField('Mother Email', studentInfo.motherEmail)}
              {renderField('Guardian Name', studentInfo.guardianName)}
              {renderField('Guardian Phone', studentInfo.guardianPhone)}
              {renderField('Guardian Email', studentInfo.gEmail)}
            </Grid>

            <Typography textAlign="center" variant="subtitle1" sx={{ color: "#636363", mt: ".5rem" }}>
              Address Information
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
              Permanent Address
            </Typography>
            <Grid container spacing={1}>
              {renderField('Province', studentInfo.pProvince)}
              {renderField('District', studentInfo.pDistrict)}
              {renderField('Local Level', studentInfo.pLocalLevel)}
              {renderField('Ward No', studentInfo.pWardNo)}
              {renderField('Block No', studentInfo.pBlockNo)}
              {renderField('House No', studentInfo.pHouseNo)}
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 3, mb: 2 }}>
              Temporary Address
            </Typography>
            <Grid container spacing={1}>
              {renderField('Province', studentInfo.tProvince)}
              {renderField('District', studentInfo.tDistrict)}
              {renderField('Local Level', studentInfo.tLocalLevel)}
              {renderField('Ward No', studentInfo.tWardNo)}
              {renderField('Block No', studentInfo.tBlockNo)}
              {renderField('House No', studentInfo.tHouseNo)}
            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{ flex: 1, borderRadius: 2, boxShadow: 2, display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: 1, overflowY: 'scroll', height: '100%' }}>
            
              <img
                src={baseUrl+studentInfo.citizenshipFront}
                alt="Student Document"
                style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
              />
              <img
                src={baseUrl+studentInfo.citizenshipBack}
                alt="Student Document"
                style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
              />
              <img
                src={baseUrl+studentInfo.nidPic}
                alt="Student Document"
                style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
              />
              <img
                src={baseUrl+studentInfo.ppSizePhoto}
                alt="Student Document"
                style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }}
              />
            
          </CardContent>
        </Card>
      </Box>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        maxWidth="xs"
        fullWidth
        sx={{
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: '500',
            fontSize: '0.9rem',
            bgcolor: '#1976d2',
            color: 'white',
            py: 0.5,
            mb: 1,
          }}
        >
          {`Confirm ${actionType.charAt(0).toUpperCase() + actionType.slice(1)}`}
        </DialogTitle>
        <DialogContent
          sx={{
            textAlign: 'center',
            py: 1,
            px: 1.5,
          }}
        >
          <Typography variant="body2" sx={{ color: '#555' }}>
            Are you sure you want to {actionType} this student?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            p: 0.5,
          }}
        >
          <Button
            onClick={() => handleConfirmClose(true)}
            color="success"
            variant="contained"
            size="small"
            sx={{
              bgcolor: '#4caf50',
              color: 'white',
              '&:hover': {
                bgcolor: '#388e3c',
              },
              mx: 0.5,
              px: 1,
              py: 0.5,
              fontSize: '0.75rem',
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => handleConfirmClose(false)}
            color="error"
            variant="outlined"
            size="small"
            sx={{
              borderColor: '#f44336',
              color: '#f44336',
              '&:hover': {
                borderColor: '#c62828',
                color: '#c62828',
              },
              mx: 0.5,
              borderRadius: '4px',
              px: 1,
              py: 0.5,
              fontSize: '0.75rem',
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            bgcolor: '#1976d2',
            color: 'white',
            '&:hover': {
              bgcolor: '#1565c0',
            },
            padding: '6px 12px',
            fontSize: '0.75rem',
            borderRadius: '4px',
          }}
          onClick={() => openConfirmationDialog('verify')}
        >
          Verify
        </Button>
        <Button
          variant="standard"
          size="small"
          sx={{
            bgcolor: '#f44336',
            color: 'white',
            '&:hover': {
              bgcolor: '#c62828',
            },
            padding: '6px 12px',
            fontSize: '0.75rem',
            borderRadius: '4px',
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default StudentsDetails;
