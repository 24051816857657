import { Button, CardContent, Dialog, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getProgram, postFee } from '../../../services/services';

const EditFeeSetup = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [getProgramData, setGetProgramData] = useState([]); // Initialize as an empty array
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [programType, setProgramType] = useState('');
  const [instituteName, setInstituteName] = useState(''); // Fixed variable name casing

  const fetchData = async () => {
    try {
      const data = await getProgram();
      setGetProgramData(data || []); // Ensure it defaults to an empty array
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      await postFee(data);
      fetchData();
      toast.success('Data Added successfully', {
        autoClose: 2000,
      });
      reset();
    } catch (err) {
      toast.error('Failed to add!!', {
        autoClose: 2000,
      });
    }
  };

  const handleProgramChange = (event) => {
    const selectedProgram = getProgramData.find(program => program.id === event.target.value);
    setProgramType(selectedProgram ? selectedProgram.programType : '');
    setInstituteName(selectedProgram ? selectedProgram.campus : '');
  };

  const handleEditClick = (facultyId) => {
    setSelectedProgramId(facultyId);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchData();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={1} />
      <Grid item xs={12} md={10}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
             Update Program Fee
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="programId" required>
                      Program Name
                    </InputLabel>
                    <Select
                      required
                      {...register("programId", { required: true })}
                      id="programId"
                      size="small"
                      name="programId"
                      label="Program Name"
                      fullWidth
                      autoComplete="given-name"
                      error={!!errors.programId}
                      helpertext={errors.programId ? "Program is required" : ""}
                      onChange={handleProgramChange}
                    >
                      {getProgramData.map((data) => (
                        <MenuItem value={data.id} key={data.id}>{data.programName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("programType", { required: true })}
                    id="programType"
                    size="small"
                    name="programType"
                    label="Program Type"
                    fullWidth
                    value={programType}
                    error={!!errors.programType}
                    helpertext={errors.programType ? "Program Type required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="instituteId"
                    size="small"
                    name="instituteId"
                    label="Institute Name"
                    value={instituteName} // Fixed variable name casing
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem1fee"
                    {...register("sem1fee")}
                    name="sem1fee"
                    size="small"
                    label="Fee 1"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    {...register("sem2fee")}
                    id="sem2fee"
                    size="small"
                    name="sem2fee"
                    label="Fee 2"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem3fee"
                    {...register("sem3fee")}
                    name="sem3fee"
                    size="small"
                    label="Fee 3"
                    fullWidth
                    error={!!errors.sem3fee}
                    helpertext={errors.sem3fee ? "Fee required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem4fee"
                    {...register("sem4fee", { required: true })}
                    name="sem4fee"
                    size="small"
                    label="Fee 4"
                    fullWidth
                    error={!!errors.sem4fee}
                    helpertext={errors.sem4fee ? "Fee 4 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem5fee"
                    {...register("sem5fee", { required: true })}
                    name="sem5fee"
                    size="small"
                    label="Fee 5"
                    fullWidth
                    error={!!errors.sem5fee}
                    helpertext={errors.sem5fee ? "Fee 5 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem6fee"
                    {...register("sem6fee", { required: true })}
                    name="sem6fee"
                    size="small"
                    label="Fee 6"
                    fullWidth
                    error={!!errors.sem6fee}
                    helpertext={errors.sem6fee ? "Fee 6 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem7fee"
                    {...register("sem7fee", { required: true })}
                    name="sem7fee"
                    size="small"
                    label="Fee 7"
                    fullWidth
                    error={!!errors.sem7fee}
                    helpertext={errors.sem7fee ? "Fee 7 required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    id="sem8fee"
                    {...register("sem8fee", { required: true })}
                    name="sem8fee"
                    size="small"
                    label="Fee 8"
                    fullWidth
                    error={!!errors.sem8fee}
                    helpertext={errors.sem8fee ? "Fee 8 required" : ""}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
      </Grid>
    </Grid>
  );
};

export default EditFeeSetup;

