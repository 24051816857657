// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';
import { createTheme, ThemeProvider } from '@mui/material';
const theme = createTheme({

   components: {
     MuiFormLabel: {
       styleOverrides: {
         asterisk: {color:"red"},
       },
     },
   },
 
 })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>

      <Helmet bodyAttributes={{ style: 'background-color : #E2E4E8' }} />
      <Provider store={store}>
         <PersistGate persistor={persistor} loading={null}>
            <ThemeProvider theme={theme}>
            <BrowserRouter>
                  <App />
            </BrowserRouter>
                  </ThemeProvider>
         </PersistGate>
      </Provider>

   </React.StrictMode>
);