import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import axios from 'axios';
import ViewRegisterUser from "./ViewRegisterUser";
import { getCampusById } from "../../services/services";
import { toast } from "react-toastify";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const defaultTheme = createTheme();

const RegisterUser = ({ id }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [campusData, setCampusData] = React.useState([]);
  const [employeeData, setEmployeeData] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);

  // Function to fetch data
  const fetchData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        }
      };
      // Fetch campus data
      const response = await getCampusById(id);
      setCampusData(Array.isArray(response) ? response : [response]);

      // Fetch employee data from new endpoint
      const employeeResponse = await axios.get(`${backendUrl}/Employee/GetAllEmployeeesForCreatingUser`, config);
      setEmployeeData(employeeResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [id]);

  // Prepare options for Autocomplete based on employee data
  const allOptions = employeeData.map((data) => ({
    label: `${data.firstName} ${data.middleName} ${data.lastName}, ${data.email}, ${data.phoneNumber}`,
    id: data.id,
  }));

  const onSubmit = async (data) => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
  
      // Prepare query parameters
      const params = new URLSearchParams({
        employeeId: selectedEmployee,
        Role: data.userType,
      }).toString();
  
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        }
      };
  
      if (selectedEmployee && data.userType) {
        // Use axios.post with query parameters
        await axios.post(
          `${backendUrl}/Employee/MakeUser?${params}`,
          null, // No request body as the parameters are in the URL
          config
        );
        toast.success('User added successfully', {
          autoClose: 1500,
        });
        reset();
        setSelectedEmployee(null); // Clear selection after submission
        fetchData(); // Re-fetch data to update the displayed data
      }
    } catch (err) {
      console.log(err);
      toast.error('Failed to add user');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div
          style={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {campusData.length > 0 && campusData[0] && campusData[0].campusName && (
            <Typography
              variant="h6"
              color="primary"
              style={{ textDecoration: "underline", marginBottom: 10 }}
            >
              Register User in {campusData[0].campusName}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  disablePortal
                  id="employee-autocomplete"
                  options={allOptions}
                  size="small"
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => setSelectedEmployee(value ? value.id : null)}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select User" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>User Type</InputLabel>
                  <Select
                    {...register("userType", { required: true })}
                    fullWidth
                    size="small"
                    name="userType"
                    label="User Type"
                    id="userType"
                    error={!!errors.userType}
                    defaultValue=""
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Register User
                </Button>
              </Grid>
            </Grid>
          </form>
          {/* <ViewRegisterUser /> */}
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default RegisterUser;
