import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Dialog,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { getLevel, postLevel } from "../../../services/services";
import EditLevel from "./EditLevel";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure you set REACT_APP_BACKEND_URL in your .env file


const LevelMgmt = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [getLevelData, setGetLevelData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedLevelId, setSelectedLevelId] = useState(null);
  const { currentUser } = useSelector((state) => state.user);

  // Initialize universityId and campusId based on currentUser
  const universityId = currentUser.type === 'Uni' ? currentUser.institution.id : 0 ;
  console.log(universityId)
  const campusId = currentUser.type === 'college' ? currentUser.institution.id : 0;

  const fetchData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
  
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        }
      };
      const response = await axios.get(`${backendUrl}/Level/GetAllLebel/${universityId}`, config);
      setGetLevelData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = {
        universityId: universityId || 0,
        campusId: campusId || 0,
        levelName: data.levelName,
        shortName: data.shortName,
        code: data.code,
        alias: data.alias || '', // Default to empty string if null
        remarks: data.remarks || '', // Default to empty string if null
        status: data.status, // Assuming Status is a boolean
      };
      await postLevel(formData); // Send data to API
  
      toast.success("Data added successfully!", {
        autoClose: 2000,
      });
      reset();
      fetchData();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Level already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };
  

  const handleEditClick = (levelId) => {
    setSelectedLevelId(levelId);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchData(); // Fetch updated data after edit
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Level Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("levelName", { required: "Level Name is required" })}
                    size="small"
                    label="Level Name"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.levelName}
                    helperText={errors.levelName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    {...register("shortName")}
                    size="small"
                    label="Short Name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    required
                    {...register("code", { required: "Code is required" })}
                    size="small"
                    label="Code"
                    fullWidth
                    error={!!errors.code}
                    helperText={errors.code?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    {...register("alias")}
                    size="small"
                    label="Alias"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    {...register("remarks")}
                    size="small"
                    label="Remarks"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid margin="10px">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            List of Levels
          </Typography>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Level Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Alias
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Details
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getLevelData.length>0 && getLevelData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.levelName}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.code}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.alias}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.remarks}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.status ? (
                        <span style={{ color: "green" }}>Active</span>
                      ) : (
                        <span style={{ color: "red" }}>Inactive</span>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      <Button onClick={() => handleEditClick(data.id)}>
                        <EditNoteIcon />
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
      >
        <EditLevel
          id={selectedLevelId}
          onClose={() => setOpenEditDialog(false)}
          onUpdate={handleUpdate}
        />
      </Dialog>
    </Grid>
  );
};

export default LevelMgmt;
