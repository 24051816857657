import React, { useEffect, useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  InputAdornment,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LevelAdd from "../../components/addingComponents/LevelAdd";
import ProgramAdd from "../../components/addingComponents/ProgramAdd";
import { getUniversity } from "../../services/services";
import SearchIcon from "@mui/icons-material/Search";
import EditUniversity from "./EditUniversity";
import { Link } from "react-router-dom";
import RegisterUniversityUser from "./RegisterUniversityUser";
import FacultyAdd from "../../components/addingComponents/FacultyAdd";

const UniversityList = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogType, setDialogType] = React.useState("");
  const [universityData, setUniversityData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);

  const openDialogHandler = (type) => {
    setDialogType(type);
    setOpenDialog(true);
  };
  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUniversity();
        setUniversityData(response);
      } catch (err) {
        throw new err();
      }
    };
    fetchData();
  }, []);

  const filteredUniversityData = universityData.filter(
    (uni) =>
      uni.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      uni.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      uni.code.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleEditClick = (universityId) => {
    setSelectedUniversity(universityId);
    setOpenEditDialog(true);
  };
  const handleRegisterUserClick = (universityId) => {
    setSelectedUniversity(universityId);
    setOpenRegisterDialog(true);
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Grid
        container
        justifyContent="space-between" // Adjust this as needed
        alignItems="center"
        style={{ marginBottom: "0px" }}
      >
        <Grid item xs={9} sm={5} md={2}>
          <TextField
            // label="Search  "
            variant="outlined"
            size="small"
            placeholder="search name, type, code"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" style={{ color: "#2b6eb5" }} />
                </InputAdornment>
              ),
              style: {
                height: "36px",
                padding: "0 10px",
                fontSize: "13px",
              },
            }}
            fullWidth
            sx={{
              border: "1px solid #2b6eb5",
              borderRadius: "5px",
              "&.MuiOutlinedInput-root": {
                height: "36px",
                "& fieldset": {
                  borderRadius: "5px",
                },
              },
              "&.MuiInputLabel-root": {
                fontSize: "14px",
              },
              "&.MuiInputBase-input": {
                padding: "6px 12px",
              },
            }}
          />
        </Grid>
        <Grid item xs={3} sm={2} md={8} style={{ textAlign: "center" }}>
          <Typography
            sx={{ fontSize: "25px", color: "#2b6eb5", padding: "10px" }}
          >
            List of Universities
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} md={2} style={{ textAlign: "right" }}>
          <Button
            component={Link}
            to="/university-register"
            variant="contained"
            color="primary"
            size="small"
            sx={{ padding: "3px 20px 3px 20px", textTransform: "capitalize" }}
          >
            Add University
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              {[
                "S.No.",
                "University Name",
                "Type",
                "Code",
                "Establish Year",
                "URL",
                "Email",
                "Actions",
              ].map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "24px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUniversityData.map((data, index) => (
              <TableRow key={data.id}>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  {data.name}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  {data.type}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  {data.code}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  {data.dateOfEstd}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  <a href={data.url} target="blank">
                    {data.url}
                  </a>
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    height: "24px",
                  }}
                >
                  {data.email}
                </TableCell>
                <TableCell
                  key={index}
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "0px",
                  }}
                >
                    <Button
                      onClick={() => handleEditClick(data.id)}
                      variant="contained"
                      padding={-2}
                      size="small"
                      sx={{
                        fontSize: "10px",
                        textTransform: "capitalize",
                        mr: "5px",
                      }}
                    >
                      edit
                    </Button>                   
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        onClose={closeDialogHandler}
        open={openDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {dialogType === "level" && "Add Level to Tribhuvan University"}
          {dialogType === "faculty" && "Add Faculty to Tribhuvan University"}
          {dialogType === "program" && "Add Program to Tribhuvan University"}
        </DialogTitle>
        <IconButton
          onClick={closeDialogHandler}
          style={{ position: "absolute", right: 5 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {dialogType === "level" && <LevelAdd />}
          {dialogType === "faculty" && <FacultyAdd />}
          {dialogType === "program" && <ProgramAdd />}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
        BackdropProps={{
          invisible: true,
          onClick: (event) => event.stopPropagation(),
        }}
        disableEscapeKeyDown
      >
        <EditUniversity
          id={selectedUniversity}
          setOpenEditDialog={setOpenEditDialog}
          onClose={() => setOpenEditDialog(false)}

        />
      </Dialog>
      <Dialog
        open={openRegisterDialog}
        onClose={() => setOpenRegisterDialog(false)}
        maxWidth="md"
        BackdropProps={{
          invisible: true,
          onClick: (event) => event.stopPropagation(),
        }}
        disableEscapeKeyDown
      >
        <RegisterUniversityUser
          setOpenRegisterDialog={setOpenRegisterDialog}
          id={selectedUniversity}
          onClose={() => setOpenEditDialog(false)}
          />
      </Dialog>
    </div>
  );
};

export default UniversityList;
