import {
  Box,
  Grid,
  Step,
  StepLabel,
  Paper,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { StudentInfoProvider } from "./StudentGeneralInfo";
import StudentGeneralInfo from "./StudentGeneralInfo";
import StudentAddressInfo, {
  StudentAddressProvider,
} from "./StudentAddressInfo";
import StudentGuardianInfo, {
  StudentGuardianProvider,
} from "./StudentGuardianInfo";
import ReviewDetails from "./ReviewStudentsInfo";
import StudentRegistration, { StudentRegProvider } from "./StudentRegstration";
import EnrollmentForm from "../setup/enrollment/EnrollmentForm";
import StudentAcademicInfo from "./StudentAcademicInfo";

const steps = [
  "General Info",
  "Address Info",
  "Guardian Info",
  "Registration",
  "Review all",
  "Previous Academic Info"
];

export default function StudentRegister() {
  const [activeStep, setActiveStep] = useState(0);
  const [studentId, setStudentId] = useState(null);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleRegisterSuccess = (id) => {
    setStudentId(id);
    handleNext(); // Move to the next step
  };

  return (
    <StudentInfoProvider>
      <StudentAddressProvider>
        <StudentGuardianProvider>
          <StudentRegProvider>
            <Grid container sx={{ minHeight: "0" }} spacing={0} marginTop={2}>
              <Grid item xs={false} md={1} />
              <Grid
                item
                xs={12}
                md={10}
                lg={10}
                sx={{ backgroundColor: "background.default", p: 0 ,  borderRadius: "20px"}}
              >
                <Paper elevation={5} sx={{ borderRadius: "20px" }}>
                  <Box sx={{ maxWidth: "95%", mx: "auto" }}>
                    <Typography
                      variant="h5"
                      mb={2}
                      textAlign="center"
                      p="10px"
                      sx={{ color: "#2b6eb5",  }}
                    >
                      Student Admission Form
                    </Typography>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel>
                            <span
                              style={{
                                color: index <= activeStep ? "#2b6eb5" : "inherit",
                              }}
                            >
                              {label}
                            </span>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <Box mt={2} mx={0}>
                      {activeStep === 0 && (
                        <StudentGeneralInfo
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === 1 && (
                        <StudentAddressInfo 
                          handleNext={handleNext}
                          handleBack={handleBack}
                        />
                      )}
                      {activeStep === 2 && (
                        <StudentGuardianInfo
                          handleNext={handleNext}
                          handleBack={handleBack} 
                        />
                      )}
                      {activeStep === 3 && (
                        <StudentRegistration
                          handleNext={handleNext}
                          handleBack={handleBack} 
                        />
                      )}
                      {activeStep === 4 && (
                        <ReviewDetails  
                          handleBack={handleBack}   
                          handleNext={handleNext}
                          onRegisterSuccess={handleRegisterSuccess}
                        />
                      )}
                      {activeStep === 5 && (
                        <StudentAcademicInfo 
                          handleBack={handleBack} 
                          studentId={studentId} // Pass the ID to StudentAcademicInfo
                        />
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={false} md={2} />
            </Grid>
          </StudentRegProvider> 
        </StudentGuardianProvider>
      </StudentAddressProvider>
    </StudentInfoProvider>
  );
}
