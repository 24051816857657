import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Paper,
  IconButton,
  InputAdornment
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/logo.jpg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const NewPassword = () => {
  const { email, token } = useParams(); // Extract email and token from URL path
  const navigate = useNavigate(); // For navigation after success
  const [formData, setFormData] = useState({
    email: email || '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showNewPassword, setShowNewPassword] = useState(false); // Toggle new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle confirm password visibility
  const [passwordError, setPasswordError] = useState(''); // For password mismatch error
  const [formVisible, setFormVisible] = useState(true); // Toggle form visibility

  useEffect(() => {
    if (email) {
      setFormData((prevState) => ({
        ...prevState,
        email
      }));
    }
  }, [email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'confirmPassword') {
      setPasswordError(value !== formData.newPassword ? 'Passwords do not match.' : '');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(
        `${backendUrl}/User/ResetPassword`,
        {
          email: formData.email,
          newPassword: formData.newPassword,
          resetToken: token // Use the token from URL path
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 201) {
        toast.success('Your password has been successfully reset. Redirecting to sign-in page...', {
          autoClose: 3000
        });
        setFormVisible(false); // Hide form after success
        setTimeout(() => navigate('/login'), 3000); // Redirect to sign-in after success message
      } else {
        toast.error('Failed to reset password. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please check your input and try again.');
    }
  };

  const handleClickShowPassword = (field) => {
    if (field === 'newPassword') {
      setShowNewPassword((prev) => !prev);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword((prev) => !prev);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer />
      <Paper elevation={5} sx={{ mt: 4, borderRadius: '15px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem'
          }}
        >
          <img src={logo} alt="logo" style={{ height: '45px' }} />
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {formVisible ? (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                label="Email Address"
                name="email"
                value={formData.email}
                InputProps={{
                  readOnly: true // Make email field read-only
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                type={showNewPassword ? 'text' : 'password'}
                label="New Password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('newPassword')}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('confirmPassword')}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {passwordError && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {passwordError}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                sx={{ width: '12rem', mx: 'auto', mt: 2 }}
                size="small"
              >
                Reset Password
              </Button>
            </Box>
          ) : (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Typography variant="body1">
                Your password has been successfully reset. Go to the sign-in page.{' '}
                <Link to="/login">Sign in</Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default NewPassword;
