import React, { useEffect, useState } from "react";
import {  useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  TableBody, Table, TableCell, TableRow, TableHead, TableContainer,
  FormControl,
  Dialog,
  createTheme,
  ThemeProvider} from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { getFaculty, getLevel, postFaculty } from "../../../services/services.js";
// import { data } from "autoprefixer";
import EditFacultyMgmt from "./EditFaculty.jsx";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure you set REACT_APP_BACKEND_URL in your .env file

// import { red } from "@mui/material/colors";



const FacultyMgmt = () => {
  const {register, handleSubmit, reset, formState: { errors}} = useForm();
  const [getFacultyData, setGetFacultyData] = useState([])
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFacultyId, setSelectedFacultyId] = useState(null);
  const { currentUser } = useSelector((state) => state.user);

  // Initialize universityId and campusId based on currentUser
  const universityId = currentUser.type === 'Uni' ? currentUser.institution.id : 0 ;
  console.log(universityId)
  const campusId = currentUser.type === 'college' ? currentUser.institution.id : 0;
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
    
        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          }
        };
        const response = await axios.get(`${backendUrl}/Faculty/GetAllFaculties/${universityId}`, config);
        setGetFacultyData(response.data)

      } catch(err) {
        console.log(err)
      }
    };
  useEffect(() => {
    fetchData();
  }, [])
  const onSubmit = async (data) => {
    try {
      const formData = {
        universityId: universityId || 0,
        campusId: campusId || 0,
        facultyName: data.facultyName,
        facultyType:data.facilityType,
        shortName: data.shortName,
        code: data.code,
        alias: data.alias || '', // Default to empty string if null
        detail: data.detail || '', // Default to empty string if null
        status: data.status, // Assuming Status is a boolean
      };
      await postFaculty(formData);
      fetchData();
      toast.success('Data Successfuly Added !!', {
        autoClose:2000
      })
      reset()
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error("Faculty already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };

  const handleEditClick = (facultyId) => {
    setSelectedFacultyId(facultyId);
    setOpenEditDialog(true);
  };
  console.log(selectedFacultyId)

  const handleUpdate = () => {
    fetchData()
  }
  return (
    <Grid container spacing={3} >
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Faculty Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("facultyName", {required: true})}
                    id="facultyName"
                    size="small"
                    name="facultyName"
                    label="Faculty Name"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.facultyName}
                    helpertext={errors.facultyName?'faculty name required' : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                <InputLabel required>Faculty Type</InputLabel>
                  <Select
                    required
                    {...register("facilityType", {required: true})}
                    id="facilityType"
                    size="small"
                    name="facilityType"
                    label="Faculty Type"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.facilityType}
                    helpertext={errors.facilityType?'faculty Type required' : ''}
                  >
                    <MenuItem value={'general'}>General</MenuItem>
                    <MenuItem value={'technical'}>Technical</MenuItem>

                  </Select>
                  </FormControl>
                </Grid>
                
                {/* <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                <InputLabel id="levelId" required>Level Name</InputLabel>
                  <Select
                    required
                    {...register("levelId", {required: true})}
                    id="levelId"
                    size="small"
                    name="levelId"
                    label="Level Name"
                    fullWidth
                    error={!!errors.levelId}
                    helpertext={errors.levelId}
                  >
                  {getLevelData.map((data) => (
                    <MenuItem value={data.id}>{data.levelName}</MenuItem>
                  ))}
                  </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register('shortName', {required: true})}
                    id="shortName"
                    size="small"
                    name="shortName"
                    label="Short Name"
                    fullWidth
                    autoComplete="short-name"
                    error={!!errors.shortName}
                    helpertext={errors.shortName?'Short Name required':''}
                    
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    {...register('code')}
                    id="code"
                    size="small"
                    name="code"
                    label="Code"
                    fullWidth
                    error={!!errors.code}
                    helpertext={errors.code}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="alias"
                    {...register('alias')}
                    name="alias"
                    size="small"
                    label="Alias"
                    fullWidth
                    error={!!errors.alias}
                    helpertext={errors.alias}
                   
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    
                    {...register('detail')}
                    id="detail"
                    name="detail"
                    size="small"
                    label="Remarks"
                    fullWidth
                   error={!!errors.detail}
                   helpertext={errors.detail}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  // height: '100vh', // Make the container full height of the viewport
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid margin='10px'>
        <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >List of Faculty</Typography>
        <TableContainer>
          <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
            <TableHead style={{ backgroundColor: "#2A629A" }}>
              <TableRow>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>S.No:</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Faculty Name</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Faculty Type</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Short Name</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Code</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Alias</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>detail</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Status</TableCell>
                <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>Action</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
                {getFacultyData.length>0 && getFacultyData.map((data, index) => (
              <TableRow key={data.id}>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{index+1}</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.facultyName}</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
  {data.facultyType}
</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.shortName}</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.code}</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.alias}</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.detail}</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.status?<span style={{color:'green'}}>Active</span>:<span style={{color:'red'}}>Inactive</span>}</TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                <Button onClick={() => handleEditClick(data.id)}>
              <EditNoteIcon />Edit
            </Button>
                </TableCell>


              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
    <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="md">
  <EditFacultyMgmt id={selectedFacultyId} onClose={() => setOpenEditDialog(false)} onUpdate={handleUpdate}/>
</Dialog>

    </Grid>
  );
};

export default FacultyMgmt;
