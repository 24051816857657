import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent,
} from "@mui/material";
import { getEthnicGroupById, patchEthnicGroup } from "../../../services/services";
import { toast } from "react-toastify";

const EditEthnicGroup = ({ id, onClose, onUpdate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [EthnicGroupById, setEthnicGroupById] = useState([]);

  const onSubmit = async (data) => {
    try {
      await patchEthnicGroup(data, id);
      onUpdate();
      onClose();
      toast.success("Data updated Successfully", {
        autoClose: 2000,
      });
    } catch (err) {
      toast.error("Failed to Update data", {
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const EthnicGroupDataById = await getEthnicGroupById(id);
        setEthnicGroupById(
          Array.isArray(EthnicGroupDataById) ? EthnicGroupDataById : [EthnicGroupDataById]
        );
        setValue("name", EthnicGroupDataById.name);
        setValue("isActive", EthnicGroupDataById.isActive);
        setValue("description", EthnicGroupDataById.description);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id, setValue]);

  return (
    <>
      <DialogContent fullWidth>
        <Grid container spacing={0}>
          <Grid item xs={false} md={0} />
          <Grid item xs={12} md={12}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Edit Ethnic Group
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                {EthnicGroupById.map((data) => (
                  <Grid key={data.id}>
                    <Grid container spacing={2} key={data.id}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          required
                          {...register("name", { required: true })}
                          size="small"
                          label="EthnicGroup Name"
                          fullWidth
                          autoComplete="given-name"
                          error={!!errors.name}
                          helpertext={
                            errors.name ? "EthnicGroup Name is required" : ""
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={2}>
                        <TextField
                          required
                          {...register("shortName", { required: true })}
                          size="small"
                          label="Short Name"
                          fullWidth
                          autoComplete="short-name"
                          error={!!errors.shortName}
                          helpertext={
                            errors.shortName ? "Short Name is required" : ""
                          }
                        />
                      </Grid> */}

                      <Grid item xs={12} sm={2.5}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Status</InputLabel>
                          <Select
                            {...register("isActive")}
                            size="small"
                            label="isActive"
                            fullWidth
                          >
                            <MenuItem value={true}>
                              <span style={{ color: "green" }}>Active</span>
                            </MenuItem>
                            <MenuItem value={false}>
                              <span style={{ color: "red" }}>InActive</span>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                     
                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...register("description")}
                          size="small"
                          label="Description"
                          fullWidth
                          autoComplete="given-name"
                          
                        />
                      </Grid>
                      
                    </Grid>

                    <Grid
                      container
                      style={{
                        margin: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Button onClick={onClose}>Cancel</Button>
                      <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        style={{
                          backgroundColor: "#007aff",
                          color: "#inherit",
                        }}
                      >
                        {" "}
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </form>
            </CardContent>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default EditEthnicGroup;
