import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";
import { createContext } from "react";
import useAddressData from "../../address/address";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getStudentById } from "../../../services/employeeService";

const EditStudentAddressContext = createContext();

const EditStudentAddressProvider = ({ children }) => {
  const methods = useForm();
  const [editStudentAddress, setEditStudentAddress] = useState({});
  const onChange = (name, value) => {
    setEditStudentAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <EditStudentAddressContext.Provider
      value={{ ...methods, editStudentAddress, onChange }}
    >
      {children}
    </EditStudentAddressContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000",
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const EditStudentAddressInfo = ({ handleNext, handleBack, id }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useContext(EditStudentAddressContext);

  const [editStudentAddress, setEditStudentAddress] = useState({
    pProvince: "",
    pDistrict: "",
    pLocalLevel: "",
    pWardNo: 0,
    pTole: "",
    pHouseNo: "",
    tProvince: "",
    tDistrict: "",
    tLocalLevel: "",
    tWardNo: 0,
    tTole: "",
    tHouseNo: "",
  });

  const fetchData = async () => {
    try {
      const getStudentData = await getStudentById(id);
      setSelectedProvince(getStudentData.pProvince)
      setSelectedProvince2(getStudentData.tProvince)
      setSelectedDistrict(getStudentData.pDistrict)
      setSelectedDistrict2(getStudentData.tDistrict)
      // Update form values
      setValue("pProvince", getStudentData.pProvince);
      setValue("pDistrict", getStudentData.pDistrict);
      setValue("pLocalLevel", getStudentData.pLocalLevel);
      setValue("pWardNo", getStudentData.pWardNo);
      setValue("pTole", getStudentData.pLocality);
      setValue("pHouseNo", getStudentData.pHouseNo);
      setValue("isSameAsPermanent", getStudentData.isSameAsPermanent);
      setValue("tProvince", getStudentData.tProvince);
      setValue("tDistrict", getStudentData.tDistrict);
      setValue("tLocalLevel", getStudentData.tLocalLevel);
      setValue("tWardNo", getStudentData.tWardNo);
      setValue("tTole", getStudentData.tLocality);
      setValue("tHouseNo", getStudentData.tHouseNo);
      
      // Optionally set internal state if needed
      setEditStudentAddress({
        pProvince: getStudentData.pProvince,
        pDistrict: getStudentData.pDistrict,
        pLocalLevel: getStudentData.pLocalLevel,
        pWardNo: getStudentData.pWardNo,
        pTole: getStudentData.pLocality,
        pHouseNo: getStudentData.pHouseNo,
        isSameAsPermanent: getStudentData.isSameAsPermanent,
        tProvince: getStudentData.tProvince,
        tDistrict: getStudentData.tDistrict,
        tLocalLevel: getStudentData.tLocalLevel,
        tWardNo: getStudentData.tWardNo,
        tTole: getStudentData.tLocality,
        tHouseNo: getStudentData.tHouseNo,
      });
    } catch (error) {
      console.log("Error fetching student data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const {
    uniqueProvinces,
    uniqueDistricts,
    uniqueLocalLevels,
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
    uniqueProvinces2,
    uniqueDistricts2,
    uniqueLocalLevels2,
    selectedProvince2,
    setSelectedProvince2,
    selectedDistrict2,
    setSelectedDistrict2,
    noOfWards,
    noOfWards2
  } = useAddressData();

  const { onChange } = useContext(EditStudentAddressContext);

  const onSubmit = (data) => {
    onChange("pProvince", data.pProvince);
    onChange("pDistrict", data.pDistrict);
    onChange("pLocalLevel", data.pLocalLevel);
    onChange("pWardNo", data.pWardNo);
    onChange("pTole", data.pTole);
    onChange("pHouseNo", data.pHouseNo);
    onChange("isSameAsPermanent", data.isSameAsPermanent);
    onChange("tProvince", data.tProvince);
    onChange("tDistrict", data.tDistrict);
    onChange("tLocalLevel", data.tLocalLevel);
    onChange("tWardNo", data.tWardNo);
    onChange("tTole", data.tTole);
    onChange("tHouseNo", data.tHouseNo);
    handleNext();
  };

  const onBack = () => {
    handleBack();
  };

  const getpWardOptions = (pLocalLevel) => {
    const wards = noOfWards[pLocalLevel] || 0;
    return Array.from({ length: wards }, (_, i) => i + 1);
  };
  const gettWardOptions = (tLocalLevel) => {
    const wards = noOfWards2[tLocalLevel] || 0;
    return Array.from({ length: wards }, (_, i) => i + 1);
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onBack}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="subtitle1" sx={{ color: "#636363" }}>
            Permanent Address
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="pProvince" required>
              Province
            </InputLabel>
            <Controller
              name="pProvince"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="pProvince"
                  label="Province"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedProvince(value);
                    setSelectedDistrict(null);
                    field.onChange(value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {uniqueProvinces.map((province) => (
                    <MenuItem key={province} value={province}>
                      {province}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="pDistrict" required>
              District
            </InputLabel>
            <Controller
              name="pDistrict"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="pDistrict"
                  label="District"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedDistrict(value);
                    field.onChange(value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                                    {uniqueDistricts.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="pLocalLevel" required>
              Local Level
            </InputLabel>
            <Controller
              name="pLocalLevel"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="pLocalLevel"
                  label="Local Level"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue("pLocalLevel", value);
                    onChange('pLocalLevel', value); // Update state if necessary
                    field.onChange(value); // Trigger field onChange
                  }}
                >
                  {uniqueLocalLevels.map((localLevel) => (
                    <MenuItem key={localLevel} value={localLevel}>
                      {localLevel}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id="pWardNo" required>
              Ward No
            </InputLabel>
            <Controller
              name="pWardNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="pWardNo"
                  label="Ward No"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value); // Manually trigger field's onChange event
                  }}
                  error={!!errors.pWardNo}
                  helperText={errors.pWardNo ? errors.pWardNo.message : ""}
                >
                  {getpWardOptions(editStudentAddress.pLocalLevel).map((ward) => (
                    <MenuItem key={ward} value={ward}>
                      {ward}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="pTole"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="pTole"
                size="small"
                name="pTole"
                label="Tole"
                value={field.value || ""}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="pHouseNo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="pHouseNo"
                size="small"
                name="pHouseNo"
                label="House No"
                value={field.value || ""}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            textAlign="center"
            variant="subtitle1"
            sx={{ color: "#636363", mt: ".5rem" }}
          >
            Current Address
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="tProvince" required>
              Province
            </InputLabel>
            <Controller
              name="tProvince"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="tProvince"
                  label="Province"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedProvince2(value);
                    setSelectedDistrict2(null);
                    field.onChange(value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {uniqueProvinces2.map((province) => (
                    <MenuItem key={province} value={province}>
                      {province}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="tDistrict" required>
              District
            </InputLabel>
            <Controller
              name="tDistrict"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="tDistrict"
                  label="District"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedDistrict2(value);
                    field.onChange(value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {uniqueDistricts2.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="tLocalLevel" required>
              Local Level
            </InputLabel>
            <Controller
              name="tLocalLevel"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="tLocalLevel"
                  label="Local Level"
                  size="small"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue("tLocalLevel", value);
                    onChange('tLocalLevel', value); // Update state if necessary
                    field.onChange(value);
                  }}
                >
                  {uniqueLocalLevels2.map((localLevel) => (
                    <MenuItem key={localLevel} value={localLevel}>
                      {localLevel}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="tWardNo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                required
                {...field}
                id="tWardNo"
                size="small"
                label="Ward No"
                fullWidth
                value={field.value || ""}
                select
                SelectProps={{
                  native: true,
                }}
              >
                {gettWardOptions(editStudentAddress.tLocalLevel).map((ward) => (
                  <option key={ward} value={ward}>
                    {ward}
                  </option>
                ))}
              </ValidationTextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="tTole"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="tTole"
                size="small"
                name="tTole"
                label="Tole"
                value={field.value || ""}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="tHouseNo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="tHouseNo"
                size="small"
                name="tHouseNo"
                label="Current House No"
                value={field.value || ""}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="standard"
          size="small"
          onClick={handleBack}
          sx={{
            color: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};

export { EditStudentAddressProvider, EditStudentAddressContext };
export default EditStudentAddressInfo;

