import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { createContext, useContext, useState } from "react";
import useAddressData from "../../address/address";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getUniversityById } from "../../../services/services";


const EditUniversityInfoContext = createContext();

const EditUniversityInfoProvider = ({ children }) => {
  const methods = useForm();

  const [universityInfo, setUniversityInfo] = useState({});

 
  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setUniversityInfo((prevState) => ({
        ...prevState,
        universityLogo: file,
        logo: file.name,
      }));
    } else {
      setUniversityInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <EditUniversityInfoContext.Provider value={{ ...methods, universityInfo, onChange }}>
      {children}
    </EditUniversityInfoContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000",
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const EditUniversityInfo = ({ handleNext, handleBack, id }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useContext(EditUniversityInfoContext);
  const { onChange } = useContext(EditUniversityInfoContext);
  const [universityInfo, setUniversityInfo] = useState({
    type: "",
    name: "",
    code: "",
    shortCode: "",
    alias: "",
    phone: "",
    email: "",
    url: "",
    province: "",
    district: "",
    localLevel: "",
    
    wardNo: "",
    locality: "",
    logo: "",
    universityLogo: null,
  });
  const fetchData = async () => {
    try {
        const getUniversityData = await getUniversityById(id)
        const province = getUniversityData.province;
        const district = getUniversityData.district;
        setSelectedDistrict(district)
        setSelectedProvince(province)
        setValue('name', getUniversityData.name);
        setValue('code', getUniversityData.code);
        setValue('alias', getUniversityData.alias);
        setValue('shortCode', getUniversityData.shortCode);
        setValue('phone', getUniversityData.phoneNo);
        setValue('email', getUniversityData.email);
        setValue('wardNo', getUniversityData.wardNo);
        setValue('locality', getUniversityData.address);
        setValue('url', getUniversityData.url);
        setValue('type', getUniversityData.type);
        setValue('province', getUniversityData.province);
        setValue('district', getUniversityData.district);
        setValue('localLevel', getUniversityData.localLevel);
        setUniversityInfo ({
          name : getUniversityData.name,
          code: getUniversityData.code,
          alias: getUniversityData.alias,
          shortCode: getUniversityData.shortCode,
          email: getUniversityData.email,
          phone: getUniversityData.phoneNo,
          locality: getUniversityData.address,
          url: getUniversityData.url,
          type: getUniversityData.type,
          province: getUniversityData.province,
          district: getUniversityData.district,
          localLevel: getUniversityData.localLevel,
          wardNo: getUniversityData.wardNo,
          logo: getUniversityData.logo,
        })
    } catch (error) {
        console.log('error')
    }
  }
  useEffect(() => {
    fetchData();
  }, [id])

  const {
    uniqueProvinces,
    uniqueDistricts,
    uniqueLocalLevels,
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
    noOfWards
  } = useAddressData();

const onSubmit = (data) => {
  // Assuming data includes all fields from the form
  Object.keys(data).forEach(key => {
    onChange(null, key, data[key]);
  });
  handleNext();
};
  const onBack = () => {
    handleBack();
  };
  const getWardOptions = (localLevel) => {
    const wards = noOfWards[localLevel] || 0;
    return Array.from({ length: wards }, (_, i) => i + 1);
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid mt='.6rem' container spacing={1}>
        <Grid item xs={12} sm={3}>
          <FormControl  size="small" fullWidth>
            <InputLabel  id="type">
              University Type
            </InputLabel>
            <Controller
              name="type"
              control={control}
              defaultValue="national" // Set the default value here
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="type"
                  id="type"
                  name="type"
                  label="University Type"
                  fullWidth
                  value='national'
                >
                  <MenuItem value="">
                    {" "}
                    {/* This will select the default value */}
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="national">National</MenuItem>
                  <MenuItem value="foreign">Foreign</MenuItem>
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4.5}>
          {/* <FormControl fullWidth required> */}
          <Controller
            name="name"
            control={control}
            rules={{ required: "University Name  is required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                // required
                {...field}
                id="name"
                size="small"
                name="name"
                label="University Name"
                fullWidth
                error={!!errors.name}
                helpertext={errors.name ? "University Name is required" : ""}
              />
            )}
          />
          {/* </FormControl> */}
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Controller
            name="code"
            control={control}
            rules={{ required: "Code is required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="code"
                size="small"
                name="code"
                label="University Code"
                fullWidth
                error={!!errors.code}
                helpertext={errors.code ? "Code is required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="shortCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="shortCode"
                size="small"
                name="shortCode"
                label="Short Code"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="alias"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="alias"
                size="small"
                name="alias"
                label="Alias"
                fullWidth
                error={!!errors.alias}
                helpertext={errors.alias}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone Number is required",
              validate: (value) => {
                const isValidPhone = /^[9]\d{9}$/.test(value);
                return isValidPhone || "invalid phone umber";
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="phone"
                type="text"
                name="phone"
                label="Phone Number"
                InputProps={{
                  placeholder: "98XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10); // Limit input to 10 characters
                  },
                }}
                fullWidth
                autoComplete="phone number"
                size="small"
                error={!!errors.phone}
                helpertext={errors.phone ? errors.phone.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email format not matched",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="email"
                name="email"
                type="email"
                size="small"
                label="Email"
                fullWidth
                error={!!errors.email}
                helpertext={errors.email && errors.email.message} // Check if errors.email exists before accessing its message property
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
  <FormControl size="small" fullWidth>
    <InputLabel id="province" shrink={true}>
      Province
    </InputLabel>
    <Controller
      name="province"
      control={control}
      defaultValue={selectedProvince || ""}
      render={({ field }) => (
        <Select
          {...field}
          labelId="province"
          label="Province"
          size="small"
          value={field.value || ""}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedProvince(value);
            setSelectedDistrict(null);
            field.onChange(value); // Manually trigger the field's onChange event
          }}
          InputLabelProps={{
            shrink: true
          }}
        >
          {uniqueProvinces.map((province) => (
            <MenuItem key={province} value={province}>
              {province}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  </FormControl>
</Grid>

<Grid item xs={12} sm={4}>
  <FormControl size="small" fullWidth>
    <InputLabel id="district" shrink={true}>
      District
    </InputLabel>
    <Controller
      name="district"
      control={control}
      defaultValue={selectedDistrict || ""}
      render={({ field }) => (
        <Select
          {...field}
          labelId="district"
          label="District"
          size="small"
          value={field.value || ""}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedDistrict(value);
            field.onChange(value); // Manually trigger the field's onChange event
          }}
          InputLabelProps={{
            shrink: true
          }}
        >
          {uniqueDistricts.map((district) => (
            <MenuItem key={district} value={district}>
              {district}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  </FormControl>
</Grid>

        {/* Local Level select */}

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="localLevel" >
              Local Level
            </InputLabel>
            <Controller
  name="localLevel"
  control={control}
  defaultValue=""
  render={({ field }) => (
    <Select
      {...field}
      labelId="localLevel"
      label="Local Level"
      size="small"
      onChange={(e) => {
        const value = e.target.value;
        onChange(null, 'localLevel', value); // Ensure onChange updates state
        field.onChange(value); // Trigger the field's onChange
      }}
    >
      {uniqueLocalLevels.map((localLevel, index, array) => (
        <MenuItem key={localLevel} value={localLevel}>
          {localLevel}
        </MenuItem>
      ))}
    </Select>
  )}
/>

          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
  <FormControl size="small" fullWidth>
    <InputLabel id="wardNo" required>
      Ward No
    </InputLabel>
    <Controller
      name="wardNo"
      control={control}
      defaultValue={universityInfo.wardNo || ""}
      render={({ field }) => (
        <ValidationSelect
          {...field}
          labelId="wardNo"
          label="Ward No"
          size="small"
          onChange={(e) => {
            const value = e.target.value;
            field.onChange(value); // Manually trigger the field's onChange event
          }}
          error={!!errors.wardNo}
          helperText={errors.wardNo ? errors.wardNo.message : ""}
        >
          {getWardOptions(universityInfo.localLevel).map((ward) => (
            <MenuItem key={ward} value={ward}>
              {ward}
            </MenuItem>
          ))}
        </ValidationSelect>
      )}
    />
  </FormControl>
</Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="locality"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="locality"
                name="locality"
                size="small"
                label="Tole"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="url"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /^(ftp|http|https):\/\/[^ "]+$/i,
                message: "Invalid URL",
              },
            }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="url"
                name="url"
                size="small"
                label="URL"
                placeholder="https://domain.com"
                fullWidth
                error={!!errors.url}
                helpertext={errors.url && errors.url.message}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl>
            <InputLabel size="small" shrink>University Logo</InputLabel>
            <TextField
              id="universityLogo"
              type="file"
              size="small"
              variant="standard"
              name="universityLogo"
              accept="image/*"
              onChange={(e) => onChange(e, "universityLogo", e.target.files[0])}
              // inputProps={{ required: true }}
              style={{
                // backgroundColor: "#2196F3", // Blue color
                color: "#FFFFFF", // White text color
                padding: "10px 15px", // Padding for better appearance
                border: "none", // Remove default border
                borderRadius: "4px", // Rounded corners
                cursor: "pointer", // Show pointer cursor when hovering
              }}
            />
          </FormControl>
        </Grid>
       
      </Grid>
      <Box mt={4} display="flex" justifyContent="space-between">
      <Button
          variant="contained"
          size="small"
          type="submit"
          disabled
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};
export { EditUniversityInfoProvider, EditUniversityInfoContext };
export default EditUniversityInfo;
