import React, { useContext, useState } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Box, Stack, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { EditCampusInfoContext } from './EditCampusInfo';
import { EditCampusContactContext } from './EditCampusContactDetails';
import axios from 'axios';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EditCampusReviewDetails = ({ handleBack, id, onClose }) => {

  const { campusInfo } = useContext(EditCampusInfoContext);
  const { contactDetails } = useContext(EditCampusContactContext);
  const [loading, setLoading] = useState(false);
  const handleRegister = async () => {
    setLoading(true); 

    const formData = new FormData();
    formData.append("id", campusInfo.id);
    formData.append("universityId", campusInfo.universityId);
    formData.append("typeOfCampus", campusInfo.type);
    formData.append("campusName", campusInfo.name);
    formData.append("code", campusInfo.code);
    formData.append("shortCode", campusInfo.shortCode);
    formData.append("alias", campusInfo.alias);
    formData.append("phone", campusInfo.phone);
    formData.append("email", campusInfo.email);
    formData.append("url", campusInfo.url);
    formData.append("province", campusInfo.province);
    formData.append("district", campusInfo.district);
    formData.append("localLevel", campusInfo.localLevel);
    formData.append("wardNo", campusInfo.wardNo);
    formData.append("Logo", campusInfo.logo || ""); // Append logo as a file
    formData.append("logo", campusInfo.campusLogo || ""); // Append logo as a file
    formData.append("dateOfEstd", contactDetails.dateOfEstd);
    formData.append("yearOfEstd", contactDetails.yearOfEstd);
    formData.append("accreditationStatus", contactDetails.accreditationStatus);
    formData.append("contactName", contactDetails.focalPersonName);
    formData.append("contactPersonPosition", contactDetails.position);
    formData.append("contactPhoneNo", contactDetails.contactNumber);
    formData.append("contactEmail", contactDetails.contactEmail2);
    formData.append("bankName", contactDetails.bankName);
    formData.append("branch", contactDetails.branchName);
    formData.append("accountNo", contactDetails.accountNumber);
    try {

    const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    const response = await axios.patch(`${backendUrl}/Campus/${id}`, formData, config);
    if (response.status === 200) {
      toast.success("University Successfully Updated", {
        autoClose: 2000
      });
    } else {
      throw new Error("Network response was not ok");
    }
    onClose()
  } catch (error) {
    console.error("Failed to Register:", error);
    toast.error("Failed to Register", {
      autoClose: 2000
    });
  } finally {
    setLoading(false);
  }
};
   
  const CampusInfo = campusInfo
    ? [
      { name: "Campus Type:", detail: campusInfo.type },
      { name: "Campus Name:", detail: campusInfo.name },
      { name: "Year of Establishment:", detail: contactDetails.yearOfEstd },
      { name: "Date of Establishment:", detail: contactDetails.dateOfEstd },
      { name: "Affiliated University:", detail: campusInfo.universityId },
      { name: "Campus Code:", detail: campusInfo.code },
      { name: "Phone Number:", detail: campusInfo.phone },
      { name: "Email:", detail: campusInfo.email },
      { name: "url:", detail: campusInfo.url },
      { name: "Short Name:", detail: campusInfo.shortCode },
      { name: "Alias:", detail: campusInfo.alias },
    ]
    : [];

  const contactPerson = contactDetails
    ? [
      {
        name: "Accreditation Status:",
        detail: contactDetails.accreditationStatus,
      },
      { name: "Full Name:", detail: contactDetails.focalPersonName },
      { name: "Contact Number:", detail: contactDetails.contactNumber },
      { name: "Position:", detail: contactDetails.position },
      { name: "Email:", detail: contactDetails.contactEmail },
      { name: "Bank Name:", detail: contactDetails.bankName },
      { name: "Account Number:", detail: contactDetails.accountNumber },
      { name: "Branch:", detail: contactDetails.branchName },
    ]
    : [];

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Grid item xs={12} md={12}>
            <Box border='1px solid #c2c2c2' borderRadius='10px' position='relative' paddingBottom='15px'>
              <Typography variant="subtitle1" display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
                Campus Details
              </Typography>
              <Grid container paddingLeft='2.5rem' justifyContent="flex-start">
                {CampusInfo.map((details, index) => (
                  <Grid key={index} item xs={6}>
                    <Typography variant="body1" gap={1}>{details.name}</Typography>
                    <Typography variant="subtitle1" color='#1976d2'>{details.detail}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid  mt='.8rem'  item xs={12} md={12}>
            <Box border='1px solid #c2c2c2' borderRadius='10px' position='relative' paddingBottom='15px'>
              <Typography variant="subtitle1" display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
                Contact Person Details
              </Typography>
              <Grid container paddingLeft='2.5rem' justifyContent="flex-start">
                {contactPerson.map((contact, index) => (
                  <Grid item xs={6}>
                    <Typography variant="body1">{contact.name}</Typography>
                    <Typography variant="body2" color='#1976d2'>{contact.detail}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} alignContent='left'>
            <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
              <Typography variant="subtitle2">Address details</Typography>
              <Typography color='#1976d2'>
                {campusInfo &&
                  [
                    campusInfo.province,
                    campusInfo.district,
                    campusInfo.localLevel,
                    campusInfo.wardNo,
                    campusInfo.locality,
                  ].join(" ")}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Box mt={4} display="flex" justifyContent="left" alignContent='left'>
          <Button onClick={handleBack} sx={{ color: '#2b6eb5' }} type="reset" size="small"
            startIcon={<ChevronLeftRoundedIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            size="small"
            type="submit"
            sx={{
              bgcolor: "#2b6eb5",
              marginBottom: "10px",
              marginLeft: "10px",
              position: 'relative', // To position the loader correctly
          // Disable the button while loading
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.6 : 1,
            }}
            onClick={handleRegister}
            endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}

          >
        {loading ? 'Submitting...' : 'Update'}
        </Button>
        </Box>
      </Grid>

      {/* Buttons */}

    </>
  );
};

export default EditCampusReviewDetails;
