import React, { useState } from "react";
import {
  Box,
  Grid,
  Step,
  StepLabel,
  Paper,
  Stepper,
  Typography,
} from "@mui/material";
import UniversityInfo from "../../components/universityRegister/UniversityInfo";
import ContactDetails from "../../components/universityRegister/ContactDetails";
import ReviewDetails from "../../components/universityRegister/ReviewDetails";
import { UniversityInfoProvider } from "../../components/universityRegister/UniversityInfo";
import { UniversityContactProvider } from "../../components/universityRegister/ContactDetails";

const steps = ["University Info", "Estd. & Bank Details", "Review Details"];

export default function UniversityRegister() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <UniversityInfoProvider>
      <UniversityContactProvider>
        <Grid container sx={{ minHeight: "0" }} spacing={3} marginTop={0}>
          <Grid item xs={false} md={1} />
          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            sx={{  p: 4 }}
          >
            <Paper elevation={5} sx={{ borderRadius: "20px" }}>
              <Box sx={{ maxWidth: "100%", mx: "auto" }}>
                <Typography
                  variant="h5"
                  mb={2}
                  textAlign="center"
                  p="10px"
                  sx={{ color: "#2b6eb5" }}
                >
                  Register University
                </Typography>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <span
                          style={{
                            color: index <= activeStep ? "#2b6eb5" : "inherit",
                          }}
                        >
                          {label}
                        </span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box mt={2} mx={3}>
                  {activeStep === 0 && (
                    <UniversityInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  )}
                  {activeStep === 1 && (
                    <ContactDetails
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  )}
                  {activeStep === 2 && (
                    <ReviewDetails handleBack={handleBack} />
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={false} md={2} />
        </Grid>
      </UniversityContactProvider>
    </UniversityInfoProvider>
  );
}
