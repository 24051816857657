import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import axios from 'axios';

const columns = [
  { name: 'no', label: 'S.No.', options: { sort: false, filter: false } },
  { name: 'campus', label: 'Campus Name', options: { sort: false, filter: false } },
  { name: 'level', label: 'Level', options: { filter: true, sort: false } },
  { name: 'faculty', label: 'Faculty', options: { filter: true, sort: false } },
  { name: 'program', label: 'Program', options: { filter: true, sort: false } },
  { name: 'male', label: 'Male', options: { filter: false, sort: false } },
  { name: 'female', label: 'Female', options: { filter: false, sort: false } },
  { name: 'totalGender', label: 'Total Gender', options: { filter: false, sort: false } },
  { name: 'madhesi', label: 'Madhesi', options: { filter: false, sort: false } },
  { name: 'janjati', label: 'Janajati', options: { filter: false, sort: false } },
  { name: 'tharu', label: 'Tharu', options: { filter: false, sort: false } },
  { name: 'edj', label: 'EDJ', options: { filter: false, sort: false } },
  { name: 'dalit', label: 'Dalit', options: { filter: false, sort: false } },
  { name: 'totalEthnicity', label: 'Total Caste', options: { filter: false, sort: false } },
];


const options = {
    filterType: '',
    selectableRows: false,
    responsive: 'standard',
    elevation: 0,
    pagination: true,
    search: true,
    searchPlaceholder: 'Search...',
    searchProps: {
        style: {
            fontSize: 14,
            padding: 4,
            borderRadius: 4,
          // border: '1px solid #ccc',
            width: 6,
        },
        inputProps: {
            placeholder: 'Search...',
            style: {
                fontSize: 14,
                padding: 4,
            },
        },
    },
    print: true,
  download: true,
  viewColumns: false,
  rowsPerPage: 15,
};

// const getMuiTheme = () =>
//   createTheme({
//     components: {
//       MuiTableCell: {
//         styleOverrides: {
//           head: {
//             backgroundColor: '#2b6eb5',
//             color: 'whitesmoke',
//             textTransform: 'capitalize',
//             padding: '6px',
//             fontSize: '14px',
//             border: '2px solid #2b6eb5',
//           },
//           body: {
//             fontSize: '14px',
//             padding: '6px',
//             border: '1px solid #C2C2C2',
//           },
//         },
//       },
//     },
//   });
const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: '#2b6eb5',
              color: 'whitesmoke',
              textTransform: 'capitalize',
              padding: '6px',
              fontSize: '12px',
              border: '1px solid #C2C2C2',
            },
            body: {
              fontSize: '12px',
              padding: '6px',
              border: '1px solid #C2C2C2',
            },
            root: {
              justifyContent: 'center',
              '& input': {
                textAlign: 'center',
              },
              padding: '0px',
            },
          },
        },
      },
    });

const CustomHeader = () => {
  return (
    <thead>
      <tr style={{ backgroundColor: '#2b6eb5', color: 'whitesmoke' }}>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', fontSize:'14px' }}>S.No.</th>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', fontSize:'14px' }}>Campus Name</th>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', fontSize:'14px' }}>Level</th>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', fontSize:'14px' }}>Faculty</th>
        <th rowSpan="2" style={{ border: '1px solid #c2c2c2', fontSize:'14px' }}>Program</th>
        <th colSpan="3" style={{ border: '1px solid #c2c2c2', fontSize:'14px' }}>Gender</th>
        <th colSpan="6" style={{ border: '1px solid #c2c2c2', fontSize:'14px' }}>Caste Ethnicity</th>
      </tr>
      <tr style={{ backgroundColor: '#2b6eb5', color: 'whitesmoke' }}>
        <th style={{ border: '1px solid #c2c2c2', padding:'0px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Male</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Female</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Total</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Madhesi</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Janajati</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Tharu</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>EDJ</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Dalit</th>
        <th style={{ border: '1px solid #c2c2c2', padding:'4px',backgroundColor: "#2b6eb5", color: "#FFFFFF",fontSize:'14px' }}>Total</th>
      </tr>
    </thead>
  );
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const StudentSummary = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
        const response = await axios.get(`${backendUrl}/Student/Report/GetStudentSummary`, config);
        const updatedData = response.data.map((student, index) => ({
          ...student,
          no: index + 1,
        }));
        setData(updatedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={<Typography variant='body1' style={{ color: '#2b6eb5',  padding: '5px', textAlign: 'right' }}>Student Summary</Typography>}
        data={data}
        columns={columns}
        options={options}
        components={{
          TableHead: CustomHeader
        }}
      />
    </ThemeProvider>
  );
};

export default StudentSummary;
