import { useState, useEffect } from "react";

export default function Main() {
  const [formData, setFormData] = useState({
    amount: "500",
    tax_amount: "0",
    total_amount: "500",
    transaction_uuid: "",
    product_code: "EPAYTEST",
    product_service_charge: "0",
    product_delivery_charge: "0",
    success_url: "http://localhost:3001/complete-payment",
    failure_url: "https://developer.esewa.com.np/failure",
    signed_field_names: "total_amount,transaction_uuid,product_code",
    signature: "",
    secret: "8gBm/:&EnhH.1/q"
  });

  useEffect(() => {
    generateTransactionUUID();
  }, []);


  const generateTransactionUUID = () => {
    const currentTime = new Date();
    const formattedTime = currentTime.toISOString().slice(2, 10).replace(/-/g, '') +
      '-' + currentTime.getHours() + currentTime.getMinutes() + currentTime.getSeconds();
    setFormData({
      ...formData,
      transaction_uuid: formattedTime
    });
  };

  const generateSignature = async () => {
    const {
      total_amount,
      transaction_uuid,
      product_code,
      secret
    } = formData;

    const data = `total_amount=${total_amount},transaction_uuid=${transaction_uuid},product_code=${product_code}`;

    try {
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(data);
      const key = await window.crypto.subtle.importKey(
        "raw",
        encoder.encode(secret),
        { name: "HMAC", hash: { name: "SHA-256" } },
        false,
        ["sign"]
      );

      const signature = await window.crypto.subtle.sign(
        "HMAC",
        key,
        encodedData
      );

      const base64Signature = btoa(String.fromCharCode(...new Uint8Array(signature)));
      setFormData({
        ...formData,
        signature: base64Signature
      });

    } catch (error) {
      console.error("Error generating signature:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await generateSignature();
      const form = event.target;
      form.submit();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;

    setFormData({
      ...formData,
      [id]: value
    });

    if (id === "total_amount" || id === "transaction_uuid" || id === "product_code") {
      generateSignature();
    }
  };

  return (
    <div>
      <div>
        <b>eSewa ID:</b> 9806800001 <br />
        <b>Password:</b> Nepal@123 <br />
        <b>MPIN:</b> 1122 <b>Token:</b> 123456
      </div>

      <form
        action="https://rc-epay.esewa.com.np/api/epay/main/v2/form"
        method="POST"
        // target="_blank"
        onSubmit={handleSubmit}
      >
        <br /><br />
        <table>
          <tbody>
            <tr>
              <td><strong>Parameter</strong></td>
              <td><strong>Value</strong></td>
            </tr>
            <tr>
              <td>Amount:</td>
              <td>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  defaultValue={formData.amount}
                  className="form"
                  required
                  onChange={handleInputChange}
                />
                <br />
              </td>
            </tr>
            <tr>
              <td>Tax Amount:</td>
              <td>
                <input
                  type="text"
                  id="tax_amount"
                  name="tax_amount"
                  defaultValue={formData.tax_amount}
                  className="form"
                  required
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Total Amount:</td>
              <td>
                <input
                  type="text"
                  id="total_amount"
                  name="total_amount"
                  defaultValue={formData.total_amount}
                  className="form"
                  required
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Transaction UUID (Item Purchase ID):</td>
              <td>
                <input
                  type="text"
                  id="transaction_uuid"
                  name="transaction_uuid"
                  value='11-201-13'
                  className="form"
                  required
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>Product Code:</td>
              <td>
                <input
                  type="text"
                  id="product_code"
                  name="product_code"
                  defaultValue='EPAYTEST'
                  className="form"
                  required
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Product Service Charge:</td>
              <td>
                <input
                  type="text"
                  id="product_service_charge"
                  name="product_service_charge"
                  defaultValue={formData.product_service_charge}
                  className="form"
                  required
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Product Delivery Charge:</td>
              <td>
                <input
                  type="text"
                  id="product_delivery_charge"
                  name="product_delivery_charge"
                  defaultValue={formData.product_delivery_charge}
                  className="form"
                  required
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Success URL:</td>
              <td>
                <input
                  type="text"
                  id="success_url"
                  name="success_url"
                  defaultValue={formData.success_url}
                  className="form"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Failure URL:</td>
              <td>
                <input
                  type="text"
                  id="failure_url"
                  name="failure_url"
                  defaultValue={formData.failure_url}
                  className="form"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Signed Field Names:</td>
              <td>
                <input
                  type="text"
                  id="signed_field_names"
                  name="signed_field_names"
                  defaultValue={formData.signed_field_names}
                  className="form"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Signature:</td>
              <td>
                <input
                  type="text"
                  id="signature"
                  name="signature"
                  value='4Ov7pCI1zIOdwtV2BRMUNjz1upIlT/COTxfLhWvVurE='
                  className="form"
                  required
                //   readOnly
                />
              </td>
            </tr>
            {/* <tr>
              <td>Secret Key:</td>
              <td>
                <input
                  type="text"
                  id="secret"
                  name="secret"
                  defaultValue='8gBm/:&EnhH.1/q'
                  className="form"
                  required
                />
              </td>
            </tr> */}
          </tbody>
        </table>
        <input
          type="submit"
          value=" Pay with eSewa "
          className="button"
          style={{
            display: 'block',
            backgroundColor: '#60bb46',
            cursor: 'pointer',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
          }}
        />
      </form>
    </div>
  );
}
