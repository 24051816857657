import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function StudentMenu({
  anchorElStudent,
  handleCloseStudentMenu,
}) {
  const { currentUser } = useSelector((state) => state.user);

  const userRole = currentUser?.listUser?.[0]?.roleName || "";
  console.log(userRole);

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElStudent}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElStudent)}
      onClose={handleCloseStudentMenu}
    >
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/student-list"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          color="inherit"
          size="small"
          onClick={handleCloseStudentMenu}
        >
          Register (By student)
        </Typography>
      </MenuItem>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/verified-students"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          color="inherit"
          size="small"
          onClick={handleCloseStudentMenu}
        >
          Register (By Admin)
        </Typography>
      </MenuItem>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/upgrade-academics"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          color="inherit"
          size="small"
          onClick={handleCloseStudentMenu}
        >
          Semester Upgrade
        </Typography>
      </MenuItem>
    </Menu>
  );
}
