import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid, Paper, Typography } from '@mui/material';
import {  getStudentsByEthnicityDashboard } from '../dashboard/services/service';

const StudentByEthnicity = ({ authToken }) => {
  const [studentsData, setStudentsData] = useState([]);

  useEffect(() => {
    if (!authToken) return;
    const fetchData = async () => {
      try {
        const data = await getStudentsByEthnicityDashboard(authToken);
        setStudentsData(data || []); // Ensure data is an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [authToken]);

  const generateChartOption = () => {
    const categories = ['Dalit', 'Janajati', 'Madhesi', 'Tharu', 'EDJ'];

    // Prepare data for chart
    const seriesData = [
      {
        name: 'Male',
        type: 'bar',
        data: categories.map(category => 
          studentsData.filter(item => item.ethnicity === category && item.gender === 'male').length
        ),
        itemStyle: { color: '#1976d2' },
        label: { show: true, position: 'top', color: '#000' }, // Change position to 'top'
      },
      {
        name: 'Female',
        type: 'bar',
        data: categories.map(category => 
          studentsData.filter(item => item.ethnicity === category && item.gender === 'female').length
        ),
        itemStyle: { color: '#FABC3F' },
        label: { show: true, position: 'top', color: '#000' }, // Change position to 'top'
      },
    ];

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: seriesData.map(series => series.name),
        textStyle: { color: '#2E294E' },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: 'category',
        data: categories,
        axisLabel: { color: '#333' },
        name: "Ethnicity", // Title for x-axis
        nameLocation: 'middle',
        nameGap: 35,
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        name: "No. of Students", // Title for y-axis
        nameLocation: 'middle',
        nameGap: 35,
        axisLabel: { color: '#333' },
      },
      series: seriesData,
    };
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ padding: '20px' }}>
          <Typography variant='body1' align="center" fontWeight={900} style={{ padding: '1rem' }}>
            Students by Ethnicity
          </Typography>
          {studentsData.length > 0 ? (
            <ReactECharts
              option={generateChartOption()}
              style={{ height: '400px' }}
              opts={{ renderer: 'canvas' }} // Use canvas renderer for better performance
            />
          ) : (
            <Typography variant="body1" align="center" color="textSecondary">
              No data available
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StudentByEthnicity;
