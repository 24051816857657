import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Button, Box, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useNavigate } from "react-router-dom";
import { EditUniversityInfoContext } from "./EditUniversityInfo";
import { EditUniversityContactContext } from "./EditContactDetails";
import axios from "axios";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

const backendUrl = process.env.REACT_APP_BACKEND_URL; 

const EditReviewDetails = ({ handleBack, id, onClose }) => {
  const { universityInfo } = useContext(EditUniversityInfoContext);
  const { contactDetails } = useContext(EditUniversityContactContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

const handleRegister = async () => {
  setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("type", universityInfo.type);
    formData.append("name", universityInfo.name);
    formData.append("code", universityInfo.code);
    formData.append("shortCode", universityInfo.shortCode);
    formData.append("alias", universityInfo.alias);
    formData.append("phoneNo", universityInfo.phone);
    formData.append("email", universityInfo.email);
    formData.append("url", universityInfo.url || "");
    formData.append("province", universityInfo.province);
    formData.append("district", universityInfo.district);
    formData.append("localLevel", universityInfo.localLevel);
    formData.append("wardNo", universityInfo.wardNo);
    formData.append("logo", universityInfo.logo || ""); 
    formData.append("universityLogo", universityInfo.universityLogo || 0); 
    formData.append("dateOfEstd", contactDetails.dateOfEstd);
    formData.append("yearOfEstd", contactDetails.yearOfEstd);
    formData.append("accreditationStatus", contactDetails.accreditationStatus || "");
    formData.append("focalPersonName", contactDetails.focalPersonName);
    formData.append("position", contactDetails.position);
    formData.append("contactNo", contactDetails.contactNumber);
    formData.append("contactEmail", contactDetails.contactEmail);
    formData.append("bankName", contactDetails.bankName || '');
    formData.append("branch", contactDetails.branchName || '');
    formData.append("accountNo", contactDetails.accountNumber || '');
    // Obtain token from localStorage
    try {
    const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    // Make sure backendUrl and id are defined
    const response = await axios.patch(`${backendUrl}/University/Patch?universityId=${id}`, formData, config);
    if (response.status === 200) {
      toast.success("University Successfully Updated", {
        autoClose: 2000

      });
      onClose()
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (err) {
    if (err.response && err.response.status === 409) {
      toast.error("Student already exists!", {
        autoClose: 2000,
      });
    } else {
      toast.error("Failed to add data!", {
        autoClose: 2000,
      });
    }
  } finally {
    setLoading(false); // Stop loading
  }
};

  const UniversityInfo = universityInfo
    ? [
      { name: "University Type:", detail: universityInfo.type },
      { name: "University Name:", detail: universityInfo.name },
      { name: "Year of Establishment:", detail: contactDetails.yearOfEstd },
      { name: "Date of Establishment:", detail: contactDetails.dateOfEstd },
      { name: "University Code:", detail: universityInfo.code },
      { name: "Phone Number:", detail: universityInfo.phone },
      { name: "Email:", detail: universityInfo.email },
      { name: "url:", detail: universityInfo.url },
      { name: "Short Name:", detail: universityInfo.shortCode },
      { name: "Alias:", detail: universityInfo.alias },
    ]
    : [];
  // Construct contact person details
  const contactPerson = contactDetails
    ? [
      { name: "Full Name:", detail: contactDetails.focalPersonName },
      { name: "Contact Number:", detail: contactDetails.contactNumber },
      { name: "Position:", detail: contactDetails.position },
      { name: "Email:", detail: contactDetails.contactEmail },
      { name: "Bank Name:", detail: contactDetails.bankName },
      { name: "Account Number:", detail: contactDetails.accountNumber },
      { name: "Branch:", detail: contactDetails.branchName },
      {
        name: "Accreditation Status:",
        detail: contactDetails.accreditationStatus,
      },
    ]
    : [];

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12} >
          <Grid container spacing={1} >
            <Grid item xs={12} md={12} >
              <Box border='1px solid #c2c2c2' borderRadius='10px' position='relative' paddingBottom='15px'>
                <Typography variant="subtitle1" display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
                  University Details
                </Typography>
                <Grid container paddingLeft='2.5rem' justifyContent="flex-start">
                  {UniversityInfo.map((details, index) => (
                    <Grid key={index} item xs='5'>
                      <Stack direction="row" spacing={1} sx={{ width: '100%', mb: 1 }}>
                        <Typography variant="body1" gap={1}>{details.name}</Typography>
                        <Typography variant="subtitle" color='#1976d2'>{details.detail || '-'}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid mt='.7rem' item xs={12} md={12}>
              <Box border='1px solid #c2c2c2' borderRadius='10px' position='relative' paddingBottom='15px'>
                <Typography
                  variant="subtitle1" display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'
                >
                  Contact Person Details
                </Typography>
                <Grid container paddingLeft='2.5rem' justifyContent="flex-start">
                  {contactPerson.map((contact, index) => (
                    <Grid key={index} item xs={5}>
                      <Stack direction="row" spacing={1} sx={{ width: '100%', mb: 1 }}>
                        <Typography variant="body1">{contact.name}</Typography>
                        <Typography variant="body2" color='#1976d2'>{contact.detail || '-'}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} alignContent='left'>
            <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
              <Typography variant="subtitle2">Address details</Typography>
              <Typography color='#1976d2'>
                {universityInfo &&
                  [
                    universityInfo.province,
                    universityInfo.district,
                    universityInfo.localLevel,
                    universityInfo.wardNo,
                    universityInfo.locality,
                  ].join(" ")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {/* Buttons */}
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button onClick={handleBack} sx={{ color: '#2b6eb5' }} type="reset" size="small"
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
            position: 'relative', // To position the loader correctly
          // Disable the button while loading
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.6 : 1,
          }}
          onClick={handleRegister}
          endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}        >
          Update
        </Button>
      </Box>
    </>

  );
};


export default EditReviewDetails;
