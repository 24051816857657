import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Margin, Padding } from '@mui/icons-material';
import AdminLogin from './AdminLogin';
import StudentLogin from './StudentLogin';
import axios from 'axios';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  display: 'flex',
  justifyContent: 'center',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 80,
    width: '100%',
    backgroundColor: '#2B6EB5',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    minWidth: 120, // Adjust the width of the tabs
    '&.Mui-selected': {
      color: 'black',
      backgroundColor: 'white',
      borderRadius:'5px',
      margin:4,
      
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'white',
    },
  })
);

export default function Login() {
  const [value, setValue] = React.useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally

      }}
    >
      <Box
        sx={{
          width: 360, 
          bgcolor: '#2B6EB5',
          borderRadius: 2, 
          boxShadow: 3,
          textAlign: 'center',
          border: '1px solid #ddd',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Admin-Login" />
          <StyledTab label="Student- Login" />
        </StyledTabs>
       
      </Box>
      
    </Box>
    <Box sx={{ p: 2, width: '100%' }}>
          {value === 0 && <AdminLogin />} {/* Render AdminLogin component */}
          {value === 1 && <StudentLogin/>} {/* Render StudentLogin component */}
        </Box>
       
        </>
  );
}
