import React, { createContext, useContext, useState } from 'react';
import { Grid, TextField, Button, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const UniversityContactContext = createContext();

const UniversityContactProvider = ({ children }) => {
  const methods = useForm();
  const [contactDetails, setContactDetails] = useState({
    yearOfEstd: "",
    dateOfEstd: 0,
    accreditationStatus: "",
    focalPersonName: '',
    position: '',
    contactNumber: '',
    contactEmail: '',
    bankName: '',
    branchName: '',
    accountNumber: '',
  });

  // Update the contactDetails state as the form data changes
  const onChange = (name, value) => {
    setContactDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <UniversityContactContext.Provider value={{ ...methods, contactDetails, onChange }}>
      {children}
    </UniversityContactContext.Provider>
  );
};


const ContactDetails = ({ handleNext, handleBack }) => {
  const { control, handleSubmit, formState: { errors } } = useContext(UniversityContactContext);
  const { onChange } = useContext(UniversityContactContext);

  const onSubmit = (data) => {
    onChange("yearOfEstd", data.yearOfEstd);
    onChange("dateOfEstd", data.dateOfEstd);
    onChange("accreditationStatus", data.accreditationStatus);
    onChange("focalPersonName", data.focalPersonName);
    onChange("position", data.position);
    onChange("contactNumber", data.contactNumber);
    onChange("contactEmail", data.contactEmail);
    onChange("bankName", data.bankName);
    onChange("branchName", data.branchName);
    onChange("accountNumber", data.accountNumber);
    handleNext();
  };

  const onBack = (data) => {
    console.log(data);
    handleBack(); // Call handleNext only if form submission is successful
  };

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} onReset={() => { onBack() }}>
      <Grid mt='.5rem' container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Controller
            name="yearOfEstd"
            control={control}
            rules={{
              pattern: {
                value: /^\d{0,4}$/,
                message: "Please enter only numeric values with maximum length of four digits"
              }
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
              required
                {...field}
                id="yearOfEstd"
                size="small"
                type="text"
                label="Year Of Establishment"
                name="yearOfEstd"
                InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, ''); // Allow only numeric characters
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4); // Limit input to maximum of four characters
                    }
                  },
                }}
                fullWidth
                error={!!errors.yearOfEstd}
                helpertext={errors.yearOfEstd ? errors.yearOfEstd.message : ""}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2.75}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker', 'DateField']} sx={{ mt: '-8px', overflow: 'hidden' }}>
            <Controller
              name="dateOfEstd"
              control={control}
              defaultValue={null} // Set your default value here
              render={({ field }) => (
                <DateField
                  {...field}
                  required
                  label='Date of establishment'
                  enableAccessibleFieldDOMStructure
                  slotProps={{ textField: { size: 'small' } }}
                  format="YYYY/MM/DD"
                />
              )}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
        <Grid item xs={12} sm={3}>
        <FormControl fullWidth size='small'>
        <InputLabel>Accreditation Status</InputLabel>
          <Controller
            name="accreditationStatus"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                id="accreditationStatus"
                size="small"
                name="accreditationStatus"
                label="Accreditation Status"
                fullWidth

              >
                <MenuItem value='yes'>Yes</MenuItem>
                <MenuItem value='no'>No</MenuItem>

              </Select>
            )}
          />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name='focalPersonName'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
              required
                {...field}
                id="focalPersonName"
                size="small"
                type='text'
                name="focalPersonName"
                label="Contact Person Name"
                fullWidth
                
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name='position'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
              required
                {...field}
                id="position"
                size="small"
                name="position"
                label="Position"
                fullWidth
                
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name='contactNumber'
            control={control}
            rules={{
              validate: (value) => {
                const isValidPhone = /^[9]\d{9}$/.test(value);
                return isValidPhone || "invalid phone umber";
              },
            }}
            defaultValue=''
            render={({ field }) => (
              <TextField
              required
                {...field}
                id="contactNumber"
                size="small"
                name="contactNumber"
                label="Contact Number"
                InputProps={{
                  placeholder: "98XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10); // Limit input to 10 characters
                  },
                }}
                fullWidth
                error={!!errors.contactNumber}
                helpertext={errors.contactNumber ? errors.contactNumber.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name='contactEmail'
            control={control}
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email format not matched",
              },
            }}
            defaultValue=''
            render={({ field }) => (
              <TextField
              required
                {...field}
                id="contactEmail"
                size="small"
                name="contactEmail"
                label="Contact Email"
                fullWidth
                error={!!errors.contactEmail}
                helpertext={errors.contactEmail && errors.contactEmail.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name='bankName'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
              
                {...field}
                id="bankName"
                size="small"
                name="bankName"
                label="Bank Name"
                fullWidth
               
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name='branchName'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
              
                {...field}
                id="branchName"
                name="branchName"
                size="small"
                label="Bank Branch"
                fullWidth
                
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name='accountNumber'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextField
              
                {...field}
                id="accountNumber"
                name="accountNumber"
                size="small"
                label="Bank Account Number"
                fullWidth
               
              />
            )}
          />
        </Grid>

      </Grid>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="standard"
          size="small"
          type="reset"
          sx={{
            color: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
            bgcolor: "#2b6eb5",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};
export { UniversityContactProvider, UniversityContactContext };
export default ContactDetails;
