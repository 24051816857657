
import React, { useState } from "react";
import {
  Box,
  Grid,
  Step,
  StepLabel,
  Paper,
  Stepper,
  Typography,
} from "@mui/material";
import EmployeeGeneralInfo, { EmployeeInfoProvider } from "./employeeGeneralInfo";
import AcademyGeneralInfo from "./AcademyGeneralInfo";
import {WorkInfoProvider} from "./Worknfo";
import  {
  AddressInfoProvider,
} from "./EmployeeAdressGeneralInfo";

import DetailsReview from "./DetailsReview";
import { AcademyInfoProvider } from "./AcademyGeneralInfo";
import EmployeeAddressGeneralInfo from "./EmployeeAdressGeneralInfo";
import WorkGeneralInfo from "./Worknfo";
import { useLocation, useSearchParams } from "react-router-dom";

const steps = [
  "General Info",
  "Address Info",
  "Work Info",
  "Academic Info",
  "Review all",
];
export default function EmployeeRegister() {
  const [activeStep, setActiveStep] = useState(0);
  const [searchParams] = useSearchParams();
  const employeeType = searchParams.get("employeeType");
  console.log(employeeType)

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <EmployeeInfoProvider>
    <AddressInfoProvider>
        <WorkInfoProvider>
          <AcademyInfoProvider>
        <Grid container sx={{ minHeight: "0"}} spacing={0} marginTop={2}>
          <Grid item xs={false} md={1}  />
          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            sx={{ backgroundColor: "background.default", p: 0, borderRadius: "20px"}}
          >
            <Paper elevation={5} sx={{ borderRadius: "20px" }}>
              <Box sx={{ maxWidth: '100%', mx: "auto" }}>
                <Typography
                  variant="h5"
                  mb={2}
                  textAlign="center"
                  p="10px"
                  sx={{ color: "#2A629A" }}
                >
                  Employee Registration
                </Typography>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <span
                          style={{
                            color: index <= activeStep ? "#003285" : "inherit",
                          }}
                        >
                          {label}
                        </span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box mt={2} mx={3}>
                  {activeStep === 0 && (
                    <EmployeeGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                      employeeType={employeeType}
                    />
                  )}
                  {activeStep === 1 && (
                    <EmployeeAddressGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  )}
                   {activeStep === 2 && (
                    <WorkGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  )}
                   {activeStep === 3 && (
                    <AcademyGeneralInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  )}
                  {activeStep === 4 && (
                    <DetailsReview handleBack={handleBack} />
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={false} md={2} />
        </Grid>
        </AcademyInfoProvider>
        </WorkInfoProvider>
        </AddressInfoProvider>
        </EmployeeInfoProvider>
  );
  }

