import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function InfrastructureMenu({
  anchorElInfrastructure,
  handleCloseInfrastructureMenu,
}) {
  const { currentUser } = useSelector((state) => state.user);

  const userRole = currentUser?.listUser?.[0]?.roleName || "";
  console.log(userRole);

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElInfrastructure}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElInfrastructure)}
      onClose={handleCloseInfrastructureMenu}
    >
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/land-management"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left", // Align text to the left
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseInfrastructureMenu}
          >
            Land Management
          </Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/building-management"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left", // Align text to the left
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseInfrastructureMenu}
          >
            Building Management
          </Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/lab-management"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left", // Align text to the left
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseInfrastructureMenu}
          >
            Lab Management
          </Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/hostel-management"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left", // Align text to the left
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseInfrastructureMenu}
          >
            Hostel Management
          </Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/facility-register"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left", // Align text to the left
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseInfrastructureMenu}
          >
            Register Facility
          </Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/library-management"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left", // Align text to the left
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseInfrastructureMenu}
          >
            Library Management
          </Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/equipment-management"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left", // Align text to the left
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseInfrastructureMenu}
          >
            Equipment Management
          </Typography>
        </MenuItem>
    </Menu>
  );
}
