import { Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function CampusUniMenu({
  anchorElList,
  handleCloseListMenu,
  handleOpenSubMenu,
  anchorEl,
  handleCloseSubMenu,
  handleCloseCampusInfoMenu,
  universityAdmin,
}) {
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const handleOpenSubMenuAndCloseParent = (e) => {
    if (anchorElList) {
    }
    handleOpenSubMenu(e);
    setSubmenuOpen(true);
  };

  const handleCloseMenus = () => {
    handleCloseListMenu();
    handleCloseSubMenu();
    setSubmenuOpen(false);
  };

  return (
    <Menu
      sx={{ mt: "40px" }}
      id="menu-appbar"
      anchorEl={anchorElList}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElList)}
      onClose={handleCloseMenus}
    >
      <>
        <MenuItem
          sx={{ margin: "0px", padding: "0px" }}
          onClick={handleOpenSubMenuAndCloseParent}
        >
          <Typography
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
          >
            Campuses
            <ArrowForwardIcon sx={{ fontSize: "16px" }} />
          </Typography>
        </MenuItem>

        {/* Submenu */}
        <Menu
          anchorEl={anchorEl}
          open={submenuOpen}
          onClose={handleCloseMenus}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "end",
          }}
        >
          <MenuItem
            onClick={handleCloseMenus}
            sx={{ margin: "0px", padding: "0px" }}
          >
            <Typography
              component={Link}
              to="/constituent-campus"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseCampusInfoMenu}
            >
              Constituent Campus
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={handleCloseMenus}
            sx={{ margin: "0px", padding: "0px" }}
          >
            <Typography
              variant="body2"
              component={Link}
              to="/private-campus"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
            >
              Private Campus
            </Typography>{" "}
          </MenuItem>
          <MenuItem
            onClick={handleCloseMenus}
            sx={{ margin: "0px", padding: "0px" }}
          >
            <Typography
              component={Link}
              to="/community-campus"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
            >
              Community Campus
            </Typography>{" "}
          </MenuItem>
        </Menu>
      </>

      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Link
          to="/campus-list"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Typography
            color="inherit"
            size="small"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            onClick={handleCloseListMenu}
          >
            Central Departments
          </Typography>
        </Link>
      </MenuItem>
      {!universityAdmin && (
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/university-list"
            color="inherit"
            size="small"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            onClick={handleCloseListMenu}
          >
            Universities
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
}
