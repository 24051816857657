import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { getEthnicGroup, postEthnicGroup } from "../../../services/services";
import EditEthnicGroup from "./EditEthnicGroup";
import { toast } from "react-toastify";

const EthnicGroup = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [getEthnicGroupData, setGetEthnicGroupData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedEthnicGroupId, setSelectedEthnicGroupId] = useState(null);

  const fetchData = async () => {
    try {
      const data = await getEthnicGroup();
      setGetEthnicGroupData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      // Ensure UniversityId and CampusId default to 0 if not provided
      const formData = {
        name: data.name,
        isActive: data.isActive ==='true',
        description: data.description, 
      };
      await postEthnicGroup(formData);
      toast.success("Data added successfully!", {
        autoClose: 2000,
      });
      fetchData();
    } catch (err) {
      toast.error(`Failed to add data${err}` );
    }
  };

  const handleEditClick = (EthnicGroupId) => {
    setSelectedEthnicGroupId(EthnicGroupId);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchData(); // Fetch updated data after edit
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Ethnic Group Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("name", { required: "EthnicGroup Name is required" })}
                    size="small"
                    label="Ethnic Group Name"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Status</InputLabel>
                          <Select
                            {...register("isActive")}
                            size="small"
                            label="isActive"
                            name="isActive"
                            fullWidth
                            defaultValue={true}
                          >
                            <MenuItem value={true}>
                              <span style={{ color: "green" }}>Active</span>
                            </MenuItem>
                            <MenuItem value={false}>
                              <span style={{ color: "red" }}>InActive</span>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    
                    {...register("description")}
                    size="small"
                    label="Description"
                    fullWidth
                   
                    
                  />
                </Grid>
           
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid margin="10px">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            List of Ethnic Group
          </Typography>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Ethnic Group Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>
                  
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getEthnicGroupData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.name}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.isActive ? (
                        <span style={{ color: "green" }}>Active</span>
                      ) : (
                        <span style={{ color: "red" }}>Inactive</span>
                      )}
                    </TableCell>
                     <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.description}
                    </TableCell>
                    
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      <Button onClick={() => handleEditClick(data.id)}>
                        <EditNoteIcon />
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="md"
      >
        <EditEthnicGroup
          id={selectedEthnicGroupId}
          onClose={() => setOpenEditDialog(false)}
          onUpdate={handleUpdate}
        />
      </Dialog>
    </Grid>
  );
};

export default EthnicGroup;
