import React, { useEffect } from 'react';

import AdminRoute from './AdminRoute.js';
import { useDispatch } from 'react-redux';
import { initializeSession } from './redux/user/userSlice.js';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize session when app loads
    dispatch(initializeSession());
  }, [dispatch]);

  return (
    <>
      <AdminRoute/>
      {/* <StudentRoute/> */}
    </>
  );
}

export default App;

