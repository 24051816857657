import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function SetupMenu({ anchorElSetup, handleCloseSetupMenu }) {
  const { currentUser } = useSelector((state) => state.user);

  const userRole = currentUser?.listUser?.[0]?.roleName || "";

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElSetup}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElSetup)}
      onClose={handleCloseSetupMenu}
    >
      {userRole === "UniSuperAdmin" && (
        <>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="/level-management"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left", // Align text to the left
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseSetupMenu}
            >
              Level Management
            </Typography>
          </MenuItem>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="/faculty-management"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left", // Align text to the left
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseSetupMenu}
            >
              Faculty Management
            </Typography>
          </MenuItem>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="program-management"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left", // Align text to the left
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseSetupMenu}
            >
              Program Management
            </Typography>
          </MenuItem>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="#subject-management"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left", // Align text to the left
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseSetupMenu}
            >
              Subject Management
            </Typography>
          </MenuItem>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="/arthikBarsa"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left", // Align text to the left
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseSetupMenu}
            >
              Fiscal Year Setup
            </Typography>
          </MenuItem>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="/batch-management"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left",
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseSetupMenu}
            >
              Batch Management
            </Typography>
          </MenuItem>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="/ethnic-group-management"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left", // Align text to the left
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              color="inherit"
              size="small"
              onClick={handleCloseSetupMenu}
            >
              Ethnic Group Management
            </Typography>
          </MenuItem>
        </>
      )}
      {userRole === "CollegeSuperAdmin" && (
        <MenuItem sx={{ margin: "0px", padding: "0px" }}>
          <Typography
            component={Link}
            to="/college-programs"
            variant="body2"
            sx={{
              width: "100%",
              textDecoration: "none",
              borderRadius: 0,
              border: "none",
              color: "inherit",
              fontSize: "14px",
              padding: "7px 7px",
              textAlign: "left",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#1976d2",
                color: "whitesmoke",
              },
            }}
            color="inherit"
            size="small"
            onClick={handleCloseSetupMenu}
          >
            Add Programs
          </Typography>
        </MenuItem>
      )}
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/fee-type"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          color="inherit"
          size="small"
          onClick={handleCloseSetupMenu}
        >
          Fee Type Setup
        </Typography>
      </MenuItem>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/fee-setup"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          color="inherit"
          size="small"
          onClick={handleCloseSetupMenu}
        >
          Fee Setup
        </Typography>
      </MenuItem>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/add-user"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          color="inherit"
          size="small"
          onClick={handleCloseSetupMenu}
        >
          Add User
        </Typography>
      </MenuItem>
    </Menu>
  );
}
