import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  FormControl,
  Dialog,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import axios from "axios";
import EditArthikBarsa from "./EditArthikBarsa";
import { toast } from "react-toastify";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ArthikBarsa = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [fiscalYears, setFiscalYears] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFiscalYearId, setSelectedFiscalYearId] = useState(null);

  const onSubmit = async (data) => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.post(`${backendUrl}/FiscalYear`, data, config);
      // Fetch the updated list of fiscal years after successful submission
      fetchFiscalYears();
    }catch (err) {
      console.error("Submission error:", err);
      if (err.response && err.response.status === 409) {
        toast.error("Level already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };

  const handleEditClick = (id) => {
    setSelectedFiscalYearId(id);
    setOpenEditDialog(true);
  };

  const fetchFiscalYears = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/FiscalYear`, config);
      setFiscalYears(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFiscalYears();
  }, []);

  const handleUpdate = () => {
    fetchFiscalYears();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={1} />
      <Grid item xs={12} md={10}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Fiscal Year Setup
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    {...register("yearNepali", { required: true })}
                    id="yearNepali"
                    size="small"
                    name="yearNepali"
                    label="Fiscal Year (B.S.)"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.yearNepali}
                    helperText={errors.yearNepali ? "Fiscal Year required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    {...register("yearEnglish", { required: true })}
                    id="yearEnglish"
                    size="small"
                    name="yearEnglish"
                    label="Fiscal Year (A.D.)"
                    fullWidth
                    error={!!errors.yearEnglish}
                    helperText={errors.yearEnglish ? "Fiscal Year required" : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    {...register("startDate", { required: true })}
                    id="startDate"
                    size="small"
                    type="date"
                    name="startDate"
                    label="Fiscal Year Start Date"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.startDate}
                    helperText={errors.startDate ? "Fiscal Year required" : ""}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    required
                    {...register("endDate", { required: true })}
                    id="endDate"
                    size="small"
                    type="date"
                    name="endDate"
                    label="Fiscal Year End Date"
                    fullWidth
                    autoComplete="given-name"
                    error={!!errors.endDate}
                    helperText={errors.endDate ? "Fiscal Year required" : ""}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="activeFiscalYear" required>
                      Status
                    </InputLabel>
                    <Select
                      required
                      {...register("activeFiscalYear", { required: true })}
                      id="activeFiscalYear"
                      size="small"
                      name="activeFiscalYear"
                      label="Status"
                      fullWidth
                      error={!!errors.activeFiscalYear}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid margin="10px">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            Fiscal Year List
          </Typography>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No:
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Fiscal Year (B.S.)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Fiscal Year (A.D.)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    End Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fiscalYears.map((year, index) => (
                  <TableRow key={year.id}>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.yearNepali}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.yearEnglish}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.startDate}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.endDate}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {year.activeFiscalYear ? (
                        <Typography color='green'>Active</Typography>
                      ) : (
                        <Typography color='red'>Inactive</Typography>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      <Button onClick={() => handleEditClick(year.id)}>
                        <EditNoteIcon /> Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="md">
        <EditArthikBarsa 
          id={selectedFiscalYearId} 
          onClose={() => setOpenEditDialog(false)} 
          onUpdate={handleUpdate} 
        />
      </Dialog>
    </Grid>
  );
};

export default ArthikBarsa;
