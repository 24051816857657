import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
  Button,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox, 
} from "@mui/material";
import { createContext, useContext, useState } from "react";
import useAddressData from "../address/address";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
const AddressContext = createContext();

const AddressInfoProvider = ({ children }) => {
  const methods = useForm();
  const [employeeAddress, setEmployeeAddress] = useState({
    pProvince: "",
    pDistrict: "",
    pLocalLevel: "",
    pWardNo: 0,
    isSameAsPermament: false,
    pTole: "",
    pHouseNo: "",
    tProvince: "",
    tDistrict: "",
    tLocalLevel: "",
    tWardNo: 0,
    tTole: "",
    tHouseNo: "",
  });
  const onChange = (name, value) => {
    setEmployeeAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <AddressContext.Provider value={{...methods,employeeAddress, onChange}}>
      {children}
    </AddressContext.Provider>
  )
}
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  // '& input:invalid + fieldset': {
  //   borderColor: '#3572EF',
  //   borderWidth: 1,
  // },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});

const EmployeeAddressGeneralInfo = ({ handleNext, handleBack }) => {
  const {control,
  handleSubmit,
  formState: { errors },} = useContext(
    AddressContext
  );
  const [sameAsPermanent, setSameAsPermanent] = useState(false);
  const {
    uniqueProvinces,
    uniqueDistricts,
    uniqueLocalLevels,
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
    uniqueProvinces2,
    uniqueDistricts2,
    selectedProvince2,
    setSelectedProvince2,
    selectedDistrict2,
    setSelectedDistrict2,
    uniqueLocalLevels2,
    noOfWards,
  } = useAddressData();
  const {employeeAddress, onChange } = useContext(AddressContext);
  const handleSameAsPermanent = (event) => {
    const isChecked = event.target.checked;
    setSameAsPermanent(isChecked);
    onChange("isSameAsPermament", isChecked);
  };
  const onSubmit = (data) => {
    onChange("pProvince", data.pProvince);
    onChange("pDistrict", data.pDistrict);
    onChange("pLocalLevel", data.pLocalLevel);
    onChange("pWardNo", data.pWardNo);
    onChange("pTole", data.pTole);
    onChange("pHouseNo", data.pHouseNo);
    onChange("tProvince", data.tProvince);
    onChange("tDistrict", data.tDistrict);
    onChange("tLocalLevel", data.tLocalLevel);
    onChange("tWardNo", data.tWardNo);
    onChange("tTole", data.tTole);
    onChange("tHouseNo", data.tHouseNo);   
   
    handleNext();
  };
  const onBack = (data) => {
    console.log(data);
    handleBack(); // Call handleNext only if form submission is successful
  };
  const getWardOptions = (localLevel) => {
    const wards = noOfWards[localLevel] || 0;
    return Array.from({ length: wards }, (_, i) => i + 1);
  };
 
  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onBack}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography textAlign='center' variant="subtitle1" sx={{ color: "#636363", mt: '.5rem' }}>
            Permanent Address
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="pProvince" required>
              Province
            </InputLabel>
            <Controller
              name="pProvince"
              control={control}
              rules={{ required: "Province is required" }}
              defaultValue={employeeAddress.pProvince || ""}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="pProvince"
                  label="pProvince"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedProvince(value);
                    setSelectedDistrict(null);
                    field.onChange(value);
                  }}
                  error={!!errors.pProvince}
                  helperText={errors.pProvince ? errors.pProvince.message : ""}
                >
                  <MenuItem value="" disabled>Select Province </MenuItem>
                  {uniqueProvinces.map((province) => (
                    <MenuItem key={province} value={province}>
                      {province}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="pDistrict" required>
              District
            </InputLabel>
            <Controller
              name="pDistrict"
              control={control}
              rules={{ required: "District is required" }}
              defaultValue={employeeAddress.pDistrict}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="pDistrict"
                  label="District"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedDistrict(value);
                    field.onChange(value);
                  }}
                  error={!!errors.pDistrict}
                  helperText={errors.pDistrict ? errors.pDistrict.message : ""}
                >
                <MenuItem value="" disabled>Select District </MenuItem>
                  {uniqueDistricts.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="pLocalLevel" required>
              Local Level
            </InputLabel>
            <Controller
              name="pLocalLevel"
              control={control}
              rules={{ required: "Local Level is required" }}
              defaultValue={employeeAddress.pLocalLevel}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="pLocalLevel"
                  label="Local Level"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value);
                    onChange("pLocalLevel", value); // Update local level in state
                  }}
                  error={!!errors.pLocalLevel}
                  helperText={errors.pLocalLevel ? errors.pLocalLevel.message : ""}
                >
                <MenuItem value="" disabled>Select Local levels </MenuItem>
                  {uniqueLocalLevels.map((localLevel) => (
                    <MenuItem key={localLevel} value={localLevel}>
                      {localLevel}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
        <FormControl fullWidth size='small'>
        <InputLabel required>Ward No</InputLabel>
          <Controller
            name="pWardNo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                required
                {...field}
                id="pWardNo"
                size="small"
                label="Ward No"
                fullWidth
                SelectProps={{
                  native: true,
                }}
              > 
               <MenuItem value="" disabled>Select Ward </MenuItem>
                {getWardOptions(employeeAddress.pLocalLevel).map((ward) => (
                  <MenuItem key={ward} value={ward}>
                    {ward}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="pTole"
            control={control}
            defaultValue={employeeAddress.pTole}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="pTole"
                size="small"
                label="Tole"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="pHouseNo"
            control={control}
            defaultValue={employeeAddress.pHouseNo}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="pHouseNo"
                size="small"
                label="House No"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign='center' variant="subtitle1" sx={{ color: "#636363", mt: '.8rem' }}>
            Temporary Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sameAsPermanent}
                onChange={handleSameAsPermanent}
              />
            }
            label="Same as Permanent Address"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="tProvince">
              Province
            </InputLabel>
            <Controller
              name="tProvince"
              control={control}
              defaultValue={sameAsPermanent ? employeeAddress.pProvince : employeeAddress.tProvince}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="tProvince"
                  label="tProvince"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedProvince2(value);
                    setSelectedDistrict2(null);
                    field.onChange(value);
                  }}
                  disabled={sameAsPermanent}
                >
                  <MenuItem value="" disabled>Select Province </MenuItem>
                  {uniqueProvinces2.map((province) => (
                    <MenuItem key={province} value={province}>
                      {province}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="tDistrict" >
              District
            </InputLabel>
            <Controller
              name="tDistrict"
              control={control}
              defaultValue={sameAsPermanent ? employeeAddress.pDistrict : employeeAddress.tDistrict}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="tDistrict"
                  label="District"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedDistrict2(value);
                    field.onChange(value);
                  }}
                  disabled={sameAsPermanent}
                >
                  <MenuItem value="" disabled>Select District </MenuItem>
                  {uniqueDistricts2.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="tLocalLevel" >
              Local Level
            </InputLabel>
            <Controller
              name="tLocalLevel"
              control={control}
              defaultValue={sameAsPermanent ? employeeAddress.pLocalLevel : employeeAddress.tLocalLevel}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  labelId="tLocalLevel"
                  label="Local Level"
                  size="small"
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value);
                    onChange("tLocalLevel", value); // Update local level in state
                  }}
                  disabled={sameAsPermanent}
                >
                <MenuItem value="" disabled>Select localLevel </MenuItem>
                  {uniqueLocalLevels2.map((localLevel) => (
                    <MenuItem key={localLevel} value={localLevel}>
                      {localLevel}
                    </MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
        <FormControl fullWidth size='small'>
        <InputLabel >Ward No</InputLabel>
          <Controller
            name="tWardNo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                
                {...field}
                id="tWardNo"
                size="small"
                label="Ward No"
                fullWidth
                SelectProps={{
                  native: true,
                }}
              > 
               <MenuItem value="" disabled>Select Ward </MenuItem>
                {getWardOptions(employeeAddress.pLocalLevel).map((ward) => (
                  <MenuItem key={ward} value={ward}>
                    {ward}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="tTole"
            control={control}
            defaultValue={employeeAddress.tTole}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="tTole"
                size="small"
                label="Tole"
                fullWidth
                disabled={sameAsPermanent}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="tHouseNo"
            control={control}
            defaultValue={employeeAddress.tHouseNo}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="tHouseNo"
                size="small"
                label="House No"
                fullWidth
                disabled={sameAsPermanent}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="standard"
          size="small"
          onClick={onBack}
          sx={{ color: "#2b6eb5", marginBottom: "10px", marginLeft: "10px" }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{ bgcolor: "#2b6eb5", marginBottom: "10px", marginLeft: "10px" }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};

export { AddressInfoProvider, AddressContext };
export default EmployeeAddressGeneralInfo;

