import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  FormControl,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  getProgram,
  getSubject,
  postSubject,
} from "../../../services/services";

const SubjectMgmt = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [getSubjectData, setGetSubjectData] = useState([]);
  const [programData, setProgramData] = useState([]);

  const onSubmit = async (data) => {
    try {
      const response = await postSubject(data);
      console.log(response);
      reset();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSubject();
        const programData = await getProgram();
        setGetSubjectData(data);
        setProgramData(programData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} /> 
      <Grid item xs={12} md={8}>
        {" "}
        <>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Subject Management
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="programId" required>
                        Program Name
                      </InputLabel>
                      <Select
                        required
                        {...register("programId")}
                        id="programId"
                        size="small"
                        name="programId"
                        fullWidth
                        label="Program Name"
                        error={!!errors.programId}
                        helpertext={errors.programId ? "program required" : ""}
                      >
                        {programData.map((data) => (
                          <MenuItem value={data.id}>
                            {data.programName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      {...register("subjectName", { required: true })}
                      id="subjectName"
                      size="small"
                      name="subjectName"
                      label="Subject Name"
                      fullWidth
                      error={!!errors.subjectName}
                      helpertext={
                        errors.subjectName ? "Subject name required" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="type" required>
                        Type
                      </InputLabel>
                      <Select
                        required
                        {...register("programType", { required: true })}
                        id="programType"
                        size="small"
                        name="programType"
                        label="Type"
                        fullWidth
                        error={!!errors.programType}
                        helpertext={
                          errors.programType ? "Program type required" : ""
                        }
                      >
                        <MenuItem value="regular">Regular</MenuItem>
                        <MenuItem value="optional">Optional</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      {...register("shortName", { required: true })}
                      id="shortName"
                      size="small"
                      name="shortName"
                      label="Short Name"
                      fullWidth
                      autoComplete="short-name"
                      error={!!errors.shortName}
                      helpertext={errors.shortName ? "short name required" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      {...register("code", { required: true })}
                      id="code"
                      size="small"
                      name="code"
                      label="Code"
                      fullWidth
                      error={!!errors.code}
                      helpertext={errors.code ? "code is required" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="alias"
                      {...register("alias")}
                      name="alias"
                      size="small"
                      label="Alias"
                      fullWidth
                      error={!!errors.alias}
                      helpertext={errors.alias}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...register("detail")}
                      id="detail"
                      name="detail"
                      size="small"
                      label="Details"
                      fullWidth
                      error={!!errors.detail}
                      helpertext={errors.detail}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Paper>
        </>
        <Grid margin="10px">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            List of Subjects
          </Typography>
          <TableContainer>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No:
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Program Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Subject Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    subject Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Short Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Alias
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    detail
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getSubjectData.map((data, index) => (
                  <TableRow>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.program.programName}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.subjectName}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.isRegular ? "Regular" : "optional"}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.shortName}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.code}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.alias}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      {data.remarks}
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      <Button>
                        <EditNoteIcon />
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubjectMgmt;
