import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import EmployeeMenu from "./EmployeeMenu";
import SetupMenu from "./SetupMenu";
import CampusUniMenu from "./CampusUniMenu";
import ReportsMenu from "./ReportsMenu";
import SettingsMenu from "./SettingsMenu";
import StudentMenu from "./StudentMenu";
import InfrastructureMenu from "./InfrastructureMenu";
import defaultTuLogo from "../../assets/defaultLogo.jpeg";
import profile from "../../assets/profile.png";

const baseUrl = process.env.REACT_APP_BASE_URL;
function stringAvatar(name) {
  let initials;
  switch (name) {
    case "SuperAdmin":
      initials = "SA";
      break;
    case "CollegeAdmin":
      initials = "CA";
      break;
    case "UniSuperAdmin":
      initials = "UA";
      break;
    case "Admin":
      initials = "AD";
      break;
    default:
      initials = "PP";
      break;
  }

  return {
    sx: {},
    children: initials,
  };
}

function ResponsiveAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElCampusInfo, setAnchorElCampusInfo] = React.useState(null);
  const [anchorElSetup, setAnchorElSetup] = React.useState(null);
  const [anchorElList, setAnchorElList] = React.useState(null);
  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const [anchorElStudent, setAnchorElStudent] = React.useState(null);

  const [anchorElInfrastructure, setAnchorElInfrastructure] =
    React.useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;

  let campusAdmin = false;
  let universityAdmin = false;
  let logos;
  let instituteNames;
  let localLevels;
  let districts;

  if (currentUser && currentUser.listUser && currentUser.listUser.length > 0) {
    const roleName = currentUser.listUser[0].roleName;
    logos = currentUser?.institution?.logo || defaultTuLogo;
    instituteNames =
      currentUser.institution?.campusName ||
      currentUser.institution?.name ||
      "विश्वविद्यालय अनुदान आयोग";
    localLevels =
      currentUser.institution?.localLevel ||
      currentUser.institution?.palika ||
      "";
    districts = currentUser.institution?.district || "";

    if (roleName === "CollegeSuperAdmin") {
      campusAdmin = true;
    } else if (roleName === "UniSuperAdmin") {
      universityAdmin = true;
    }
  }

  let user;
  if (currentUser && currentUser.listUser && currentUser.listUser.length > 0) {
    user = currentUser.listUser[0].roleName;
  }
  const handleOpenSubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenStudentMenu = (event) => {
    setAnchorElStudent(event.currentTarget);
  };
  const handleOpenInfrastructureMenu = (event) => {
    setAnchorElInfrastructure(event.currentTarget);
  };

  const handleCloseInfrastructureMenu = () => {
    setAnchorElInfrastructure(null);
  };
  const handleCloseSubMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseStudentMenu = () => {
    setAnchorElStudent(null);
  };

  const handleOpenCampusInfoMenu = (event) => {
    setAnchorElCampusInfo(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenSetupMenu = (event) => {
    setAnchorElSetup(event.currentTarget);
  };

  const handleOpenListMenu = (event) => {
    setAnchorElList(event.currentTarget);
  };
  const handleOpenReportMenu = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseReportMenu = () => {
    setAnchorElReport(null);
  };

  const handleCloseSetupMenu = () => {
    setAnchorElSetup(null);
  };
  const handleCloseListMenu = () => {
    setAnchorElList(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseCampusInfoMenu = () => {
    setAnchorElCampusInfo(null);
  };

  return (
    <Box bgcolor="white">
      <Box
        position="fixed"
        sx={{
          width: "100%",
          top: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#01204E",
          height: "77px",
          bgcolor: "white",
        }}
      >
        <Container maxWidth="xl" sx={{ paddingBottom: "5px" }}>
          {currentUser && (
            <Toolbar disableGutters>
              <Link to="/" variant="body1" nowrap="true" component="a" href="/">
                <img
                  src={
                    currentUser?.institution?.logo
                      ? `${baseUrl}${logos}`
                      : logos
                  }
                  alt="Logo"
                  style={{ marginRight: "8px", height: "75px" }}
                />
              </Link>

              <Box>
                <Typography color="#2b6eb5" variant="body1" fontWeight="bold">
                  {instituteNames}
                </Typography>
                {localLevels && districts ? (
                  <Typography variant="body2">
                    {localLevels}, {districts}
                  </Typography>
                ) : (
                  <Typography variant="body2" fontWeight="bold" color="#2b6eb5">
                    University Grants Commission
                  </Typography>
                )}
              </Box>
            </Toolbar>
          )}
          {!currentUser && (
            <Toolbar disableGutters>
              <IconButton sx={{ display: { xs: "none", md: "flex" }, mr: 5 }} />
              <Link
                to="/"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: "0 rem",
                  color: "#01204E",
                  textDecoration: "none",
                  marginLeft: "4rem",
                }}
              >
                <img
                  src={defaultTuLogo}
                  alt="Logo"
                  style={{ marginRight: "8px", height: "75px", padding: "5px" }}
                />
              </Link>

              <Box>
                <Typography color="#2b6eb5" variant="body1">
                  {instituteNames}
                </Typography>

                <Typography variant="body1">
                  University Grants Commission
                </Typography>
                <Typography variant="body2">Sanothimi, Bhaktapur</Typography>
              </Box>
            </Toolbar>
          )}
        </Container>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          top: "76px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#FFF",
          bgcolor: "#2B6EB5",
          height: "41px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                color="inherit"
                size="small"
                sx={{
                  "&:hover": {
                    bgcolor: "#1976d2", // 80% dim color
                  },
                }}
                component={Link}
                to="/"
              >
                <HomeIcon fontSize="smhttp://localhost:3001/loginall" />
              </IconButton>
              {currentUser && (
                <>
                  <Button
                    component={Link}
                    to="/"
                    variant="body2"
                    size="small"
                    sx={{
                      mx: 0,
                      textDecoration: "none",
                      color: "inherit",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#1976d2", // 80% dim color
                      },
                    }}
                  >
                    Home
                  </Button>
                  {roleName === "Student" && (
                    <Button
                      variant="body2"
                      sx={{
                        mx: 0,
                        textDecoration: "none",
                        color: "inherit",
                        textTransform: "capitalize",
                        "&:hover": {
                          bgcolor: "#1976d2", // 80% dim color
                        },
                      }}
                      size="small"
                    >
                      Payment
                    </Button>
                  )}

                  {roleName !== "Student" && (
                    <>
                      <Button
                        onClick={handleOpenSetupMenu}
                        variant="body2"
                        sx={{
                          mx: 0,
                          textDecoration: "none",
                          color: "inherit",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "#1976d2", // 80% dim color
                          },
                        }}
                        size="small"
                        endIcon={<ArrowDropDownIcon />}
                      >
                        setup
                      </Button>
                      <Button
                        onClick={handleOpenInfrastructureMenu}
                        variant="body2"
                        sx={{
                          mx: 0,
                          textDecoration: "none",
                          color: "inherit",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "#1976d2", // 80% dim color
                          },
                        }}
                        size="small"
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Infrastructure
                      </Button>

                      <Button
                        onClick={handleOpenStudentMenu}
                        variant="body2"
                        sx={{
                          mx: 0,
                          textDecoration: "none",
                          color: "inherit",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "#1976d2", // 80% dim color
                          },
                        }}
                        size="small"
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Students
                      </Button>

                      <Button
                        onClick={handleOpenCampusInfoMenu}
                        variant="body2"
                        sx={{
                          mx: 0,
                          textDecoration: "none",
                          color: "inherit",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "#1976d2", // 80% dim color
                          },
                        }}
                        size="small"
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Employee
                      </Button>

                      <EmployeeMenu
                        anchorElCampusInfo={anchorElCampusInfo}
                        handleCloseCampusInfoMenu={handleCloseCampusInfoMenu}
                      />
                      <InfrastructureMenu
                        anchorElInfrastructure={anchorElInfrastructure}
                        handleCloseInfrastructureMenu={
                          handleCloseInfrastructureMenu
                        }
                      />
                      <StudentMenu
                        anchorElStudent={anchorElStudent}
                        handleCloseStudentMenu={handleCloseStudentMenu}
                      />
                      <CampusUniMenu
                        anchorElList={anchorElList}
                        handleCloseListMenu={handleCloseListMenu}
                        handleOpenSubMenu={handleOpenSubMenu}
                        anchorEl={anchorEl}
                        handleCloseSubMenu={handleCloseSubMenu}
                        handleCloseCampusInfoMenu={handleCloseCampusInfoMenu}
                        universityAdmin={universityAdmin}
                      />

                      {!campusAdmin && (
                        <Button
                          onClick={handleOpenListMenu}
                          size="small"
                          variant="body2"
                          sx={{
                            mx: 0,
                            textDecoration: "none",
                            color: "inherit",
                            textTransform: "capitalize",
                            "&:hover": {
                              bgcolor: "#1976d2", // 80% dim color
                            },
                          }}
                          endIcon={<ArrowDropDownIcon />}
                        >
                          {universityAdmin
                            ? "Campuses/Departments"
                            : "Campuses/Universities"}
                        </Button>
                      )}
                      <SetupMenu
                        handleCloseSetupMenu={handleCloseSetupMenu}
                        anchorElSetup={anchorElSetup}
                      />

                      <Button
                        onClick={handleOpenReportMenu}
                        size="small"
                        variant="body2"
                        sx={{
                          mx: 0,
                          textDecoration: "none",
                          color: "inherit",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "#1976d2", // 80% dim color
                          },
                        }}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Reports
                      </Button>
                    </>
                  )}
                </>
              )}
            </Box>

            <ReportsMenu
              anchorElReport={anchorElReport}
              handleCloseReportMenu={handleCloseReportMenu}
            />
            <Box sx={{ flexGrow: 0 }} justifyContent="right" alignItems="right">
              <Button
                href="https://support.google.com/"
                size="small"
                variant="body2"
                sx={{
                  mx: 0,
                  textDecoration: "none",
                  color: "inherit",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: "#1976d2", // 80% dim color
                  },
                }}
                target="blank"
              >
                Help
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }} justifyContent="right" alignItems="right">
              {!currentUser && (
                <Button
                  component={Link}
                  to="/login"
                  variant="standard"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: "#1976d2", // 80% dim color
                    },
                  }}
                >
                  Login
                </Button>
              )}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {currentUser && (
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0 }}
                    size="small"
                  >
                    <Avatar src={profile} alt="Profile" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />{" "}
      <SettingsMenu
        anchorElUser={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
      />
    </Box>
  );
}

export default ResponsiveAppBar;
