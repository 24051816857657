import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getFaculty, getLevel } from "../../services/services";
import { data } from "autoprefixer";

const AcademyContext = createContext();

const AcademyInfoProvider = ({ children }) => {
  const methods = useForm();
  const [academyInfo, setAcademyInfo] = useState({
    employeeid: "",
    graduatedfrom: "",
    faculty: "",
    level: "",
    program:'',
    enrolledYear: "",
    passedYear: "",
    certificateCopy: null,
    transcriptCopy: null,
    marksheetCopy: null,
    otherDoc: null,
  });
  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setAcademyInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setAcademyInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <AcademyContext.Provider value={{ ...methods, academyInfo, onChange }}>
      {children}
    </AcademyContext.Provider>
  );
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },

  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});

const AcademyGeneralInfo = ({ handleNext, handleBack }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useContext(AcademyContext);
  const [level, setLevel] = useState([])
  const [faculty, setFaculty] = useState([])

  const { onChange } = useContext(AcademyContext);
  useEffect (() => {
    const fetchData = async () =>  {
      try {
        const levelData = await getLevel()
        const facultyData = await getFaculty()
        setLevel(levelData)
        setFaculty(facultyData)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  },[])

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  const onBack = (data) => {
    handleBack(); // Call handleNext only if form submission is successful
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid container>
        <Grid item xs="12">
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", color: "#636363", mb: ".6rem" }}
          >
            Academy Information
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Controller
              name="graduatedfrom"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="graduatedfrom"
                  size="small"
                  name="graduatedfrom"
                  label="Graduated From"
                  fullWidth
                  error={!!errors.graduatedfrom}
                  helperText={errors.graduatedfrom ? "Required" : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small">
          <InputLabel>Faculty</InputLabel>
            <Controller
              name="faculty"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  required
                  id="faculty"
                  size="small"
                  name="faculty"
                  label="Faculty"
                  fullWidth
                  error={!!errors.faculty}
                  helperText={errors.faculty ? "Required" : ""}
                >
                {faculty && faculty.map((data) => (
                  <MenuItem key={data.id} value={data.facultyName}>{data.facultyName}</MenuItem>
                ))}
                </Select>
              )}
            />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2.5}>
          <FormControl fullWidth size="small">
          <InputLabel>Level</InputLabel>
            <Controller
              name="level"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  required
                  id="level"
                  size="small"
                  name="level"
                  label="Level"
                  fullWidth
                  error={!!errors.level}
                  helperText={errors.level ? "Required" : ""}
                >
                {level && level.map((data) => (
                  <MenuItem key={data.id} value={data.levelName}>{data.levelName}</MenuItem>
                ))}
                </Select>
              )}
            />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <Controller
              name="program"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="program"
                  size="small"
                  name="program"
                  label="Program"
                  fullWidth
                  error={!!errors.program}
                  helperText={errors.program ? "Required" : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="enrolledYear"
              control={control}
              defaultValue=""
              rules={{
              pattern: {
                value: /^\d{0,4}$/,
                message:
                  "Please enter only numeric values with maximum length of four digits",
              },
            }}
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="enrolledYear"
                  size="small"
                  name="enrolledYear"
                  label="Enrolled Year"
                  fullWidth
                  InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Allow only numeric characters
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4); // Limit input to maximum of four characters
                    }
                  },
                }}
                  
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="passedYear"
              control={control}
              defaultValue=""
              rules={{
              pattern: {
                value: /^\d{0,4}$/,
                message:
                  "Please enter only numeric values with maximum length of four digits",
              },
            }}
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="passedYear"
                  size="small"
                  name="passedYear"
                  label="Passed Year"
                  fullWidth
                  InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, ""); // Allow only numeric characters
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4); // Limit input to maximum of four characters
                    }
                  },
                }}
                 
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                Certificate Copy
              </InputLabel>
              <TextField
                id="certificateCopy"
                type="file"
                size="small"
                variant="standard"
                name="certificateCopy"
                accept="image/*"
                onChange={(e) =>
                  onChange(e, "certificateCopy", e.target.files[0])
                }
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                Transcript Copy
              </InputLabel>
              <TextField
                id="transcriptCopy"
                type="file"
                size="small"
                variant="standard"
                name="transcriptCopy"
                accept="image/*"
                onChange={(e) =>
                  onChange(e, "transcriptCopy", e.target.files[0])
                }
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                Marksheet Copy
              </InputLabel>
              <TextField
                id="marksheetCopy"
                type="file"
                size="small"
                variant="standard"
                name="marksheetCopy"
                accept="image/*"
                onChange={(e) =>
                  onChange(e, "marksheetCopy", e.target.files[0])
                }
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                Other Doc
              </InputLabel>
              <TextField
                id="otherDoc"
                type="file"
                size="small"
                variant="standard"
                name="otherDoc"
                accept="image/*"
                onChange={(e) => onChange(e, "otherDoc", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="flex-start">
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="standard"
              size="small"
              onClick={onBack}
              sx={{
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              startIcon={<ChevronLeftRoundedIcon />}
            >
              Back
            </Button>

            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                bgcolor: "#003285",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              endIcon={<ChevronRightRoundedIcon />}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { AcademyInfoProvider, AcademyContext };
export default AcademyGeneralInfo;
