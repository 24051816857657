import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import React, { createContext, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { getEmployeeById } from "../../../services/employeeService";

const EditAcademyContext = createContext();

const EditAcademyInfoProvider = ({ children }) => {
  const methods = useForm();
  const [academyInfo, setAcademyInfo] = React.useState({
    graduatedfrom: "",
    faculty: "",
    level: "",
    enrolledYear: "",
    PassedYear: "",
    certificateCopy: "",
    transcriptCopy: "",
    marksheetCopy: "",
    otherDoc: "",
  });

  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setAcademyInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setAcademyInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <EditAcademyContext.Provider value={{ ...methods, academyInfo, onChange }}>
      {children}
    </EditAcademyContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});

const EditAcademyGeneralInfo = ({ handleNext, handleBack, id }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    onChange
  } = useContext(EditAcademyContext);

  const fetchData = async () => {
    try {
      const employeeData = await getEmployeeById(id);
      console.log('Fetched data:', employeeData); // Debugging: log fetched data
  
      // Use `setValue` to set form fields
      setValue('graduatedfrom', employeeData.graduatedFrom || "");
      setValue('faculty', employeeData.faculty || "");
      setValue('level', employeeData.level || "");
      setValue('enrolledYear', employeeData.enrolledYear || "");
      setValue('PassedYear', employeeData.passedYear || "");
      setValue('certificateCopy', employeeData.certificateCopy || "");
      setValue('transcriptCopy', employeeData.transcriptCopy || "");
      setValue('marksheetCopy', employeeData.marksheetCopy || "");
      setValue('otherDoc', employeeData.otherDoc || "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [id]);

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  const onBack = () => {
    handleBack(); // Call handleBack only if form submission is successful
  };

  return (
    <Grid container component="form" onSubmit={handleSubmit(onSubmit)} onReset={onBack}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="#636363" textAlign="center" mb=".8rem">
          Academic Information
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="graduatedfrom"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                size="small"
                label="Graduated From"
                fullWidth
                error={!!errors.graduatedfrom}
                helperText={errors.graduatedfrom ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="faculty"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                size="small"
                label="Faculty"
                fullWidth
                error={!!errors.faculty}
                helperText={errors.faculty ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="level"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                size="small"
                label="Level"
                fullWidth
                error={!!errors.level}
                helperText={errors.level ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="enrolledYear"
            control={control}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                size="small"
                label="Enrolled Year"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="PassedYear"
            control={control}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                size="small"
                label="Passed Year"
                fullWidth
              />
            )}
          />
        </Grid>
        {/* Include other fields as necessary */}
      </Grid>
      <Grid container direction="column" alignItems="flex-start">
        <Box mt={4} display="flex" justifyContent="space-between">
          <Button
            variant="standard"
            size="small"
            onClick={onBack}
            sx={{ marginBottom: "10px", marginLeft: "10px" }}
            startIcon={<ChevronLeftRoundedIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            size="small"
            type="submit"
            sx={{ bgcolor: "#003285", marginBottom: "10px", marginLeft: "10px" }}
            endIcon={<ChevronRightRoundedIcon />}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export { EditAcademyInfoProvider, EditAcademyContext };
export default EditAcademyGeneralInfo;
