
import React, { useContext, useState } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { EditEmployeeInfoContext } from './EditEmployeeGeneralInfo';
import { EditAddressContext } from './EditEmployeeAddressGeneralInfo';
import { EditWorkInfoContext } from './EditWorkInfo';
import { EditAcademyContext } from './EditAcademyGeneralInfo';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure you set REACT_APP_BACKEND_URL in your .env file


const EditDetailsReview = ({ handleBack, id }) => {
    const { employeeInfo } = useContext(EditEmployeeInfoContext);
    const { employeeAddress } = useContext(EditAddressContext);
    const { workInfo } = useContext(EditWorkInfoContext);
    const { academyInfo } = useContext(EditAcademyContext);
    const [loading, setLoading] = useState(false);

   
    const { currentUser } = useSelector((state) => state.user);
  let universityId;
  let campusId;

  if (currentUser.type === 'Uni') {
    universityId = currentUser.institution.id;
  } else if (currentUser.type === 'college') {
    campusId = currentUser.institution.id;
  }
    // Function to handle registration
    const handleRegister = async () => {
        setLoading(true); // Start loading

        const formData = new FormData();
        formData.append('id', id);
        formData.append('campusId', campusId || 0 );
        formData.append('employeeType', employeeInfo.employeeType); // Assuming campusId is always 0 or a default value
        formData.append('citizenIssueDist', employeeInfo.citizenIssueDist);
        formData.append('firstName', employeeInfo.firstName);
        formData.append('middleName', employeeInfo.middleName);
        formData.append('lastName', employeeInfo.lastName);
        formData.append('phoneNumber', employeeInfo.phone); // Corrected key name
        formData.append('email', employeeInfo.email);
        formData.append('gender', employeeInfo.gender);
        formData.append('citizenshipNo', employeeInfo.citizenshipNo);
        formData.append('doBBS', employeeInfo.dob);
        formData.append('nidNo', employeeInfo.nidNo); // Append logo as a file
        formData.append('nidPic', 'nidPicValue'); // Add appropriate value for nidPic
        formData.append('citizenshipFront', 'citizenshipFrontValue'); // Add appropriate value for citizenshipFront
        formData.append('citizenshipBack', 'citizenshipBackValue'); // Add appropriate value for citizenshipBack
        formData.append('ppSizePhoto', employeeInfo.ppSizePhoto); // Corrected key name
        formData.append('admissionYear', 'string'); // Add appropriate value for admissionYear
        formData.append("graduatedfrom", academyInfo.graduatedfrom);
        formData.append("faculty", academyInfo.faculty);
        formData.append("level", academyInfo.level);
        formData.append("institutionName", academyInfo.institutionName);
        formData.append("enrolledYear", academyInfo.enrolledYear);
        formData.append("PassedYear", academyInfo.PassedYear);
        formData.append("certificateCopy", academyInfo.certificateCopy);
        formData.append("transcriptCopy", academyInfo.transcriptCopy);
        formData.append("marksheetCopy", academyInfo.marksheetCopy);
        formData.append("otherDoc", academyInfo.otherDoc);
        formData.append('pProvince', employeeAddress.pProvince);
        formData.append('pDistrict', employeeAddress.pDistrict);
        formData.append('pLocalLevel', employeeAddress.pLocalLevel);
        formData.append('pWardNo', employeeAddress.pWardNo);
        formData.append('pBlockNo', 0);
        formData.append('pHouseNo', 0);
        formData.append('pLocality', employeeAddress.pTole);
        formData.append('pProvince', employeeAddress.tProvince);
        formData.append('pDistrict', employeeAddress.tDistrict);
        formData.append('tLocalLevel', employeeAddress.tLocalLevel);
        formData.append('tWardNo', employeeAddress.tWardNo);
        formData.append('tBlockNo', 0);
        formData.append('tHouseNo', 0);
        formData.append('tLocality', employeeAddress.tTole);
        formData.append('group', workInfo.group);
        formData.append('position', workInfo.position);
        formData.append('joiningType', workInfo.joiningType);
        formData.append('joiningDate', workInfo.joiningdate);
        formData.append('workPhone', workInfo.workphone);
        formData.append('workEmail', workInfo.workemail);
        formData.append('joiningLetter', workInfo.joiningletter);
        formData.append('otherLetter', workInfo.otherletter);
        formData.append('referenceletter', workInfo.reference);
        try {

        const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
    
        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data'
          }
        };
            const response = await axios.patch(`${backendUrl}/Employee/${id}`, formData, config);
    
        if (response.status === 200) {
          toast.success("University Successfully Updated", {
            autoClose: 2000
          });
          onclose();
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        toast.error("Failed to Register", {
          autoClose: 2000
        });
    } finally {
        setLoading(false); // Stop loading
      }
    };

    const formatDetail = (detail) => {
        if (detail === null || detail === undefined) return "N/A";
        if (typeof detail === 'object') return JSON.stringify(detail); // For complex objects
        return detail.toString(); // For primitive values
      };
    
      const employeeDetails = [
        { name: "Employee Type:", detail: formatDetail(employeeInfo.employeeType || 'teaching') },
        { name: "First Name:", detail: formatDetail(employeeInfo.firstName) },
        { name: "Middle Name:", detail: formatDetail(employeeInfo.middleName || '-') },
        { name: "Last Name:", detail: formatDetail(employeeInfo.lastName) },
        { name: "Phone Number:", detail: formatDetail(employeeInfo.phone) },
        { name: "Email:", detail: formatDetail(employeeInfo.email) },
        { name: "Date Of Birth(B.S.):", detail: formatDetail(new Date(employeeInfo.dobBS).toLocaleDateString()) },
        { name: "Date Of Birth(B.S.):", detail: formatDetail(new Date(employeeInfo.dobAD).toLocaleDateString()) },
        { name: "Citizenship No:", detail: formatDetail(employeeInfo.citizenshipNo) },
       
      ];
      
      const workDetails = [
        { name: "Profession:", detail: formatDetail(workInfo.group) },
        // { name: "Sub Group:", detail: formatDetail(workInfo.subgroup) },
        { name: "Position:", detail: formatDetail(workInfo.position) },
        { name: "Joining Type:", detail: formatDetail(workInfo.joiningType) },
        { name: "Joining Date:", detail: formatDetail(new Date(workInfo.joiningdate).toLocaleDateString()) },
        
      ];
      
      const academicDetails = [
        { name: "Faculty:", detail: formatDetail(academyInfo.faculty) },
        { name: "Level:", detail: formatDetail(academyInfo.level) },
        { name: "Institution Name:", detail: formatDetail(academyInfo.graduatedfrom) },
        { name: "Graduated From:", detail: formatDetail(academyInfo.graduatedfrom) },
        { name: "Enrolled Year:", detail: formatDetail(academyInfo.enrolledYear) },
        { name: "Passed Year:", detail: formatDetail(academyInfo.passedYear) },
      ];
    
      return (
        <>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            Review Details
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Box
                border={`1px solid #2B6EB5`}
                borderRadius="10px"
                position="relative"
                paddingBottom="15px"
                bgcolor="#F7F9FC"
    
              >
                <Typography
                  variant="subtitle1"
                  display="inline-block"
                  bgcolor="white"
                  padding="0 5px"
                  position="relative"
                  left="20px"
                  bottom="14px"
                  color="#2B6EB5"
    
                >
                  Employee Details
                </Typography>
                <Grid container paddingLeft="2rem" justifyContent="space-between">
                  {employeeDetails.map((details, index) => (
                    <Grid item xs={5} key={index}>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ width: "100%", mb: 1 }}
                      >
                        <Typography variant="body1" color="text.secondary">
                          {details.name}
                        </Typography>
                        <Typography variant="body2" color="#2B6EB5">{details.detail}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid mt="0.8rem" item xs={12} md={12}>
              <Box
                border={`1px solid #2B6EB5`}
                borderRadius="10px"
                position="relative"
                paddingBottom="15px"
                bgcolor="#F7F9FC"
    
    
              >
                <Typography
                  variant="subtitle1"
                  display="inline-block"
                  bgcolor="white"
                  padding="0 5px"
                  position="relative"
                  left="20px"
                  bottom="14px"
                  color="#2B6EB5"
                >
                  Work Info
                </Typography>
                <Grid container justifyContent="space-between">
                  {workDetails.map((details, index) => (
                    <Grid paddingLeft="2rem" item xs={5} key={index}>
                      <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                        <Typography variant="body1" color="text.secondary">
                          {details.name}
                        </Typography>
                        <Typography variant="body2" color="#2B6EB5">{details.detail}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid mt=".8rem" item xs={12} md={12}>
              <Box
                border={`1px solid #2B6EB5`}
                borderRadius="10px"
                position="relative"
                paddingBottom="15px"
                bgcolor="#F7F9FC"
    
              >
                <Typography
                  variant="subtitle1"
                  display="inline-block"
                  bgcolor="white"
                  padding="0 5px"
                  position="relative"
                  left="20px"
                  bottom="14px"
                  color="#2B6EB5"
                >
                  Academic Info
                </Typography>
                <Grid container flexWrap="wrap" justifyContent="space-between">
                  {academicDetails.map((details, index) => (
                    <Grid paddingLeft="2rem" item xs={5} key={index}>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body1" color="text.secondary">
                          {details.name}
                        </Typography>
                        <Typography variant="body2" color="#2B6EB5">{details.detail}</Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} mt={2} >
      <Box
                border={`1px solid #2B6EB5`}
                borderRadius="10px"
        position="relative"
        paddingBottom="15px"
      >
        <Typography
          variant="subtitle1"
          display="inline-block"
          bgcolor="white"
          padding="0 5px"
          position="relative"
          left="20px"
          bottom="14px"
          color="#2B6EB5"
    
        >
          Address Info
        </Typography>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} md={12} sx={{ mb: 1 }} mx='2rem'>
            <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>Permanent Address:</Typography>
            <Typography variant="body2" sx={{ textAlign: 'left' }} color="#2B6EB5">
              {employeeAddress.pProvince}, {employeeAddress.pDistrict}, {employeeAddress.pLocalLevel} - {employeeAddress.pWardNo || '-'} , {employeeAddress.pTole || '-'},  {employeeAddress.pHouseNo || '-'}
            </Typography>
          </Grid>
          {!employeeAddress.isSameAsPermament && (
            <Grid item xs={12} md={6} sx={{ mb: 1, ml:'2rem' }}>
              <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>Temporary Address:</Typography>
              <Typography variant="body2" sx={{ textAlign: 'left' }} color="#2B6EB5">
                {employeeAddress.tProvince}, {employeeAddress.tDistrict}, {employeeAddress.tLocalLevel} - {employeeAddress.tWardNo}, {employeeAddress.tTole || '-'}, {employeeAddress.tHouseNo || '-'}, 
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
          </Grid>
            <Box mt={4} display="flex" justifyContent="space-between">
                <Button onClick={handleBack} sx={{ color: '#2b6eb5' }} type="reset" size="small"
                    startIcon={<ChevronLeftRoundedIcon />}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                        bgcolor: "#2b6eb5",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        position: 'relative', // To position the loader correctly
          // Disable the button while loading
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.6 : 1,
                    }}
                    onClick={handleRegister}
                    endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}

                >
                            {loading ? 'Submitting...' : 'Update'}

                </Button>
            </Box>

        </>
    );
};

export default EditDetailsReview;
