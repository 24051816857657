import { DialogContent, MenuItem, Select } from "@mui/material";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const AddingUniversityRoles = () => {
  return (
    <DialogContent>
      <div>
        {" "}
        <TableContainer>
          <Table
            style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
          >
            <TableHead>
              <TableRow
                style={{ backgroundColor: "#2A629A", color: "#FFFFFF" }}
              >
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  User Type
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  Email
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  Phone
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  Student Management
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  User Management
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  Employee Management
                </TableCell>
              </TableRow>
              <TableRow
                style={{ backgroundColor: "#2A629A", color: "#FFFFFF" }}
              >
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Privilege
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Permissions
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Privilege
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Permissions
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Privilege
                </TableCell>
                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Permissions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Admin
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Ram Bahadur @gmail.com
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  987654321
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  No
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  <Select size="small" fullWidth variant='standard'>
                    <MenuItem value="read">Read</MenuItem>
                    <MenuItem value="write">Write</MenuItem>
                  </Select>
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  yes
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  <Select size="small" fullWidth variant='standard'>
                    <MenuItem value="read">Read</MenuItem>
                    <MenuItem value="write">Write</MenuItem>
                  </Select>
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  No
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd", padding: "4px" }}>
                  <Select size="small" fullWidth variant="standard" >
                    <MenuItem value="read">Read</MenuItem>
                    <MenuItem value="write">Write</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
              {/* Add more rows as needed */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Grid margin={1} container justifyContent="center">
        <Button variant="contained" size="small">
          submit
        </Button>
      </Grid>
    </DialogContent>
  );
};
export default AddingUniversityRoles;
