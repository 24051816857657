import { Card, CardContent, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import {
  Male,
  Female,
  Face,
  Group,
  Home,
  LocalHospital,
} from "@mui/icons-material";
import {
  getEmployeeByGender,
  getStudentByGender,
} from "../dashboard/services/service";

const IconBox = styled(Box)(({ color }) => ({
  display: "flex",
  alignItems: "center",
  "& svg": {
    marginRight: 4,
    color: color || "#FFF",
    fontSize: "1rem",
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(0.5),
}));

const DashboardCard = ({
  title,
  male,
  female,
  others,
  total,
  color,
  community,
  constituent,
  privateCampus,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(10px)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
      }}
    >
      <CardContent sx={{ padding: "16px", flexGrow: 1 }}>
        {title === "No of Campus" ? (
          <StatBox>
            <IconBox color={color}>
              <Home />
              <Typography variant="body2" fontSize="0.8rem">
                Community: {community}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <Group />
              <Typography variant="body2" fontSize="0.8rem">
                Constituent: {constituent}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <LocalHospital />
              <Typography variant="body2" fontSize="0.8rem">
                Private: {privateCampus}
              </Typography>
            </IconBox>
          </StatBox>
        ) : (
          <StatBox>
            <IconBox color={color}>
              <Male />
              <Typography variant="body2" fontSize="0.8rem">
                Male: {male}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <Female />
              <Typography variant="body2" fontSize="0.8rem">
                Female: {female}
              </Typography>
            </IconBox>
            <IconBox color={color}>
              <Face />
              <Typography variant="body2" fontSize="0.8rem">
                Others: {others}
              </Typography>
            </IconBox>
          </StatBox>
        )}
        <Typography
          textAlign="center"
          variant="body1"
          color={color}
          style={{
            marginTop: "5px",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Total: {total}
        </Typography>
      </CardContent>
      <CardContent
        sx={{
          backgroundColor: color,
          color: "#fff",
          padding: "16px",
          borderRadius: "0 0 8px 8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "30px",
        }}
      >
        <Typography
          variant="body2"
          component="div"
          sx={{ width: "100%", textAlign: "center", fontSize: "0.875rem" }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Dashboard = ({ authToken }) => {
  const [students, setStudents] = useState({});
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) return;
      try {
        const studentData = await getStudentByGender(authToken);
        console.log(studentData);
        const employeeData = await getEmployeeByGender(authToken);
        const teachingStaff = employeeData.filter(
          (data) => data && data.type === "teaching"
        );
        console.log(employeeData);
        setStudents(studentData || {});
        setEmployeeData(employeeData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authToken]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          title="Students"
          male={students.male || 0}
          female={students.female || 0}
          others={students.other || 0}
          total={students.total || 0}
          color="#005884"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          title="Teaching Staff"
          male={employeeData[1]?.male || 0}
          female={employeeData[1]?.female || 0}
          others={employeeData[1]?.other || 0}
          total={employeeData[1]?.totalEmployees || 0}
          color="#FABC3F"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          title="Non-Teaching Staff"
          male={employeeData[0]?.male + employeeData[2]?.male || 0}
          female={employeeData[0]?.female + employeeData[2]?.female || 0}
          others={employeeData[0]?.other + employeeData[2]?.other || 0}
          total={
            employeeData[0]?.totalEmployees + employeeData[2]?.totalEmployees || 0
          }
          color="#1976d2"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          title="No of Campus"
          total={6}
          color="#5e5e5e"
          community={3}
          constituent={2}
          privateCampus={1}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
