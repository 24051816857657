import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import DashboardCard from "../../components/newDashboard/DashboardCard.jsx";
import FacultyLevelChart from "../../components/newDashboard/FacultyLevelChart.jsx";
import StudentByEthnicity from "../../components/newDashboard/StudentByEthnicity.jsx";
import StudentsByCampus from "../../components/newDashboard/StudentsByCampus.jsx";
import UgcTable from "../../components/dashboard/Tables/UgcTable.jsx";
import UnivesityTable from "../../components/dashboard/Tables/UnivesityTable.jsx";
import TeachingStaffPieChart from "../../components/newDashboard/TeachingStaffPieChart.jsx";
import NonTeachingStaffPieChart from "../../components/newDashboard/NonTeachingStaffPieChart.jsx";
import ProgramWiseStudents from "../../components/report/ProgramWiseStudents.jsx";
import StudentByCampusType from "../../components/report/StudentByCampusType.jsx";
import StudentEmployeeByCampus from "../../components/report/StudentEmployeeByCampus.jsx";
import InstitutionsStatus from "../../components/report/InstitutionsStatus.jsx";
import { Home } from "@mui/icons-material";
import HomePage from "../home/HomePage.jsx";

const NewDashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;

  if (!currentUser) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardCard authToken={currentUser?.tokenString} />
        </Grid>

        <Grid item xs={12} md={12}>
          <FacultyLevelChart authToken={currentUser?.tokenString} />
        </Grid>

        <Grid item xs={12} md={6}>
          <StudentByEthnicity authToken={currentUser?.tokenString} />
        </Grid>

        <Grid item xs={6}>
          <StudentsByCampus authToken={currentUser?.tokenString} />
        </Grid>
        <Grid item xs={12}>
          {roleName === "SuperAdmin" ? (
            <UgcTable authToken={currentUser?.tokenString} />
          ) : roleName === "UniSuperAdmin" ? (
            <UnivesityTable authToken={currentUser?.tokenString} />
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <TeachingStaffPieChart authToken={currentUser?.tokenString} />
        </Grid>
        <Grid item xs={12} md={6}>
          <NonTeachingStaffPieChart authToken={currentUser?.tokenString} />
        </Grid>
      </Grid>
      {/* <ProgramWiseStudents /> */}
      {/* <StudentByCampusType/>
      <StudentEmployeeByCampus/>
      <InstitutionsStatus/> */}
      <HomePage/>
    </div>
  );
};

export default NewDashboard;
