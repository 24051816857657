import {
  Menu,
  MenuItem,
  Typography,
  Divider,
  Box,
  Avatar,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { signOut } from "../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SettingsMenu({ anchorElUser, handleCloseUserMenu }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const email = currentUser?.email || "";
  const name = currentUser?.listUser?.[0]?.roleName || "";

  const handleLogout = () => {
    dispatch(signOut());
    navigate("/");
  };

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Avatar
          sx={{
            bgcolor: "#1976d2",
            marginRight: "12px",
            width: 36,
            height: 36,
          }}
        >
          {name.charAt(0).toUpperCase()}
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              color: "#333",
              mb: 0.5,
              fontSize: "14px",
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#555",
              fontSize: "12px",
            }}
          >
            {email}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <MenuItem sx={{ padding: "0" }} onClick={handleCloseUserMenu}>
        <Typography
          component={Link}
          to="/profile"
          sx={{
            width: "100%",
            padding: "10px 16px",
            color: "inherit",
            textDecoration: "none",
            textAlign: "left",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
        >
          Profile
        </Typography>
      </MenuItem>
      <MenuItem sx={{ padding: "0" }} onClick={handleCloseUserMenu}>
        <Typography
          component={Link}
          to="/esewa"
          sx={{
            width: "100%",
            padding: "10px 16px",
            color: "inherit",
            textDecoration: "none",
            textAlign: "left",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
        >
          Payment
        </Typography>
      </MenuItem>
      <MenuItem sx={{ padding: "0" }} onClick={handleCloseUserMenu}>
        <Typography
          onClick={handleLogout}
          sx={{
            width: "100%",
            padding: "10px 16px",
            color: "inherit",
            textDecoration: "none",
            textAlign: "left",
            "&:hover": {
              bgcolor: "red",
              color: "whitesmoke",
            },
          }}
        >
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );
}
