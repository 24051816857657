import React, { useEffect } from 'react';
import { Avatar } from 'rsuite';
import { FaUserGraduate, FaUsers, FaWpforms, FaClipboardList, FaBuilding, FaTachometerAlt, FaUserShield, FaCogs, FaCog } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Margin } from '@mui/icons-material';
import { color } from 'echarts';

const modules = [
  { title: "Student Management", icon: <FaUserGraduate size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/student-management" },
  { title: "Employee Management", icon: <FaUsers size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/employee-management" },
  { title: "Attendance Management", icon: <FaClipboardList size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/attendance-management" },
  { title: "Infrastructure Management", icon: <FaBuilding size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/infrastructure-management" },
  { title: "Dashboard", icon: <FaTachometerAlt size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/dashboard" },
  { title: "Role/User Management", icon: <FaUserShield size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/role-user-management" },
  { title: "Program Setup Management", icon: <FaCogs size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/program-setup-management" },
  { title: "Other Setup Management", icon: <FaCog size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/other-setup-management" },
  { title: "Exam/Form Management", icon: <FaWpforms size={40} />, bgColor: "#1C375B", color:'#1C375B', link: "/exam-form-management" },
];

const HomePage = () => {

  // Effect to add hover effect dynamically
  useEffect(() => {
    const moduleBoxes = document.querySelectorAll('.moduleBox');
    moduleBoxes.forEach((box) => {
      box.addEventListener('mouseenter', () => {
        box.style.transform = 'translateY(-8px)';
        box.style.boxShadow = '0 12px 24px rgba(0, 0, 0, 0.15)';
      });
      box.addEventListener('mouseleave', () => {
        box.style.transform = 'translateY(0)';
        box.style.boxShadow = '0 6px 18px rgba(0, 0, 0, 0.1)';
      });
    });

    // Cleanup the event listeners when the component unmounts
    return () => {
      moduleBoxes.forEach((box) => {
        box.removeEventListener('mouseenter', () => {});
        box.removeEventListener('mouseleave', () => {});
      });
    };
  }, []);

  return (
    <div style={styles.container}>
      {modules.map((module, index) => (
        <Link key={index} to={module.link} style={{ textDecoration: 'none' }}>
          <div className="moduleBox" style={{ ...styles.moduleBox, backgroundColor: module.bgColor, color: module.color }}>
            <div style={styles.avatarContainer}>
              <Avatar style={styles.avatar}>{module.icon}</Avatar>
            </div>
            <p style={styles.moduleTitle}>{module.title}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // 3 cards per row
    gap: "20px",
    alignItems: "center",
    padding: "20px",
    margin: "0 10%",
  },
  moduleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px",
    borderRadius: "12px",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    textAlign: "center",
    overflow: "hidden",
    marginLeft: "auto",
  },
  avatarContainer: {
    position: "relative",
    padding: "8px",
    backgroundColor: "#fff",
    borderRadius: "50%", 
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
  },
  avatar: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out",
  },
  moduleTitle: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#ffffff",
    marginTop: "8px",
    fontFamily: "'Poppins', sans-serif",
    letterSpacing: "0.3px",
    lineHeight: "1.4",
  },
};

export default HomePage;
