import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress } from "@mui/material";
import { StudentAddressContext } from "./StudentAddressInfo";
import { StudentInfoContext } from "./StudentGeneralInfo";
import { StudentGuardianContext } from "./StudentGuardianInfo";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { StudentRegContext } from "./StudentRegstration";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ReviewDetails = ({ handleBack, handleNext, onRegisterSuccess }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false)
  let universityId;
  let campusId;

  if (currentUser.type === 'Uni') {
    universityId = currentUser.institution.id;
  } else if (currentUser.type === 'college') {
    campusId = currentUser.institution.id;
  }

  const formatDetail = (detail) => {
    if (detail === null || detail === undefined) return "N/A";
    if (typeof detail === 'object') return JSON.stringify(detail); // For complex objects
    return detail.toString(); // For primitive values
  };
  const { studentInfo, getLogoURL } = useContext(StudentInfoContext);
  const { studentAddress } = useContext(StudentAddressContext);
  const { guardianInfo } = useContext(StudentGuardianContext);
  const { registrationInfo } = useContext(StudentRegContext);


const handleRegister = async () => {
  setLoading(true); // Start loading

  const formData = new FormData();
  formData.append("campusId", campusId || 0);
  formData.append("universityId", universityId || 0);
  formData.append("nepaliName", studentInfo.nepaliName || '');
  formData.append("firstName", studentInfo.firstName);
  formData.append("middleName", studentInfo.middleName || "");
  formData.append("lastName", studentInfo.lastName);
  formData.append("phoneNumber", studentInfo.phone);
  formData.append("doBBS", formatDetail(new Date(studentInfo.dobBs).toLocaleDateString()));
  formData.append("doBAD", formatDetail(new Date(studentInfo.dobAd).toLocaleDateString()));
  formData.append("gender", studentInfo.gender);
  formData.append("ethnicity", studentInfo.ethnicity);
  formData.append("nationality", studentInfo.nationality);
  formData.append("disabilityStatus", studentInfo.disabilityStatus);
  formData.append("disabilityType", studentInfo.disabilityType || '');
  formData.append("email", studentInfo.email);
  formData.append("citizenshipNo", studentInfo.citizenshipNo);
  formData.append("nidNo", studentInfo.nidNo || '');
  formData.append("citizenIssueDist", studentInfo.issuedDist);
  formData.append("citizenshipFrontFile", studentInfo.citizenFront);
  formData.append("citizenshipBackFile", studentInfo.citizenBack);
  formData.append("nidPicFile", studentInfo.nidPic);
  formData.append("levelId", registrationInfo.levelId);
  formData.append("facultyId", registrationInfo.facultyId);
  formData.append("programId", registrationInfo.programId);
  formData.append("admissionYear", registrationInfo.admissionYear);
  formData.append("complitionYear", registrationInfo.completionYear);
  formData.append("dateOfEnrollment", registrationInfo.dateOfEnrollment);
  formData.append("ppSizePhotoFile", studentInfo.ppSizePhoto);
  formData.append("programFee", registrationInfo.programFee);
  formData.append("paidAmount", registrationInfo.paidAmount);
  formData.append("hasScolorship", registrationInfo.haveScholarship);
  formData.append("scholarshipAmt", registrationInfo.scholarshipAmount);
  formData.append("receiptImage", registrationInfo.receipt);
  formData.append("fiscalYearId", registrationInfo.fiscalYearId);

  // Permanent Address
  formData.append("pProvince", studentAddress.pProvince);
  formData.append("pDistrict", studentAddress.pDistrict);
  formData.append("pLocalLevel", studentAddress.pLocalLevel);
  formData.append("pWardNo", studentAddress.pWardNo);
  formData.append("pBlockNo", 0);
  formData.append("pHouseNo", studentAddress.pHouseNo);
  formData.append("pLocality", studentAddress.pTole);
  formData.append("isSameAsPermanent", studentAddress.isSameAsPermament)

  // Conditional Temporary Address
  if (studentAddress.isSameAsPermament) {
    formData.append("tProvince", studentAddress.pProvince);
    formData.append("tDistrict", studentAddress.pDistrict);
    formData.append("tLocalLevel", studentAddress.pLocalLevel);
    formData.append("tWardNo", studentAddress.pWardNo);
    formData.append("tBlockNo", 0);
    formData.append("tHouseNo", studentAddress.pHouseNo);
    formData.append("tLocality", studentAddress.pTole);
  } else {
    formData.append("tProvince", studentAddress.tProvince);
    formData.append("tDistrict", studentAddress.tDistrict);
    formData.append("tLocalLevel", studentAddress.tLocalLevel);
    formData.append("tWardNo", studentAddress.tWardNo);
    formData.append("tBlockNo", 0);
    formData.append("tHouseNo", studentAddress.tHouseNo);
    formData.append("tLocality", studentAddress.tTole);
  }

  // Guardian Info
  formData.append("fatherName", guardianInfo.fatherName);
  formData.append("fOccupation", guardianInfo.fatherOccupation);
  formData.append("fatherPhoneNo", guardianInfo.fatherPhoneNo);
  formData.append("fatherEmail", guardianInfo.fatherEmail);
  formData.append("motherName", guardianInfo.motherName);
  formData.append("mOccupation", guardianInfo.motherOccupation);
  formData.append("motherPhoneNo", guardianInfo.motherPhoneNo);
  formData.append("motherEmail", guardianInfo.motherEmail);
  formData.append("guardianName", guardianInfo.guardianName);
  formData.append("gOccupation", guardianInfo.guardianOccupation);
  formData.append("guardianPhone", guardianInfo.guardianPhone);
  formData.append("gAddress", guardianInfo.address);
  formData.append("gEmail", guardianInfo.guardianEmail);

  // POST request to the API endpoint
  try {
    const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;

    const config = {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      }
    };

    const response = await axios.post(`${backendUrl}/Student`, formData, config);
    const id = response.data.id;

    onRegisterSuccess(id);
  } catch (err) {
    if (err.response && err.response.status === 409) {
      toast.error("Student already exists!", {
        autoClose: 2000,
      });
    } else {
      toast.error("Failed to add data!", {
        autoClose: 2000,
      });
    }
  } finally {
    setLoading(false);
  }
};


  const StudentGeneralInfo = studentInfo
    ? [ 
      { name: "विद्यार्थीको पुरा नाम देबनगरीमा (unicode):", detail: studentInfo.nepaliName },
        { name: "First Name:", detail: studentInfo.firstName },
        { name: "Middle Name:", detail: studentInfo.middleName || '-' },
        { name: "Last Name:", detail: studentInfo.lastName },
        { name: "Date Of Birth (BS):", detail: formatDetail(new Date(studentInfo.dobBs).toLocaleDateString()) },
        { name: "Phone Number:", detail: studentInfo.phone },
        { name: "Email:", detail: studentInfo.email },
        { name: "Date Of Birth (AD):", detail: formatDetail(new Date(studentInfo.dobAd).toLocaleDateString()) },
        { name: "Gender:", detail: studentInfo.gender },
        { name: "Ethnicity:", detail: studentInfo.ethnicity },
        { name: "Nationality:", detail: studentInfo.nationality },
        { name: "Disability Status:", detail: studentInfo.disabilityStatus },
        { name: "Disability Type:", detail: studentInfo.disabilityType || '-' },
        { name: "Citizenship No:", detail: studentInfo.citizenshipNo },
        { name: "Citizenship issued District:", detail: studentInfo.issuedDist || '-' },
      ]
    : [];
    const profileURL = getLogoURL();


  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: "center", color: "#2A629A" }}
      >
        Review Details
      </Typography>
      {profileURL && (
            <Box mt={2} display="flex" justifyContent="center">
              <img src={profileURL} alt="University Logo" style={{ maxWidth: '95px', maxHeight: '75px', borderRadius: '8px' }} />
            </Box>
          )}
      <Grid container spacing={1}>
      
        {/* University Details */}
        <Grid item xs={12} md={12}>
          <Box
            border={`1px solid #2B6EB5`}
            borderRadius="10px"
            position="relative"
            paddingBottom="15px"
            bgcolor="#F7F9FC"

          >
            <Typography
              variant="subtitle1"
              display="inline-block"
              bgcolor="white"
              padding="0 5px"
              position="relative"
              left="20px"
              bottom="14px"
              color="#2B6EB5"

            >
              General Info
            </Typography>
            <Grid container justifyContent="space-around">
              {StudentGeneralInfo.map((details, index) => (
                <Grid item xs={5} key={index} sx={{ mb: 1 }}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">{details.name}</Typography>
                    <Typography variant="body2" color="#2B6EB5">{details.detail}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* Address Details */}
<Grid item xs={12} md={12} mt={2} >
  <Box
            border={`1px solid #2B6EB5`}
            borderRadius="10px"
    position="relative"
    paddingBottom="15px"
  >
    <Typography
      variant="subtitle1"
      display="inline-block"
      bgcolor="white"
      padding="0 5px"
      position="relative"
      left="20px"
      bottom="14px"
      color="#2B6EB5"

    >
      Address Info
    </Typography>
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item xs={12} md={12} sx={{ mb: 1 }} mx='2rem'>
        <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>Permanent Address:</Typography>
        <Typography variant="body2" sx={{ textAlign: 'left' }} color="#2B6EB5">
          {studentAddress.pProvince}, {studentAddress.pDistrict}, {studentAddress.pLocalLevel} - {studentAddress.pWardNo || '-'} , {studentAddress.pTole || '-'},  {studentAddress.pHouseNo || '-'}
        </Typography>
      </Grid>
      {!studentAddress.isSameAsPermament && (
        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>Temporary Address:</Typography>
          <Typography variant="body2" sx={{ textAlign: 'left' }} color="#2B6EB5">
            {studentAddress.tProvince}, {studentAddress.tDistrict}, {studentAddress.tLocalLevel} - {studentAddress.tWardNo}, {studentAddress.tTole || '-'}, {studentAddress.tHouseNo || '-'}, 
          </Typography>
        </Grid>
      )}
    </Grid>
  </Box>
</Grid>


       
      </Grid>

      <Box mt={4} display="flex" justifyContent="space-between">
      <Button
        onClick={handleBack}
        sx={{ color: "#2b6eb5" }}
        type="reset"
        size="small"
        startIcon={<ChevronLeftRoundedIcon />}
      >
        Back
      </Button>
      <Button
        variant="contained"
        size="small"
        type="submit"
        sx={{
          bgcolor: "#2b6eb5",
          marginBottom: "10px",
          marginLeft: "10px",
          position: 'relative', // To position the loader correctly
          // Disable the button while loading
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.6 : 1,
        }}
        onClick={handleRegister}
        endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}
      >
        {loading ? 'Submitting...' : 'Submit & Next'}
      </Button>
    </Box>
    </>
  );
};

export default ReviewDetails;
