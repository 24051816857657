import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { createContext, useContext } from "react";
import { Controller, useForm } from 'react-hook-form';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getFiscalYear } from "../../../services/services";
import { getEmployeeById } from "../../../services/employeeService";

const convertToISODate = (dateString) => {
  if (!dateString) return "";
  const [datePart] = dateString.split(" "); // Split to get date part
  return datePart; // Ensure it's in YYYY-MM-DD format
};



const EditWorkInfoContext = createContext();
const EditWorkInfoProvider = ({ children }) => {
  const methods = useForm();
  const [workInfo, setWorkInfo] = useState({
    group: "",
    position: "",
    joiningType: "",
    joiningdate: 0,
    fiscalYear:0,
    reference: "",
    joiningletter: "",
    otherletter: "",
    logo: "",
  });

  const onChange = (name, value) => {
    setWorkInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <EditWorkInfoContext.Provider
      value={{ ...methods, workInfo, onChange }}
    >
      {children}
    </EditWorkInfoContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});

const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});

const EditWorkGeneralInfo = ({ handleNext, handleBack, id }) => {
  const { control, handleSubmit, setValue, formState: { errors } } = useContext(EditWorkInfoContext);
  const [defaultFiscal, setDefaultFiscal] = useState('');
  const [fiscalYear, setFiscalYear] = useState([]);
  
  const fetchData = async () => {
    try {
      const fiscalYears = await getFiscalYear();
      setFiscalYear(fiscalYears);
  
      const employeeData = await getEmployeeById(id);
  
      // Convert and set values
      setValue('group', employeeData.group || "");
      setValue('position', employeeData.position || "");
      setValue('joiningType', employeeData.joiningType || "");
      setValue('joiningdate', convertToISODate(employeeData.joiningDate) || ""); // Ensure date is set correctly
      setValue('fiscalYear', employeeData.fiscalYear || "");
      setValue('reference', employeeData.reference || "");
      setValue('joiningletter', employeeData.joiningletter || "");
      setValue('otherletter', employeeData.otherletter || "");
  
      // Set default fiscal year
      const activeFiscalYear = fiscalYears.find(data => data && data.activeFiscalYear === true);
      if (activeFiscalYear) {
        setDefaultFiscal(activeFiscalYear.id);
        setValue('fiscalYear', activeFiscalYear.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [id]);

  const { onChange } = useContext(EditWorkInfoContext);

  const onSubmit = (data) => {
    Object.keys(data).forEach((key) => {
      onChange(key, data[key]);
    });
    handleNext();
  };

  const onBack = () => {
    handleBack();
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onBack}
    >
      <Grid container>
        <Grid item xs={12} mb='.8rem'>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ textAlign: "center", color: "#636363" }}
          >
            Work Information
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2.5}>
            <FormControl fullWidth size="small">
              <InputLabel required>Profession</InputLabel>
              <Controller
                name="group"
                control={control}
                rules={{ required: "Required" }}
                defaultValue=""
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    id="group"
                    size="small"
                    name="group"
                    label="Profession"
                    fullWidth
                    error={!!errors.group}
                    helperText={errors.group ? "Required" : ""}
                  >
                    <MenuItem value="" disabled>Select</MenuItem>
                    <MenuItem value="professor">Professor</MenuItem>
                    <MenuItem value="reader">Reader</MenuItem>
                    <MenuItem value="lecturer">Lecturer</MenuItem>
                    <MenuItem value="asstLecturer">Asst. Lecturer</MenuItem>
                    <MenuItem value="teachingAsst">Teaching Asst.</MenuItem>
                    <MenuItem value="instructor">Instructor</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="position"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="position"
                  size="small"
                  name="position"
                  label="Position"
                  fullWidth
                  error={!!errors.position}
                  helperText={errors.position ? "Required" : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2.5}>
          <FormControl fullWidth size='small'>
          <InputLabel required>Joining Type</InputLabel>
            <Controller
              name="joiningType"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  required
                  id="joiningType"
                  size="small"
                  name="joiningType"
                  label="Joining Type"
                  fullWidth
                  error={!!errors.joiningType}
                  helperText={errors.joiningType ? "Required" : ""}
                >
                  <MenuItem value="" disabled>Select</MenuItem>
                  <MenuItem value='permanent'>Permanent</MenuItem>
                  <MenuItem value='temporary'>Temporary</MenuItem>
                  <MenuItem value='partTime'>Part Time</MenuItem>
                  <MenuItem value='contract'>Contract</MenuItem>
                </ValidationSelect>
              )}
            />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
  <Controller
    name="joiningdate"
    control={control}
    rules={{ required: "Required" }}
    defaultValue="" // Default value should be empty initially
    render={({ field }) => (
      <ValidationTextField
        {...field}
        id="joiningdate"
        label="Joining Date"
        type="date"
        size="small"
        fullWidth
        InputLabelProps={{ shrink: true }} // Ensure the label shrinks when date is present
        error={!!errors.joiningdate}
        helperText={errors.joiningdate ? "Required" : ""}
        value={field.value ? convertToISODate(field.value) : ""} // Set the formatted date
      />
    )}
  />
</Grid>



          <Grid item xs={12} sm={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="fiscalYear">Fiscal Year</InputLabel>
              <Controller
                name="fiscalYear"
                control={control}
                defaultValue={defaultFiscal} // Ensure this is updated after fetching data
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    id="fiscalYear"
                    size="small"
                    label="Fiscal Year"
                    fullWidth
                    value={field.value || defaultFiscal} // Ensure the value is managed by state
                    autoComplete="given-name"
                    error={!!errors.fiscalYear}
                    helperText={errors.fiscalYear ? "Fiscal year is required" : ""}
                  >
                    {fiscalYear.map((data) => (
                      <MenuItem value={data.id} key={data.id}>{data.yearNepali}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="flex-start">
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="standard"
              size="small"
              onClick={onBack}
              sx={{
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              startIcon={<ChevronLeftRoundedIcon />}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                bgcolor: "#003285",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              endIcon={<ChevronRightRoundedIcon />}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { EditWorkInfoProvider, EditWorkInfoContext };
export default EditWorkGeneralInfo;
