import { useForm, Controller } from "react-hook-form";
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const LibraryMgmt = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Library Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
              
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="libraryName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Library Name  is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Library Name"
                        fullWidth
                        autoComplete="libraryName"
                        error={!!errors.libraryName}
                      />
                    )}
                  />
                  {errors.libraryName && (
                    <FormHelperText error>{errors.libraryName.message}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small" error={!!errors.buildingName}>
                    <InputLabel id="building-name-label">Building Name (Block No)</InputLabel>
                    <Controller
                      name="buildingName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Building Name (Block No) is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="building-name-label"
                          label="Building Name (Block No)"
                          autoComplete="buildingName"
                        >
                          
                          <MenuItem value="Building1">Building1</MenuItem>
                          <MenuItem value="Building2">Building2</MenuItem>
                         
                        </Select>
                      )}
                    />
                    {errors.buildingName && (
                      <FormHelperText>{errors.buildingName.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberOfLibraryrooms"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Number of Library Rooms is required",
                      pattern: {
                        value: /^[1-9][0-9]*$/,
                        message: "Please enter a positive integer",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Number of Library Rooms"
                        fullWidth
                        autoComplete="numberOfLibraryrooms"
                        error={!!errors.numberOfLibraryrooms}
                        inputProps={{ step: "1", min: "1" }} // Allows only positive integers
                      />
                    )}
                  />
                  {errors.numberOfLibraryrooms && (
                    <FormHelperText error>{errors.numberOfLibraryrooms.message}</FormHelperText>
                  )}
                </Grid>
               {/* Area Covered by Library Rooms */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="areaCoveredRooms"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "Total Area Covered by Library Rooms is required",
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Total Area Covered by Library Rooms must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Total Area Covered by Library Rooms (sq.ft)"
                        fullWidth
                        autoComplete="areaCoveredRooms"
                        
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows only positive decimal numbers
                      />
                    )}
                  />
                  {errors.areaCoveredRooms && (
                    <FormHelperText error>{errors.areaCoveredRooms.message}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberOfReadingHalls"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Number of Reading Halls  is required",
                      pattern: {
                        value: /^[1-9][0-9]*$/,
                        message: "Please enter a positive integer",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Number of Reading Halls"
                        fullWidth
                        autoComplete="numberOfReadingHalls"
                        error={!!errors.numberOfReadingHalls}
                        inputProps={{ step: "1", min: "1" }} // Allows only positive integers
                      />
                    )}
                  />
                  {errors.numberOfReadingHalls && (
                    <FormHelperText error>{errors.numberOfReadingHalls.message}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="areaReadingHalls"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "Total Area Covered by Reading Halls is required",
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Area of Reading Hall must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label="Area of Reading Halls (sq.ft)"
                        fullWidth
                        autoComplete="areaReadingHalls"
                       
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows only positive decimal numbers
                      />
                    )}
                  />
                  {errors.areaReadingHalls && (
                    <FormHelperText error>{errors.areaReadingHalls.message}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberofBooks"
                    control={control}
                    defaultValue=""
                    rules={{required:"Number of Books is required"}}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Number of Books"
                        fullWidth
                        autoComplete="numberofBooks"
                        error={!!errors.numberofBooks}
                      />
                    )}
                  />
                  {errors.numberofBooks && (
                    <FormHelperText error>{errors.numberofBooks.message}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberofDailyJournals"
                    control={control}
                    defaultValue=""
                    
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Number of Daily Journals"
                        fullWidth
                        autoComplete="numberofDailyJournals"
                        error={!!errors.numberofDailyJournals}
                      />
                    )}
                  />
                  {errors.numberofDailyJournals && (
                    <FormHelperText error>{errors.numberofDailyJournals.message}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberofweeklyJournals"
                    control={control}
                    defaultValue=""
                    
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Number of Weekly Journals"
                        fullWidth
                        autoComplete="numberofweeklyJournals"
                        error={!!errors.numberofweeklyJournals}
                      />
                    )}
                  />
                  {errors.numberofweeklyJournals && (
                    <FormHelperText error>{errors.numberofweeklyJournals.message}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberofmonthlyJournals"
                    control={control}
                    defaultValue=""
                    
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Number of Monthly Journals"
                        fullWidth
                        autoComplete="numberofmonthlyJournals"
                        error={!!errors.numberofmonthlyJournals}
                      />
                    )}
                  />
                  {errors.numberofmonthlyJournals && (
                    <FormHelperText error>{errors.numberofmonthlyJournals.message}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="numberofannualJournals"
                    control={control}
                    defaultValue=""
                    
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Number of Annual Journals"
                        fullWidth
                        autoComplete="numberofannualJournals"
                        error={!!errors.numberofannualJournals}
                      />
                    )}
                  />
                  {errors.numberofannualJournals && (
                    <FormHelperText error>{errors.numberofanualJournals.message}</FormHelperText>
                  )}
                </Grid>
                {/* Has Internet Connection */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.hasInternet}>
                    <InputLabel id="internet-label">Has Internet Connection</InputLabel>
                    <Controller
                      name="hasInternet"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Has Internet Connection is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="internet-label"
                          label="Has Internet Connection"
                          autoComplete="hasInternet"
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.hasInternet && (
                      <FormHelperText>{errors.hasInternet.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Remarks */}
                <Grid item xs={12} >
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        multiline
                        rows={4}
                        InputProps={{ style: { minWidth: '100%' } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LibraryMgmt;
