import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select, Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { createContext, useContext, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getCampus, getFaculty, getFiscalYear, getLevel, getProgram } from "../../services/services";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { data } from "autoprefixer";
const WorkInfoContext = createContext();
const WorkInfoProvider = ({ children }) => {
  const methods = useForm();
  const [workInfo, setWorkInfo] = useState({
    group: "",
    subgroup: "",
    position: "",
    joiningType: "",
    joiningdate: 0,
    fiscalYear:0,
    reference: null,
    joiningletter: null,
    otherletter: null,
    logo: "",
  });
  // Update the contactDetails state as the form data changes
  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setWorkInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setWorkInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  return (
    <WorkInfoContext.Provider
      value={{ ...methods, workInfo, onChange }}
    >
      {children}
    </WorkInfoContext.Provider>
  );
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },

  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const WorkGeneralInfo = ({ handleNext, handleBack }) => {
  const { control, handleSubmit, formState: { errors } } = useContext(
    WorkInfoContext
  );
  const { onChange } = useContext(WorkInfoContext);
  const [campusData, setCampusData] = useState([]);
  const [fiscalYear, setFiscalYear] = useState([])
  const [defaultFiscal, setDefaultFiscal] = useState(''); 

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCampus()
       const fiscalYear = await getFiscalYear()
        setCampusData(response)
        setFiscalYear(fiscalYear)
        const activeFiscalYear = fiscalYear.find(data => data && data.activeFiscalYear === true);
        if (activeFiscalYear) {
          setDefaultFiscal(activeFiscalYear.id); // Use the ID or value that uniquely identifies the default fiscal year
        }

      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, []);

  const onBack = (data) => {
    console.log(data);
    handleBack(); // Call handleNext only if form submission is successful
  };
  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid container spacing={1}>
          <Grid item xs='12'>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", color: "#636363" }}
          >
            Work Information
          </Typography>
          </Grid>

          <Grid item xs={12} sm={2.5}>
          <FormControl fullWidth size="small">
          <InputLabel required>Profession</InputLabel>
            <Controller
              name="group"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  required
                  id="group"
                  size="small"
                  name="group"
                  label="Profession"
                  fullWidth
                  error={!!errors.group}
                  helperText={errors.group ? "Required" : ""}
                >
                  <MenuItem value={""} disabled>Select</MenuItem>

                  <MenuItem value={"professor"}>Professor</MenuItem>
                  <MenuItem value={"reader"}>Reader</MenuItem>
                  <MenuItem value={"lecturer"}>Lecturer</MenuItem>
                  <MenuItem value={"asstLecturer"}>Asst. Lecturer</MenuItem>
                  <MenuItem value={"teachingAsst"}>Teaching Asst.</MenuItem>
                  <MenuItem value={"instructor"}>Instructor</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>


                </Select>
              )}
            />
</FormControl>

          </Grid>

          <Grid item xs={12} sm={2.5}>
            <Controller
              name="position"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="position"
                  size="small"
                  name="position"
                  label="Position"
                  fullWidth
                  error={!!errors.position}
                  helperText={errors.position ? "Required" : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
          <FormControl fullWidth size='small'>
          <InputLabel required>Joining Type</InputLabel>
            <Controller
              name="joiningType"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  required
                  id="joiningType"
                  size="small"
                  name="joiningType"
                  label="Joining Type"
                  fullWidth
                  error={!!errors.joiningType}
                  helperText={errors.joiningType ? "Required" : ""}
                >
                                  <MenuItem value={""} disabled>Select</MenuItem>
                  <MenuItem value='permanent'>Permament</MenuItem>
                  <MenuItem value='temporary'>Temporary</MenuItem>
                  <MenuItem value='partTime'>Part Time</MenuItem>
                  <MenuItem value='contract'>Contract</MenuItem>

                </ValidationSelect>
              )}
            />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2.75}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker', 'DateField']} sx={{ mt: '-8px', overflow: 'hidden' }}>
            <Controller
              name="joiningdate"
              control={control}
              defaultValue={null} // Set your default value here
              render={({ field }) => (
                <DateField
                  {...field}
                  required
                  label='Joining Date'
                  enableAccessibleFieldDOMStructure
                  slotProps={{ textField: { size: 'small' } }}
                  format="YYYY/MM/DD"
                />
              )}
            />
          </DemoContainer>
        </LocalizationProvider>
           
          </Grid>
          <Grid item xs={12} sm={1.75}>
          <FormControl fullWidth size="small">
    <InputLabel id="fiscalYear">Fiscal Year</InputLabel>
    <Controller
      name="fiscalYear"
      control={control}
      defaultValue={defaultFiscal} // Set defaultValue here for initial form render
      render={({ field }) => (
        <Select
          {...field}
          required
          id="fiscalYear"
          size="small"
          label="Fiscal Year"
          fullWidth
          value={field.value || defaultFiscal} // Ensure the value is managed by state
          autoComplete="given-name"
          error={!!errors.fiscalYear}
          helperText={errors.fiscalYear ? "Fiscal year is required" : ""}
        >
          {fiscalYear.map((data) => (
            <MenuItem value={data.id} key={data.id}>{data.yearNepali}</MenuItem>
          ))}
        </Select>
      )}
    />
  </FormControl>
          </Grid>
         
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>Joining Letter</InputLabel>
              <TextField
                id="joiningletter"
                type="file"
                size="small"
                variant="standard"
                name="joiningletter"
                accept="image/*"
                onChange={(e) => onChange(e, "joiningletter", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>Other Letter</InputLabel>
              <TextField
                id="otherletter"
                type="file"
                size="small"
                variant="standard"
                name="otherletter"
                accept="image/*"
                onChange={(e) => onChange(e, "otherletter", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>Reference Letter</InputLabel>
              <TextField
                id="reference"
                type="file"
                size="small"
                variant="standard"
                name="reference"
                accept="image/*"
                onChange={(e) => onChange(e, "reference", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="flex-start">
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="standard"
              size="small"
              onClick={onBack}
              sx={{
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              startIcon={<ChevronLeftRoundedIcon />}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                bgcolor: "#003285",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              endIcon={<ChevronRightRoundedIcon />}
            >
              Next
            </Button>
          </Box>
        </Grid>
    </Grid>
  );
};

export { WorkInfoProvider, WorkInfoContext };
export default WorkGeneralInfo;
