import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const GetCollegePrograms = ({ programData }) => {
  return (
    <div>
      <TableContainer style={{ maxHeight: 300, width: "100%" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                S.No:
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Level
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Faculty
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Programs
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programData.map((program, index) => (
              <TableRow key={program.id} style={{ border: "1px solid #ddd" }}>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {program.levelName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {program.facultyName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {program.shortName}
                </TableCell>

                <TableCell
                  style={{
                    padding: "8px",
                    color: "green",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "none",
                  }}
                >
                  {/* <FiberManualRecordIcon sx={{ fontSize: 12, color: 'green', marginRight: '4px' }} /> */}
                  <span>Active</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GetCollegePrograms;
