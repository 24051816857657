import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function EmployeeMenu({
  anchorElCampusInfo,
  handleCloseCampusInfoMenu,
}) {
  const handleNavigate = () => {
    handleCloseCampusInfoMenu();
  };

  return (
    <Menu
      sx={{ mt: "45px", color: "blue" }}
      id="menu-appbar"
      anchorEl={anchorElCampusInfo}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElCampusInfo)}
      onClose={handleCloseCampusInfoMenu}
    >
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/teaching-staff"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left",
            textTransform: "capitalize",
            bgcolor: "transparent",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleNavigate}
        >
          Teaching Staff
        </Typography>
      </MenuItem>

      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/non-technical-staff"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left",
            textTransform: "capitalize",
            bgcolor: "transparent",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleNavigate}
        >
          Administrative Staff
        </Typography>
      </MenuItem>
    </Menu>
  );
}
