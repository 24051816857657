import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  loading: false,
  error: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInStart: (state) => {
      state.loading = true;
      state.error = false;
    },
    signInSuccess: (state, action) => {
      state.currentUser = action.payload.user;
      state.loading = false;
      state.error = false;
      // Save user and expiration time to sessionStorage
      const expirationTime = Date.now() + (2 * 60 * 60 * 1000); // 1 hour expiration
      sessionStorage.setItem('currentUser', JSON.stringify(action.payload.user));
      sessionStorage.setItem('expirationTime', expirationTime.toString());
    },
    signInFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    signOut: (state) => {
      state.currentUser = null;
      // Clear sessionStorage on sign out
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('expirationTime');
    },
    initializeSession: (state) => {
      const user = sessionStorage.getItem('currentUser');
      const expirationTime = sessionStorage.getItem('expirationTime');

      if (user && expirationTime && Date.now() < parseInt(expirationTime, 10)) {
        state.currentUser = JSON.parse(user);
      } else {
        state.currentUser = null;
        // Clear sessionStorage if session has expired
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('expirationTime');
      }
    },
  },
});

export const { signInStart, signInSuccess, signInFailure, signOut, initializeSession } = userSlice.actions;
export default userSlice.reducer;
