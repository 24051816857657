import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent
} from "@mui/material";
import {
  // getFaculty,
  getFacultyById,
  getLevel,
  patchFaculty,
} from "../../../services/services.js";
import { toast } from "react-toastify";


const EditFacultyMgmt = ({ id, onClose, onUpdate }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  // const [getFacultyData, setGetFacultyData] = useState([]);
  const [getFacultyDataById, setGetFacultyDataById] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const facultyData = await getFaculty();
        const facultyDataById = await getFacultyById(id);
        // setGetFacultyData(facultyData);
        setGetFacultyDataById(Array.isArray(facultyDataById[0]) ? facultyDataById : [facultyDataById]);
        // Set default values for form fields using setValue
        setValue('universityId', facultyDataById.universityId)
        setValue('campusId', facultyDataById.campusId)
        setValue("facultyName", facultyDataById.facultyName);
        setValue('facilityType', facultyDataById.facultyType)
        setValue("shortName", facultyDataById.shortName);
        setValue("code", facultyDataById.code);
        setValue("alias", facultyDataById.alias);
        setValue("detail", facultyDataById.detail);
        setValue('status', facultyDataById.status)
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    try {
      await patchFaculty(data, id);
      onClose();
      onUpdate();
      reset();
      toast.success("Faculty updated successfully!", {
        autoClose:2000
      });
    } catch (err) {
      console.error("Submission error:", err);
      if (err.response && err.response.status === 409) {
        toast.error("Level already exists!", {
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add data!", {
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <>
      <DialogContent fullWidth>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h6" gutterBottom               sx={{ textAlign: "center", color: "#2A629A" }}
 padding='10px'>
                Update Faculty Management
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                {getFacultyDataById.map((data) => (
                  <Grid container spacing={3} key={data.id}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        {...register("facultyName", { required: true })}
                        size="small"
                        label="Faculty Name"
                        fullWidth
                        autoComplete="given-name"
                        error={!!errors.facultyName}
                        helpertext={errors.facultyName ? "Faculty name required" : ""}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="levelId" required>
                          Level Name
                        </InputLabel>
                        <Select
                          {...register("levelId", { required: true })}
                          size="small"
                          label="Level Name"
                          fullWidth
                          error={!!errors.levelId}
                          helpertext={errors.levelId ? "Level required" : ""}
                          defaultValue={data.levelId} // Set default value
                        >
                          {getLevelData.map((level) => (
                            <MenuItem key={level.id} value={level.id}>
                              {level.levelName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="facilityType" required>
                          Faculty Type
                        </InputLabel>
                        <Select
                          {...register("facilityType", { required: true })}
                          size="small"
                          label="Faculty Type"
                          fullWidth
                          error={!!errors.facilityType}
                          helpertext={errors.facilityType ? "Faculty Type required" : ""}
                          defaultValue={data.facilityType} // Set default value
                        >
                            <MenuItem  value={'general'}> General</MenuItem>
                            <MenuItem  value={'technical'}> Technical</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register("shortName", { required: true })}
                        size="small"
                        label="Short Name"
                        fullWidth
                        autoComplete="short-name"
                        error={!!errors.shortName}
                        helpertext={errors.shortName ? "Short name required" : ""}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                <FormControl fullWidth size="small">
                <InputLabel>Status</InputLabel>
                  <Select
                    {...register("status")}
                    size="small"
                    label="Status"
                    fullWidth
                    defaultValue={data.status}
                  >
                    <MenuItem value={true}><span style={{color:'green'}}>Active</span></MenuItem>
                    <MenuItem value={false}><span style={{color:'red'}}>InActive</span></MenuItem>
                  </Select>
                  </FormControl>
                  
                </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register("code")}
                        size="small"
                        label="Code"
                        fullWidth
                        
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        {...register("alias")}
                        size="small"
                        label="Alias"
                        fullWidth
                        error={!!errors.alias}
                        helpertext={errors.alias ? "Alias required" : ""}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        {...register("detail")}
                        size="small"
                        label="Details"
                        fullWidth
                        error={!!errors.detail}
                        helpertext={errors.detail ? "Detail required" : ""}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Grid
                container
                style={{
                  // height: '100vh', // Make the container full height of the viewport
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >                    <Button onClick={onClose}>Cancel</Button>
 <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#007aff", color: "#inherit" }}
                >                      Update
                    </Button>
                  </Grid>
              </form>
            </CardContent>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default EditFacultyMgmt;
