import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { createContext, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from "@mui/x-date-pickers";
import useAddressData from "../address/address";
import { getEthnicGroup } from "../../services/services";


const StudentInfoContext = createContext();

const StudentInfoProvider = ({ children }) => {
  const methods = useForm();
  const [studentInfo, setStudentInfo] = useState({
    nepaliName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: 0,
    dobBs: "",
    dobAd: "",
    gender: "",
    ethnicity: "",
    nationality: "",
    disabilityStatus: "",
    disabilityType: "",
    email: "",
    citizenshipNo: "",
    issuedDist: "",
    citizenFront: null,
    citizenBack: null,
    nidNo: "",
    nidPic: null,
    ppSizePhoto: null,
  });

  const getLogoURL = () => {
    return studentInfo.ppSizePhoto ? URL.createObjectURL(studentInfo.ppSizePhoto) : null;
  };

  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setStudentInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setStudentInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <StudentInfoContext.Provider value={{ ...methods, studentInfo, onChange, getLogoURL }}>
      {children}
    </StudentInfoContext.Provider>
  );
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
  datePicker: {
    "& .MuiTextField-root": {
      width: 20,
    },
  }
});
const StudentGeneralInfo = ({ handleNext, handleBack }) => {
  const {
    address
  } = useAddressData();
  const [ethnicGroup, setEthnicGroup] = useState([])
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useContext(StudentInfoContext);
  const { onChange } = useContext(StudentInfoContext);
  const disabilityStatus = watch("disabilityStatus"); // Watch the value of disabilityStatus

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  const onBack = (data) => {
    console.log(data);
    handleBack(); // Call handleNext only if form submission is successful
  };
  const fetchData = async () => {
    try {
      const response = await getEthnicGroup()
      setEthnicGroup(response)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData()
  },[])
  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid container>
        <Grid xs="12">
          <Typography
            textAlign="center"
            variant="subtitle1"
            sx={{ color: "#636363", mt: ".5rem" }}
          >
            Student General Info
          </Typography>
        </Grid>
        <Grid mt='.5rem' container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Controller
              name="nepaliName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                required
                  {...field}
                  id="nepaliName"
                  size="small"
                  name="nepaliName"
                  label="विद्यार्थीको पुरा नाम देबनगरीमा (unicode)"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                required
                  {...field}
                  id="firstName"
                  size="small"
                  name="firstName"
                  label="First Name (Eng)"
                  fullWidth
                  inputProps={{ style: { textTransform: "uppercase" } }}
                 
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="middleName"
              control={control}
              //rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="middleName"
                  size="small"
                  name="middleName"
                  label="Middle Name"
                  fullWidth
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="lastName"
                  size="small"
                  name="lastName"
                  label="Last Name (Eng)"
                  fullWidth
                  inputProps={{ style: { textTransform: "uppercase" } }}
                 
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <Controller
              name="phone"
              control={control}
              rules={{
                validate: (value) => {
                  const isValidPhone = /^[9]\d{9}$/.test(value);
                  return isValidPhone || "invalid phone umber";
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="phone"
                  type="text"
                  name="phone"
                  label="Phone Number"
                  InputProps={{
                    placeholder: "98XXXXXXXX",
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    onInput: (e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10); // Limit input to 10 characters
                    },
                  }}
                  fullWidth
                  autoComplete="phone number"
                  size="small"
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Email format not matched",
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="email"
                  name="email"
                  type="email"
                  size="small"
                  label="Email"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message} // Check if errors.email exists before accessing its message property
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
      <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
        <InputLabel sx={{ borderColor: "blue" }} id="gender" required>
          Gender
        </InputLabel>
        <Controller
          name="gender"
          control={control}
          defaultValue="" // Set default value to match the "Select Gender" MenuItem
          render={({ field }) => (
            <ValidationSelect
              {...field}
              required
              labelId="gender"
              id="gender"
              name="gender"
              label="Gender"
              fullWidth
            >
              <MenuItem value="" disabled>Select Gender</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </ValidationSelect>
          )}
        />
      </FormControl>
    </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="type">
                Nationality
              </InputLabel>
              <Controller
                name="nationality"
                control={control}
                rules={{ required: "Required" }}
                defaultValue="nepali" // Set the default value here
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    labelId="nationality"
                    id="nationality"
                    name="nationality"
                    label="Nationality"
                    fullWidth
                    error={!!errors.nationality}
                    helperText={
                      errors.nationality ? errors.nationality.message : ""
                    }
                  >
                    <MenuItem value="" disabled>Select Nationality </MenuItem>
                    <MenuItem value="nepali">Nepali</MenuItem>

                    <MenuItem value="other">Other</MenuItem>
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2.5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker', 'DateField']} sx={{ mt: '-8px', overflow: 'hidden' }}>
            <Controller
              name="dobBs"
              control={control}
              defaultValue={null} // Set your default value here
              render={({ field }) => (
                <DateField
                  {...field}
                  required
                  label='Date of Birth (B.S.)'
                  enableAccessibleFieldDOMStructure
                  slotProps={{ textField: { size: 'small' } }}
                  format="YYYY/MM/DD"
                />
              )}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={2.5}>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer components={['DatePicker', 'DateField']} sx={{ mt: '-8px', overflow: 'hidden' }}>
      <Controller
        name="dobAd"
        control={control}
        defaultValue={null} // Set your default value here
        render={({ field }) => (
          <DateField
            {...field}
            required
            label='Date of Birth (A.D.)'
            enableAccessibleFieldDOMStructure
            slotProps={{ textField: { size: 'small' } }}
            format="YYYY/MM/DD"
            inputFormat="YYYY/MM/DD"
            disableFormatting
          />
        )}
      />
    </DemoContainer>
  </LocalizationProvider>
</Grid>          
          <Grid item xs={12} sm={2.5}>
  <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
    <InputLabel sx={{ borderColor: "blue" }} id="ethnicity" required>
      Ethnicity
    </InputLabel>
    <Controller
      name="ethnicity"
      control={control}
      render={({ field }) => (
        <ValidationSelect
          {...field}
          required
          labelId="ethnicity"
          id="ethnicity"
          name="ethnicity"
          label="Ethnicity"
          fullWidth
          error={!!errors.ethnicity}
          onChange={(e) => field.onChange(e.target.value)} // Ensure field value is updated
          value={field.value || ""} // Handle value properly
        >
          <MenuItem value="" disabled>Select Ethnicity</MenuItem>
          {ethnicGroup && ethnicGroup.map((data) => (
          <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
        ))}
        </ValidationSelect>
      )}
    />
  </FormControl>
</Grid>
          
          <Grid item xs={12} sm={2}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel
                sx={{ borderColor: "blue" }}
                id="disabilityStatusLabel"
                required
              >
                Disability Status
              </InputLabel>
              <Controller
                name="disabilityStatus"
                control={control}
                defaultValue="" // Set the default value here
                render={({ field }) => (
                  <Select
                    {...field}
                    required
                    labelId="disabilityStatusLabel"
                    id="disabilityStatus"
                    fullWidth
                    label="Dissability Status"
                    
                  >
                    <MenuItem value="" disabled>Select Disability Status </MenuItem>
                    <MenuItem value="able">Able</MenuItem>
                    <MenuItem value="differentlyable">
                      Differently-Able
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="disabilityType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="disabilityType"
                  size="small"
                  name="disabilityType"
                  label="Disability Type"
                  placeholder="if-any"
                  fullWidth
                  disabled={disabilityStatus === "able"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="citizenshipNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  
                  id="citizenshipNo"
                  size="small"
                  name="citizenshipNo"
                  label="Citizenship No"
                  fullWidth
                 
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small">
          <InputLabel>
            Issued District
          </InputLabel>
  <Controller
    name="issuedDist"
    control={control}
    defaultValue=""
    render={({ field }) => (
      <ValidationSelect
        {...field}
        id="issuedDist"
        size="small"
        name="issuedDist"
        label="Issued District"
        fullWidth
       
      >
        {/* Filter unique districts using a Set */}
        <MenuItem value="" disabled>Select Issued District </MenuItem>

        {address && Array.from(new Set(address.map(data => data.district))).map((district, index) => (
          <MenuItem key={index} value={district}>{district}</MenuItem>
        ))}
      </ValidationSelect>
    )}
  />
  </FormControl>
</Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="nidNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="nidNo"
                  size="small"
                  name="nidNo"
                  label="NID No."
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                PP size Photo
              </InputLabel>
              <TextField
                id="ppSizePhoto"
                type="file"
                size="small"
                variant="standard"
                name="ppSizePhoto"
                accept="image/*"
                onChange={(e) => onChange(e, "ppSizePhoto", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                Citizenship Front
              </InputLabel>
              <TextField
                id="citizenFront"
                type="file"
                size="small"
                variant="standard"
                name="citizenFront"
                accept="image/*"
                onChange={(e) => onChange(e, "citizenFront", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                Citizenship Back
              </InputLabel>
              <TextField
                id="citizenBack"
                type="file"
                size="small"
                variant="standard"
                name="citizenBack"
                accept="image/*"
                onChange={(e) => onChange(e, "citizenBack", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <InputLabel size="small" shrink>
                NID photo
              </InputLabel>
              <TextField
                id="nidPic"
                type="file"
                size="small"
                variant="standard"
                name="nidPic"
                accept="image/*"
                onChange={(e) => onChange(e, "nidPic", e.target.files[0])}
                // inputProps={{ required: true }}
                style={{
                  // backgroundColor: "#2196F3", // Blue color
                  color: "#FFFFFF", // White text color
                  padding: "10px 15px", // Padding for better appearance
                  border: "none", // Remove default border
                  borderRadius: "4px", // Rounded corners
                  cursor: "pointer", // Show pointer cursor when hovering
                }}
              />
            </FormControl>
          </Grid>
          
        </Grid>

        <Box mt={4} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            size="small"
            type="submit"
            disabled
            sx={{
              bgcolor: "#2b6eb5",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
            startIcon={<ChevronLeftRoundedIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            size="small"
            type="submit"
            sx={{
              bgcolor: "#2b6eb5",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
            endIcon={<ChevronRightRoundedIcon />}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
export { StudentInfoProvider, StudentInfoContext };
export default StudentGeneralInfo;
